import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import { jurisdiction } from '../../utils'


const Home = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        setTimeout(() => {
            // 获取登录用户的插件权限
            jurisdiction(sessionStorage.getItem('name')).then((res) => {
                setData(res.data);
            })
        }, 500)
    }, [])
    return (
        <div className='extends'>
            <div className='plugInUnit'>
                {
                    data?.map((item, index) => {
                        return (
                            <div key={index} className='box' >
                                <Link className='extend' to={'/' + item.extensionId}>
                                    <img alt='' src={item.iconURL}></img>
                                    <div className='name'>{item.name}</div>
                                    <div className='details'>{item.description}</div>
                                </Link>
                            </div>
                        )
                    })
                }
                <div className='more'>更多扩展即将推出</div>
                <i></i>
                <i></i>
            </div>
            <footer>英荔 AI 实验平台仅限正常教学实验使用，滥用平台或提交非法信息将会被限制访问</footer>
        </div>
    )
}
export default Home;