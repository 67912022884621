import axios from 'axios';
import { message } from 'antd';

const instance = axios.create();

// 响应拦截器
instance.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error.response?.status) {
      switch (error.response.status) {
        // 404请求不存在
        case 404:
          message.error('网络请求不存在');
          break;
        case 400:
          break;
        // 其他错误，直接抛出错误提示
        default:
          message.error('服务器错误，请重试！');
      }
      return Promise.reject(error.response);
    }
  }
);

export const jurisdiction = usernmae => {
  return instance.get(`/api/v1/ailabplugin/?username=${usernmae}`);
};

export const uploadPictures = image => {
  return instance.post(
    '/api/v1/user_face',
    { image },
    {
      headers: {
        Authorization: sessionStorage.getItem('toKen')
      }
    }
  );
};

export const deleteCache = () => {
  return instance.post(
    '/api/v1/delete_user_face_cache',
    {},
    {
      headers: {
        Authorization: sessionStorage.getItem('toKen')
      }
    }
  );
};

export const getPicture = username => {
  return instance.get('/api/v1/user_face_cache', {
    headers: {
      Authorization: sessionStorage.getItem('toKen')
    }
  });
};

export const me = () => {
  return instance.get('/api/v1/users/me/', {
    headers: {
      Authorization: sessionStorage.getItem('toKen')
    }
  });
};

// 训练鸢尾花
export const trainIris = param => {
  return instance.post(
    '/api/v1/train_iris/',
    { ...param },
    {
      headers: {
        Authorization: sessionStorage.getItem('toKen')
      }
    }
  );
};

// 查询训练结果
export const getTrainResult = () => {
  return instance.get('/api/v1/train_iris_lab_result/?type=0', {
    headers: {
      Authorization: sessionStorage.getItem('toKen')
    }
  });
};

/**
 * **** 深度强化学习 ****
 *
 */
// 人类玩游戏保存
export const deepLearn_humanTrain = params => {
  return instance.post(
    '/api/v1/people_train_deep_learning/',
    { ...params },
    {
      headers: {
        Authorization: sessionStorage.getItem('toKen')
      }
    }
  );
};
// 人类游戏查询
export const deepLearn_humanTrainResult = params => {
  return instance.get(
    `/api/v1/people_train_deep_learning/${params}`,

    {
      headers: {
        Authorization: sessionStorage.getItem('toKen')
      }
    }
  );
};
// 根据传递参数获取预计时间
export const deepLearn_trainTime = params => {
  return instance.post(
    `/api/v1/train_deep_learning/`,
    { ...params },
    {
      headers: {
        Authorization: sessionStorage.getItem('toKen')
      }
    }
  );
};
// 深度强化学习点击确定和取消的接口（重新训练）
export const deepLearn_trainCancel_or_Ok = params => {
  return instance.post(
    `/api/v1/train_deep_learning_cancel_or_ok/`,
    { ...params },
    {
      headers: {
        Authorization: sessionStorage.getItem('toKen')
      }
    }
  );
};

// 查询是否已有训练模型
export const deepLearn_getTrainModal = params => {
  return instance.get(
    `/api/v1/train_deep_learning_cancel_or_ok/`,

    {
      headers: {
        Authorization: sessionStorage.getItem('toKen')
      }
    }
  );
};


/**
 * **** 农作物病虫害识别 ****
 *
 */
export const cropDiseaseRecognition = (image) => {
    return instance.post(
        '/api/v1/crop_disease_recognition',
        // 'http://localhost:5003/upload',
        {
            image
        },
        {
            headers: {
                Authorization: sessionStorage.getItem('toKen')
            }
        }
    )
}

export const cacheRecognitionImages = () => {
    return instance.get(
        '/api/v1/crop_disease_cache',
        // 'http://localhost:5003/get_cache',
        {
            headers: {
                Authorization: sessionStorage.getItem('toKen')
            }
        },
    )
}

/**
 * **** 图形识别专家系统 ****
 *
 */
export const pattern_currentRules = params => {
  return instance.get(`/api/v1/picture_detection?rule_type_id=${params}`, {
    headers: {
      Authorization: sessionStorage.getItem('toKen')
    }
  });
};
export const picture_detection = params => {
  return instance.post(
    `/api/v1/picture_detection`,
    { ...params },
    {
      headers: {
        Authorization: sessionStorage.getItem('toKen')
      }
    }
  );
};


/**
 * **** 说话人识别 ****
 *
 */
// 上传
export const uploadVoice = formData => {
    return instance.post(
        // 'http://localhost:5003/upload',
        // 'https://api.dev.longan.eliteu.xyz/api/v1/speaker_recognition_upload',
        '/api/v1/speaker_recognition_upload',
        formData,
        {
            headers: {
                Authorization: sessionStorage.getItem('toKen'),
            }
        }
    )
}

// 训练声纹
export const speakerTraining = formData => {
    return instance.post(
        // 'http://localhost:5003/train',
        // 'https://api.dev.longan.eliteu.xyz/api/v1/speaker_recognition',
        '/api/v1/speaker_recognition',
        formData,
        {
            headers: {
                Authorization: sessionStorage.getItem('toKen'),
            }
        }
    )
}

// 获取声纹是否已经上传完成
export const getSpeakerTraining = () => {
    return instance.get(
        // 'http://localhost:5003/getTrain',
        // 'https://api.dev.longan.eliteu.xyz/api/v1/speaker_recognition',
        '/api/v1/speaker_recognition',
        {
            headers: {
                Authorization: sessionStorage.getItem('toKen'),
            }
        }
    )
}

// 删除声纹
export const deleteVoice = () => {
    return instance.post(
        // 'http://localhost:5003/deleteVoice',
        // 'https://api.dev.longan.eliteu.xyz/api/v1/speaker_delete',
        '/api/v1/speaker_delete',
        {},
        {
            headers: {
                Authorization: sessionStorage.getItem('toKen'),
            }
        }
    )
}


/** textCreateImage **/
export const getDataJson = module => {
    return new Promise((resolve, reject) => {
        fetch(`https://dall-e.eliteu.xyz/dall-e/v2/metadata_zip/${ module }.json`)
            .then(response => response.json())
            .then(data => resolve(data))
            .catch(error => reject('Error fetching JSON:', error));
    })
}