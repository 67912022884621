import React from 'react';
import { InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  convolutionalLayer,
  poolingLayer,
  fullyConnected
} from '../../../store/createSlice';

import './style.scss';

const StepThree = () => {
  const dispatch = useDispatch();
  const convolutionalL = useSelector(state => state.counter.convolutionalLayer);
  const poolingL = useSelector(state => state.counter.poolingLayer);
  const fullyConnect = useSelector(state => state.counter.fullyConnected);

  return (
    <div className="step" id="stepThree">
      <div className="title">设计深度学习模型</div>
      <p className="describe">
        深度学习是学习样本数据的内在规律和表示层次，这些学习过程中获得的信息对诸如文字，图像和声音等数据的解释有很大的帮助。它的最终目标是让机器能够像人一样具有分析学习能力，能够识别文字、图像和声音等数据。
        <br />
        卷积神经网络（CNN）是典型的深度学习模型，常应用于图像处理，以下便是卷积神经网络的基本结构。
      </p>
      <div className="model">
        <img
          className="modelImg"
          src="/image/deepLearning/step3_1.png"
          alt="获取样本数据"
        />
        <div className="process_jtLine">
          {/* <span></span> */}
          <img
            src="/image/deepLearning/arrow_right.png"
            alt=""
            className="arrow_right"
          />
        </div>
        <img
          className="modelImg"
          src="/image/deepLearning/step3_2.png"
          alt="卷积层"
        />
        <div>
          <img
            src="/image/deepLearning/arrow_cycle.png"
            alt=""
            className="arrow_cycle"
          />
        </div>
        <img
          className="modelImg"
          src="/image/deepLearning/step3_3.png"
          alt="池化层"
        />
        <div className="process_jtLine">
          {/* <span></span> */}
          <img
            src="/image/deepLearning/arrow_right.png"
            alt=""
            className="arrow_right"
          />
        </div>
        <img
          className="modelImg"
          src="/image/deepLearning/step3_4.png"
          alt="全连接层"
        />
      </div>
      <div className="textBox">
        <span>获取样本数据</span>
        <span>卷积层</span>
        <span>池化层</span>
        <span>全连接层</span>
      </div>
      <div className="inputNum">
        <span>设置卷积层数：</span>
        <InputNumber
          min={1}
          precision={0}
          max={10}
          defaultValue={convolutionalL}
          onChange={value => dispatch(convolutionalLayer(value))}
        />
        <span className="inputTips">当前实验环境规定最多 10 个卷积层</span>
      </div>
      <div className="inputNum">
        <span>设置池化层数：</span>
        <InputNumber
          precision={0}
          min={1}
          max={10}
          defaultValue={poolingL}
          onChange={value => dispatch(poolingLayer(value))}
        />
        <span className="inputTips">当前实验环境规定最多 10 个池化层</span>
      </div>
      <div className="inputNum">
        <span>设置全连接层数：</span>
        <InputNumber
          min={1}
          precision={0}
          max={10}
          defaultValue={fullyConnect}
          onChange={value => dispatch(fullyConnected(value))}
        />
        <span className="inputTips">当前实验环境规定最多 10 个全连接层</span>
      </div>
    </div>
  );
};
export default StepThree;
