import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    connect: '',
    name: '',
    toKen: '',
    /*  深度学习 */
    computingPower: 50, // 分配算力
    convolutionalLayer: 3, // 卷积层
    poolingLayer: 3, // 池化层
    fullyConnected: 3, // 全连接层
    imagesNum: 4, // 图像数量
    cycles: 10, //   循环次数
    iterationsNum: 100, // 迭代次数
    AIModelResult: {}, // 模型参数和试验结果
    humanTrainResult: null, // 人类训练结果集
    /* 图形识别专家系统 */
    currentRulesID: sessionStorage.getItem('rules')?.split(',') || ['a']
  },
  reducers: {
    connect: (state, action) => {
      state.connect = action.payload;
    },
    toKen: (state, action) => {
      state.toKen = action.payload;
    },
    name: (state, action) => {
      state.name = action.payload;
    },
    /*  深度学习 */
    computingPower: (state, action) => {
      state.computingPower = action.payload;
    },
    convolutionalLayer: (state, action) => {
      state.convolutionalLayer = action.payload;
    },
    poolingLayer: (state, action) => {
      state.poolingLayer = action.payload;
    },
    fullyConnected: (state, action) => {
      state.fullyConnected = action.payload;
    },
    imagesNum: (state, action) => {
      state.imagesNum = action.payload;
    },
    cycles: (state, action) => {
      state.cycles = action.payload;
    },
    iterationsNum: (state, action) => {
      state.iterationsNum = action.payload;
    },
    AIModelResult: (state, action) => {
      state.AIModelResult = action.payload;
    },

    humanTrainResult: (state, action) => {
      state.humanTrainResult = action.payload;
    },
    /** 图形识别专家系统 */
    currentRulesID: (state, action) => {
      state.currentRulesID = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  connect,
  toKen,
  name,
  computingPower,
  convolutionalLayer,
  poolingLayer,
  fullyConnected,
  imagesNum,
  cycles,
  iterationsNum,
  AIModelResult,
  humanTrainResult,
  currentRulesID
} = counterSlice.actions;
export default counterSlice.reducer;
