import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { routes, Route404 } from './config';

const RouterRender = () => {

  return (
    <Routes>
      {
        [...routes, Route404].map((item, index) => {
          if (item.redirect) {
            return (
              <Route key={index} path={item.path} element={<Navigate to={item.redirect} />} />
            )
          } else {
            return (
              <Route
                key={index}
                path={item.path}
                element={item.element()}
              />
            )
          }
        })
      }
    </Routes>
  );
};

export default RouterRender;
