import React, { useState, useEffect } from 'react'
import './style.scss'
import Generator from '../Generator'
import { Button } from 'antd'
import { SyncOutlined } from '@ant-design/icons'

const Basic = ({ show, baseurl }) => {
    
    const [spin, setSpin] = useState(false)
    const [target, setTarget] = useState(3)
    
    // why do this?
    // at first paint, if change `target` is also get 3, React will not update state
    useEffect(() => {
        setTimeout(() => setTarget(0))
    }, [])

    const changeExamples = () => {
        // 随机 1、2、3 三个数
        // got number 1, change 1st example
        // got number 2, change 2nd example
        // got number 3, change both of them
        
        const _target = [1, 2, 3][parseInt(Math.random() * 3)]
        // console.log('_target', _target)
        
        // 根据 target 对指定的 example 随机分配模块
        setTarget(_target)
        
        /** 
         * If `target` is the same in this component and `updateFlag` is true in Generator component.
         * In this case, `props` don't change, and Generator component can't update.
         * And we could build a macro task to change `target` value to implement its update.
         * At the same time, we need to add a condition for judging `target` isn't `0` in Generator component.
         * */
        setTimeout(() => setTarget(0))
        
        
        if (spin) return
        setSpin(true)
        setTimeout(() => {
            setSpin(false)
        }, 300)
    }
    
    
    return(
        <div id="basic" style={{ display: show ? "block" : "none" }}>
            
            <p className="text">
                AIGC 指的是「人工智能生成内容」，是人工智能技术中一个很重要的领域。特别是在 2022 年下半年，伴随着 Dall-E、Craiyon、Stable Diffusion 等项目的发布，AIGC 开始逐渐成为人工智能技术中最火热的话题。
            </p>
            
            <p className="text">
                AIGC 主要可以分为 AI 文本生成图像、AI 文本生成文段、AI 文本生成视频等类型。其中 AI 文本生成图像由于其技术最早达到商业应用级别，成为了 AIGC 中率先进入大众视野的产品。
            </p>
            
            <p className="text">
                AI 文本生成图像，顾名思义指的是 AI 可以依据少量词汇在几秒钟内生成与之相匹配的图像。
            </p>
            
            <Generator type="basic" updateFlag={ target === 1 || target === 3 } target={ target } baseurl={ baseurl } index={0}/>
            <Generator type="basic" updateFlag={ target === 2 || target === 3 } target={ target } baseurl={ baseurl } index={1}/>

            <Button type="primary"
                    className="primaryButton changeGenerator"
                    icon={<SyncOutlined className={ spin ? "spinIcon" : ""}/>}
                    onClick={() => changeExamples()}
            >换一换</Button>
        </div>
    )
}

export default Basic