import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {changeStep,changeIsClick1, changeIsClick, changeIsClick3} from "../../../store/cropSlice";
import {Button} from "antd";

const StepFour = props => {

    const step = useSelector(state => state.crop.step);
    const isClick1 = useSelector(state => state.crop.isClick1); // 步骤1
    const isClick = useSelector(state => state.crop.isClick); // 步骤 2
    const isClick3 = useSelector(state => state.crop.isClick3); // 步骤 3
    const dispatch = useDispatch();

    const stepPlus1 = () => {
        // 初始状态
        dispatch(changeIsClick1());
        dispatch(changeStep(1));
    }

    const stepPlus2 = () => {
        if(step < 1) return
        dispatch(changeStep(2));
        dispatch(changeIsClick());
    }

    const stepPlus3 = () => {
        if(step < 2 && !isClick) return
        dispatch(changeIsClick3());
        dispatch(changeStep(3));
    }

    return(
        <div id="step4">
            <div className="stepTitle">其他数据增强方式</div>
            <p className="text">
                前面几步，我们体验了通过几何变换、颜色变换来进行数据增强的几种方式，但实际上几何变换、颜色变换的方式还有很多，有些复杂的方式甚至需要结合另外一个 AI 模型来实现。
                <br/>
                
                <span>前面几步我们体验的都属于单样本数据增强，</span>
                即我们都是围绕某个样本本身进行各种操作。
                <br/>
                
                <span>还有一大类数据增强方式叫多样本数据增强，</span>
                可以利用多个样本来产生新的样本。
            </p>

            <div className="stepTitle secondTitle">SMOTE</div>
            <p className="text smote">
                即 Synthetic Minority Over-sampling Technique（合成少数过采样技术），这是一种典型的多样本数据增强技术，通过
                <br/>
                <span>在小样本类中的样本之间进行插值来生成新样本，</span>
                由此来解决类不平衡的问题。
                <br/>
                类不平衡现象指的是数据集中各类别的样本数量不近似相等。
                <span>如果类别之间样本数量相差很大，会影响分类器的分类效果，</span>
                「小类」可能会「被大类吞并」。这种问题在实际采样过程中经常出现，比如：某种农作物病虫害现象很少出现，而另一种农作物病虫害现象则十分常见，就会导致收集到的两类样本的量差异很大。
            </p>

            <div className="example">
                <p><span>示例：</span></p>
                <p>
                    <span
                        className={[
                            step >= 0 ? "exampleStep" : "",
                            isClick1 ? "exampleStepActive" : "",
                            "examplesAll"
                        ].join(" ")}
                        onClick={stepPlus1}
                    >1. 随机选择 1 张照片</span>
                </p>
                <p>
                    <span
                        className={[
                            step >= 1 ? "exampleStep" : "",
                            isClick ? "exampleStepActive" : "",
                            "examplesAll"
                        ].join(" ")}
                        onClick={stepPlus2}
                    >2. 随机选择 1 张与上一张照片参数上临近的照片</span>
                </p>
                <p>
                    <span
                        className={[
                            isClick  ? "exampleStep" : "",
                            isClick3 ? "exampleStepActive" : "",
                            "examplesAll"
                        ].join(" ")}
                        onClick={stepPlus3}
                    >3. 根据算法生成一张参数位于这两张照片之间的照片</span>
                </p>


                <div className={ !step ? "picBox" : "picBox paddingBox"}>
                    {
                        step === 1
                            ? <img
                                src="/image/cropDiseases/random1.png"
                                alt=""
                              />
                            : step === 2
                                ? <>
                                    <img
                                        src="/image/cropDiseases/random1.png"
                                        alt=""
                                    />
                                    <img
                                        src="/image/arrow-black.png"
                                        className="icon"
                                        alt=""
                                        style={{visibility:"hidden"}}
                                    />
                                    <img
                                        src="/image/cropDiseases/random3.png"
                                        alt=""
                                    />
                                  </>
                                : step === 3 &&
                                    <>
                                        <img
                                            src="/image/cropDiseases/random1.png"
                                            alt=""
                                        />

                                        <img
                                            src="/image/arrow-black.png"
                                            className="icon"
                                            alt=""
                                        />
                                        <img
                                            src="/image/cropDiseases/random2.png"
                                            alt=""
                                        />

                                        <img
                                            src="/image/arrow-black.png"
                                            className="icon"
                                            alt=""
                                            style={{transform:"rotate(180deg)"}}
                                        />

                                        <img
                                            src="/image/cropDiseases/random3.png"
                                            alt=""
                                        />
                                    </>
                    }
                </div>

            </div>

            <div className="stepTitle secondTitle">无监督数据增强</div>

            <p className="text">
                AutoAugment 是 Google 提出的自动选择最优数据增强方案的研究，这是无监督数据增强的重要研究方向。
                <br/>
                前面几步的实验我们已经可以意识到，
                <span>不同的数据增强方式有不同的作用。</span>
                在实际应用过程中，如果只是简单地使用各种数据增强方法去无限制地扩充样本库可能会导致运算量增大，而且新生成的样本也可能污染原有数据集导致模型准确率下降。所以我们往往需要多次实验，慎重选择数据增强的方法。
                <span>
                    AutoAugment 的基本思路是引入强化学习，从数据本身寻找最佳图像变换策略，自动对不同的任务采取不同的数据增强方法。
                </span>
            </p>


            <div className="buttons">
                <Button onClick={props.prev}>上一步</Button>
                <Button onClick={props.next} type="primary">下一步</Button>
            </div>
        </div>
    )
}

export default StepFour;
