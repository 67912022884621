import { createSlice } from '@reduxjs/toolkit'

export const speakerSlice = createSlice({
    name: 'speaker',
    initialState: {
        /** steps **/
        record: false,
        upload: false,
        train: false,
        complete: false,
        /** 录音相关 **/
        // 录音分页
        recRecord: false,
        recUploadPending: false,
        recUpload: false,
        recComplete: false,
        url: '',
        // 上传分页
        userRecord: false,
        userUploadPending: false,
        userUpload: false,
        userComplete: false,
        /** 上传时禁止其他操作 **/
        uploadFlag: true,
        /** 录音时禁止其他操作 **/
        recordFlag: true
    },
    reducers: {
        setRecord: (state,action) => {
            state.record = action.payload;
        },
        setUpload: (state, action) => {
            state.upload = action.payload;
        },
        setTrain: (state, action) => {
            state.train = action.payload;
        },
        setComplete: (state, action) => {
            state.complete = action.payload;
        },
        setRecRecord: (state, action) => {
            state.recRecord = action.payload;
        },
        setRecUploadPending: (state, action) => {
          state.recUploadPending = action.payload;
        },
        setRecUpload: (state, action) => {
            state.recUpload = action.payload;
        },
        setRecComplete: (state, action) => {
            state.recComplete = action.payload;
        },
        setUserUpload: (state, action) => {
            state.userUpload = action.payload;
        },
        setUserUploadPending: (state, action) => {
            state.userUploadPending = action.payload;
        },
        setUserComplete: (state, action) => {
            state.userComplete = action.payload;
        },
        setUrl: (state,action) => {
            state.url = action.payload;
        },
        setUserRecord: (state, action) => {
            state.userRecord = action.payload;
        },
        setUploadFlag: (state, action) => {
            state.uploadFlag = action.payload;
        },
        setRecordFlag: (state, action) => {
            state.recordFlag = action.payload;
        }
    }
})

export const {
    setRecord,
    setUpload,
    setTrain,
    setComplete,
    setRecRecord,
    setRecUploadPending,
    setRecUpload,
    setRecComplete,
    setUserUpload,
    setUserUploadPending,
    setUserComplete,
    setUrl,
    setUserRecord,
    setUploadFlag,
    setRecordFlag
} = speakerSlice.actions;

export default speakerSlice.reducer;
