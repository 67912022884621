import React, {useEffect, useState, useRef} from "react";
import "./style.scss";
import RecordPage from "./record";
import UploadPage from "./upload";
import {getSpeakerTraining} from '../../utils'
const SpeakerRecognition = () => {

    const [current, setCurrent] = useState('record');
    const [source, setSource] = useState('');
    useEffect(() => {
        getSpeakerTraining().then(res => {
            if (res.source === 'upload') {
                setCurrent('upload')
                setSource('upload')
            } else {
                setSource('record')
            }
        })
    },[])

    const infoBox = useRef()
    const messageBox = useRef()
    const props = { current, setCurrent , source, infoBox, messageBox }

    return(
        <div id="speakerBox">
            <div className="title">说话人识别</div>

            <div className='switch'>
                <span onClick={() => setCurrent('record')} style={{ 'color': current === 'record' ? '#0084ff' : '#333333' }}>录入声音</span>
                <span onClick={() => setCurrent('upload')} style={{ 'color': current === 'upload' ? '#0084ff' : '#333333' }}>上传音频</span>
            </div>

            <div className="content">
                <RecordPage {...props}/>
                <UploadPage {...props}/>
            </div>

            <p className="weDontSaveYourVoice">
                你的声音仅用于训练声纹模型，英荔 AI 实验平台不会保存你的原始声音文件
            </p>

            <div ref={infoBox} className="moveContainer"></div>
            <div ref={messageBox} className="messageInfo"></div>
        </div>
    )
}


export default SpeakerRecognition
