import React, { useEffect, useState, useRef } from 'react';
import {Steps, Button, notification} from 'antd';
import './style.scss'
import StepOne from './stepsContent/stepOne';
import StepTwo from './stepsContent/stepTwo';
import StepThree from './stepsContent/stepThree';
import StepFour from './stepsContent/stepFour';
import StepFive from './stepsContent/stepFive';
import {cacheRecognitionImages} from "../../utils";
import {useSelector, useDispatch} from "react-redux";
import {setHasForecast} from "../../store/cropSlice";
import {CloseCircleFilled} from "@ant-design/icons";
const { Step } = Steps;

const CropDiseasesAndPests = () => {
    const container = useRef();
    const [current, setCurrent] = useState(0);
    const [loading, setLoading] = useState(false);
    const [train, setTrain] = useState(false);
    const [isClick, setIsClick] = useState(false);
    const [upload, setUpload] = useState(false);
    const [recognize, setRecognize] = useState(false); // 识别犬种
    const [recoginzing,setRecognizing] = useState(false); // 识别中
    const hasForecast = useSelector(state => state.crop.hasForecast);
    const dispatch = useDispatch();

    useEffect(() => {
        cacheRecognitionImages()
            .then(res => {
                // res.image_info = '';
                if (res.image_info) {
                    setCurrent(4);
                    dispatch(setHasForecast(true));
                    const data = JSON.parse(res.image_info);
                    window.localStorage.setItem('diseaseType', data.crop_disease);
                    window.localStorage.setItem('cropImage', data.image);
                } else {
                    // 清空图片和预测类型
                    window.localStorage.setItem('diseaseType', '');
                    window.localStorage.setItem('cropImage', '');
                }
            }).catch(err => {
                console.log('---error---',err);
                notification.open({
                    message: '错误',
                    description: '你的网络存在问题或缺少该功能的使用权限。请稍后再试，或联系实验室管理员',
                    placement: 'top',
                    duration: 3,
                    className: 'errorMsg',
                    getContainer: () => container.current,
                    icon: <CloseCircleFilled style={{color:'#ff4d4f',fontSize:'22px'}}/>,
                });
            })
    },[dispatch])

    const onChangeStep = num => {
        if (current < num) return;
        setCurrent(num);
    };
    const next = () => {
        window.scrollTo(0,0);
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };

    const startTraining = () => {
        setIsClick(true);
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setTrain(true);
        },3000)
    }

    // 识别并上传
    const handleRecognizeDog = () => {
        setUpload(true);
        setLoading(true);
        setTimeout(() => {
            setUpload(false);
        },1000)
        setTimeout(() => {
            setRecognizing(true);
            setTimeout(() => {
                setRecognize(true);
            },1000)
        },1000)
        setRecognizing(false);
    }
    
    const stepsCont = [
        {
            title: '农作物病虫害识别的背景',
            content: <StepOne train={train} isClick={isClick}/>,
            step : current === 0 ? 'process' : 'finish'
        },
        {
            title: '几何变换',
            content: <StepTwo prev={prev} next={next}/>,
            step: current === 1 ? 'process' : current < 1 ? 'wait' : 'finish'
        },
        {
            title: '颜色变换',
            content: <StepThree prev={prev} next={next}/>,
            step: current === 2 ? 'process' : current < 2 ? 'wait' : 'finish'
        },
        {
            title: '其他数据增强方式',
            content: <StepFour prev={prev} next={next}/>,
            step: current === 3 ? 'process' : current < 3 ? 'wait' : 'finish'
        },
        {
            title: '体验训练效果',
            content:
                <StepFive
                    prev={prev}
                />,
            step: current === 4 && !hasForecast ? 'process' : current < 4 ? 'wait' : 'finish'
        },
    ]

    const imgsrc = status => {
        let iconsrc = '';
        switch (status) {
            case 'wait':
                iconsrc = '/image/deepLearning/wait.png';
                break;
            case 'process':
                iconsrc = '/image/deepLearning/process.png';
                break;
            case 'finish':
                iconsrc = '/image/deepLearning/finish.png';
                break;
            default:
                break;
        }
        return iconsrc;
    };


    return(
        <div id="box">
            <div className="title">农作物病虫害识别</div>

            <div className="content">

                <div className="steps">
                    <Steps
                        current={current}
                        labelPlacement="vertical"
                        onChange={onChangeStep}
                    >
                        {stepsCont.map(item => (
                                <Step
                                    key={item.title}
                                    title={item.title}
                                    status={item.step}
                                    icon={
                                        <img
                                            width={18}
                                            src={imgsrc(item.step)}
                                            alt=""
                                        />
                                    }
                                />
                            ))}
                    </Steps>
                </div>


                {/* 各步骤内容区 */}
                <div className="stepsContent">
                    {stepsCont[current].content}
                </div>

                {/* step1 */}
                {
                    !current && !train
                        ?
                        <Button
                            type="primary"
                            className="primaryButton"
                            onClick={startTraining}
                            loading={loading}
                        >
                            {loading ? "训练中" : "开始训练"}
                        </Button>
                        :
                        !current && !recognize
                            ?
                            <>
                                <Button
                                    type="primary"
                                    className="primaryButton"
                                    onClick={handleRecognizeDog}
                                    loading={loading}
                                >
                                    {
                                        upload 
                                            ? "上传中"
                                            : recoginzing 
                                                ? "识别中"
                                                : "上传并识别"
                                    }
                                </Button>
                            </>
                            :
                            !current &&
                            <>
                                <div className="afterRecognize">
                                    <p className="text result">预测结果：柴犬</p>
                                    <p className="text result">
                                        为什么 AI 会做出错误的判断呢？很可能是因为 AI 认为两个训练集最大的差异不是在狗狗脸部的毛色上，而是在姿势上——柴犬都是坐姿的，秋田犬都是站姿的。进而得出了一个抽象的判断规律「坐着的都是柴犬，站着的都是秋田犬」。
                                    </p>
                                    <p className="text result">
                                        这就是训练数据集不足导致的。如果要比较好地区分柴犬和秋田犬，我们可以：
                                        <br/>
                                        <span className="weightSpan">
                                        1、收集尽可能多的数据。
                                    </span>
                                        比如在不同光照条件下，不同背景前，拍摄不同姿势不同表情的狗狗照片。
                                        <br/>
                                        <span className="weightSpan">
                                        2、人为设定一些剪裁规则。
                                    </span>
                                        比如我们知道柴犬和秋田犬有个比较明显的差异在脸部的毛色分布上，那就可以先用一个模型把狗狗的脸部裁切出来，然后再区分不同犬种。
                                    </p>
                                    <p className="text result">
                                        实际生活中，农作物叶片样式各异，病虫害情况繁多。
                                        <span className="weightSpan">
                                        在对农作物病虫害识别工具的开发过程中，收集一个齐全的训练数据集几乎是不可能完成的任务，所以我们需要对现有数据进行增强。
                                    </span>
                                        接下来，就让我们了解一些常见的数据增强方法。
                                    </p>
                                </div>

                                <Button
                                    type="primary"
                                    className="primaryButton"
                                    onClick={next}
                                >
                                    下一步
                                </Button>
                            </>
                }

            </div>
            <div className="errorContainer" ref={container}/>
        </div>
    )
}

export default CropDiseasesAndPests;
