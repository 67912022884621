import React, {useState, useRef} from 'react';
import {Modal, Carousel} from 'antd';
import {LeftOutlined ,RightOutlined} from '@ant-design/icons';
import './style.scss';

const StepOne = props => {

    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [currentSlide1, setCurrentSlide1] = useState(0);
    const [currentSlide2, setCurrentSlide2] = useState(0);

    // 更改 modal 挂载节点
    const container = useRef();
    const modalContainer = () => {
        return container.current;
    }

    const slide1 = useRef();
    const slide2 = useRef();

    const dogsImage = (name) => {
        const arr = [];
        for(let i = 1; i <= 6; i++) {
            arr.push(`/image/cropDiseases/${name}/dog${i}.png`);
        }
        return arr;
    }

    const dogs = [
        {
            id: 1,
            name: '柴犬',
            class: 'dog1',
            imgsrc: 'chai_dog',
            modal,
            showModal() {
                setModal(true);
            },
            cancelModal() {
                // setCurrentSlide1(0);
                setModal(false);
            },
            images: dogsImage('chai_dog'),
            slide: slide1,
            currentSlide: currentSlide1,
            nextSlide() {
                // 关闭轮播图循环
                if(currentSlide1 === 5) return
                slide1.current?.next();
            },
            prevSlide() {
                if(currentSlide1 === 0) return
                slide1.current?.prev();
            },
            handleSlideChange(from, to) {
                setCurrentSlide1(to);
            }
        },
        {
            id: 2,
            name: '秋田犬',
            class: 'dog2',
            imgsrc: 'qiutian_dog',
            modal:modal2,
            showModal() {
                setModal2(true);
            },
            cancelModal() {
                setModal2(false);
            },
            images: dogsImage('qiutian_dog'),
            slide: slide2,
            currentSlide: currentSlide2,
            nextSlide() {
                // 关闭轮播图循环
                if(currentSlide2 === 5) return
                slide2.current?.next();
            },
            prevSlide() {
                if(currentSlide2 === 0) return
                slide2.current?.prev();
            },
            handleSlideChange(from, to) {
                setCurrentSlide2(to);
            }
        }
    ]

    return(
        <div id="step1" ref={container}>
            <div className="stepTitle">农业病虫害识别的背景</div>
            <p className="text text1">
                农作物病虫害是我国的主要农业灾害之一，它具有种类多、影响大，并时常暴发成灾的特点。精准且快速地诊断病害可以大大减少经济损失。而植物病害种类繁多，不同生长阶段的植物存在不同种类的病害，这导致非专业人士对于植物病害的分类经常不够精确，快速。随着深度学习的不断发展，图像识别在众多领域得到了广泛的应用，
                <span className='weightSpan'>
                    相对于人工识别植物病害，采用计算机视觉技术识别农作物病虫害不仅成本低而且时效快。
                </span>
            </p>

            <div className="bigPic">
                <img src="/image/cropDiseases/background.png" alt=""/>
            </div>

            <p className="text text2">
                <span className='weightSpan'>
                    缺乏大的、标注良好的、高度可变性的图像数据集是训练植物病害识别系统的主要障碍。
                </span>
                PlantVillage 数据集和植物病害症状图像数据库（PDDB）是目前仅有的 2 个大型公开数据集。
            </p>

            <div className="stepTitle">样本不足对分类的影响</div>
            <div className="notEnoughSample">
                {dogs.map(item => (
                    <div className="dog" key={item.id}>
                        <div className="dogPic">
                            <img
                                src={dogsImage(item.imgsrc)[0]}
                                alt=""
                            />
                        </div>
                        <div className="sample">
                            <p>{item.name}样本（共 6 份）</p>
                            <span onClick={item.showModal}>查看完整样本库</span>
                            <Modal
                                centered
                                className={item.class}
                                title={item.name+"样本库"}
                                visible={item.modal}
                                footer={null}
                                onCancel={item.cancelModal}
                                getContainer={modalContainer}
                            >
                                <div className="dogs">

                                    <div className="slide">

                                        <div className="left both">
                                            <span
                                                className="picbtn"
                                                onClick={item.prevSlide}
                                                style={
                                                    item.currentSlide
                                                        ? {cursor:'pointer'}
                                                        : {}
                                                }
                                            >
                                                <LeftOutlined
                                                    style={
                                                        !item.currentSlide
                                                            ? {color:'#d9d9d9'}
                                                            : {color:'#333'}
                                                    }
                                                />
                                            </span>
                                        </div>

                                        <Carousel
                                            dots={false}
                                            beforeChange={
                                                item.handleSlideChange
                                            }
                                            ref={item.slide}
                                        >
                                            {item.images.map((slideImg, idx)=>
                                               <div
                                                   className="slidePic"
                                                   key={idx}
                                               >
                                                   <img src={slideImg} alt=""/>
                                               </div>
                                            )}
                                        </Carousel>

                                        <div className="right both">
                                            <span
                                                className="picbtn"
                                                onClick={item.nextSlide}
                                                style={
                                                    item.currentSlide <= 4
                                                        ? {cursor:'pointer'}
                                                        : {}
                                                }
                                            >
                                                <RightOutlined
                                                    style={
                                                        item.currentSlide > 4
                                                            ? {color:'#d9d9d9'}
                                                            : {color:'#333'}
                                                    }
                                                />
                                            </span>
                                        </div>
                                    </div>

                                    <span className="whichPic">
                                        {item.currentSlide+1} / {item.images.length}
                                    </span>
                                </div>

                            </Modal>
                        </div>
                    </div>
                ))}
            </div>

            <p className="text text3">
                分类算法是通过一定策略去提取样本的特征，然后根据特征的不同得出「一套分类规则」。
                <br/>
                假设我们现在要训练一个模型去区分柴犬和秋田犬。看完完整的样本库应该可以很容易发现，柴犬和秋田犬面部的毛色分布是不太一样的。
            </p>

            {
                props.train &&
                <div className="afterTrain">
                    <p className="text text4">上传待测图片：微笑的秋田犬.jpg</p>
                    <div className="afterTrainPic">
                        <img src="/image/cropDiseases/sample.png" alt=""/>
                    </div>
                </div>
            }

        </div>
    )
}

export default StepOne;
