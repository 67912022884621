import React, { useEffect, useRef, useState } from 'react';
import SpeedOfProgress from '../speedOfProgress'
import { Button, notification, message } from 'antd';
import { ExclamationCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import { uploadPictures, getPicture, deleteCache } from '../../utils';
import './style.scss';
function state(take, transmission, complete) {
    this.take = take;
    this.transmission = transmission;
    this.complete = complete;
}
let time;
const Upload = () => {
    // 这个对象是上传的状态
    const [speedOfProgress, setSpeedOfProgress] = useState(new state(false, false, false));
    const [imageSrc, setImageSrc] = useState('');
    const file = useRef();
    useEffect(() => {
        // 进入后先判断有没有暂时录入的参照，如果有就直接用，没有就去获取，有就将参照放到页面
        if (sessionStorage.getItem('referencePicture')) {
            setSpeedOfProgress(new state(true, true, true));
            setImageSrc(sessionStorage.getItem('referencePicture'));
        } else {
            setTimeout(() => {
                getPicture().then((res) => {
                    if (res.img) {
                        let img = JSON.parse(res.img);
                        setImageSrc(img.image);
                        sessionStorage.setItem('referencePicture', img.image);
                        setSpeedOfProgress(new state(true, true, true));
                    }
                })
            }, 500)
        }
    }, [])
    const Change = function () {
        // 用户点击上传后上传的图片解析后放到界面
        let fileData = file.current.files[0];
        if ((fileData.type === 'image/jpg' || fileData.type === 'image/png' || fileData.type === 'image/jpeg' || fileData.type === 'image/bmp') && fileData.size < 2 * 1024 * 1024) {
            var reader = new FileReader();
            reader.readAsDataURL(fileData);
            reader.onload = (e) => {
                setImageSrc(e.currentTarget.result);
                setSpeedOfProgress(new state(true, false, false))
            }
        } else {
            notification.info({
                message: '提示',
                description:
                    '经检测，你上传的图片格式不支持或过大，请选择另一张图片',
                icon: <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />
            });
        }
    }
    const Click = () => {
        // 如果用户已经有参照就清除这个参照，然后回到第一状态
        if (sessionStorage.getItem('referencePicture')) {
            deleteCache();
            sessionStorage.removeItem('referencePicture');
            message.success('当前录入的人脸数据已清除，重新录入后方可继续实验');
        }
        setImageSrc('');
        file.current.value = '';
        clearTimeout(time);
        setSpeedOfProgress(new state(false, false, false));
        file.current.click();
    }
    const up = () => {
        // 用户点击上传后先转两秒再上传，为了可以让用户后悔然后点击重新选择（我的评价是：没有意义）
        setSpeedOfProgress(new state(true, 'ing', false));
        time = setTimeout(() => {
            uploadPictures(imageSrc).then(() => {
                sessionStorage.setItem('referencePicture', imageSrc);
                setSpeedOfProgress(new state(true, true, true));
            }).catch(() => {
                message.error('上传失败，请重试');
                setSpeedOfProgress(new state(true, false, false));
            });
        }, 2000)
    }
    return (
        <div className='upload'>
            <SpeedOfProgress
                speedOfProgress={speedOfProgress}
            />
            {speedOfProgress.take ?
                <img className='preview' alt='' src={imageSrc}></img>
                : <div className='choice'></div>
            }
            {
                speedOfProgress.complete ?
                    <div className='complete'>
                        <CheckCircleFilled style={{ color: '#52c41a' }} />
                        <div>参照人脸录入完成，请前往英荔创作平台继续实验</div>
                    </div>
                    : <div className='Tips'>图片文件需要包含清晰的人脸，请勿上传包含敏感信息的图片。支持 PNG、JPG、JPEG、BMP，图片大小不超过 2M</div>
            }
            {
                speedOfProgress.take && !speedOfProgress.complete ?
                    <div className='SelectionComplete'>
                        <Button onClick={Click} className='again program button'>重新选择</Button>
                        {speedOfProgress.transmission === 'ing' ?
                            <Button onClick={up} disabled className='disabled button' type='primary'>上传</Button>
                            : <Button onClick={up} className='determine button' type='primary'>上传</Button>}
                    </div>
                    : (speedOfProgress.complete ? <Button onClick={Click} className='again program button'>重新录入</Button>
                        : <Button onClick={Click} className='file' type='primary'>浏览本地文件</Button>)
            }
            <input style={{ 'position': 'fixed', 'top': '-9999px', 'left': '-9999px' }} type='file' ref={file} onChange={Change} accept='image/jpg,image/png,image/jpeg,image/bmp' />
        </div >
    )
}
export default Upload;