import React, { useState, useRef } from 'react';
import { Button, Modal, Table, message, Popover } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
// import FlappyBird from '../flappyBird/index';
import { QuestionCircleFilled } from '@ant-design/icons';
import {
  deepLearn_humanTrain,
  deepLearn_humanTrainResult,
  deepLearn_trainCancel_or_Ok
} from '../../../utils';
import { humanTrainResult } from '../../../store/createSlice';
import './style.scss';
import { useEffect } from 'react';
const columns = [
  {
    title: '试验序号',
    dataIndex: 'sort_num',
    key: 'id',
    width: 186
    // render:(text,record,index)=>{
    //   return index +1;
    // }
  },
  {
    title: '得分',
    dataIndex: 'score',
    key: 'score',
    width: 186
  }
];
const columnsAI = [
  {
    title: '试验序号',
    // dataIndex: 'id',
    key: 'id',
    width: 186,
    render: (text, record, index) => {
      return index + 1;
    }
  },
  {
    title: '得分',
    dataIndex: 'score',
    key: 'score',
    width: 186
  }
];
const content = (
  <>
    <span style={{ width: '300px', display: 'block' }}>
      模型训练的过程中，本身就存在一定的随机性。
      同时，为了让有限的样本尽可能模拟出难以计量的现实情况，我们还时常需要人为地在神经网络中添加随机量，以避免出现「过拟合」的情况。
    </span>
    <span style={{ width: '300px', display: 'block' }}>
      所以，用同样的参数去训练，模型的效果也不一定一致，甚至有时候会出现参数变大了，效果还变差了的情况。
    </span>
  </>
);
const humanContent = (
  <>
    <span style={{ width: '280px', display: 'block' }}>
      你所有的游戏记录我们都会长期留存，你的有效游戏时长就是你的训练时长。
    </span>
    <span style={{ width: '280px', display: 'block' }}>
      AI
      也是这样一次一次训练自己的，在经历成千上万次迭代后才能达到比较完善的状态。
    </span>
  </>
);
const StepFive = props => {
  const dispatch = useDispatch();
  const [modalAI, setModalAI] = useState(false);
  const [modalHuman, setModalHuman] = useState(false);
  const [paginationProps, setPaginationProps] = useState({
    simple: true, // showTotal 会失效
    pageSize: 5 // 每页条数
  });
  const [data, setData] = useState([]);
  const [humanLoading, setHumanLoading] = useState(false);
  const AIModelResult = useSelector(state => state.counter.AIModelResult);

  const humanTrain_result = useSelector(
    state => state.counter.humanTrainResult
  );
  const [AIAutoplay, setAIAutoplay] = useState(false);
  const [videoSrc, setVideoSrc] = useState(
    'https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/109fe754387702303615969046/v.f40671.mp4'
  );

  const videoRef = useRef(null);
  // const iframeRef = useRef(null);

  const handleAIResult = () => {
    setModalAI(true);
  };
  const handlePlayer = () => {
    setAIAutoplay(true);
    videoRef?.current?.play();
  };
  const handleHumanResult = async (params = {}) => {
    setHumanLoading(true);
    try {
      let data = await deepLearn_humanTrainResult(
        `?page_index=${params.current}&page_size= ${paginationProps.pageSize}`
      );

      const pageProps = { ...paginationProps };
      pageProps.total = data.count;

      setPaginationProps(pageProps);
      setModalHuman(true);
      setHumanLoading(false);
      setData(data.data);
    } catch (error) {
      setModalHuman(true);
    }
  };
  const handleTableChange = (newPagination, filters, sorter) => {
    handleHumanResult({ current: newPagination.current });
  };

  const handleClose = () => {
    setModalAI(false);
    setModalHuman(false);
  };
  const handleOk = () => {
    setModalAI(false);
    setModalHuman(false);
  };

  const retrain = () => {
    let params = { id: AIModelResult.id, opt_type: 0 };
    deepLearn_trainCancel_or_Ok(params)
      .then(res => {
        message.success('现有模型已清除，请重新训练');
        window?.opener?.postMessage(
          {
            trained: false // 训练是否完成
          },
          '*'
        );
        props.prev();
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  /* 获取iframe传过来的参数 */

  // window.addEventListener(  // 会触发两次
  //   'message',
  //   e => {
  //   },
  //   true
  // );
  window.onmessage = e => {
    if (!e.data.GAME_STATE) return;
    deepLearn_humanTrain({
      play_time: !e.data.TIME ? 1 : e.data.TIME,
      score: e.data.GAME_STATE.score
    })
      .then(res => {
        if (res.success) dispatch(humanTrainResult(res.data));
      })
      .catch(err => message.error(err.message));
  };

  window?.opener?.postMessage(
    {
      AIModel_result: AIModelResult,
      humanTrain_result,
      trained: true
    },
    '*'
  );
  // useEffect(() => {
  //   return () => {
  //     console.log('销毁步骤五');
  //     window?.opener?.postMessage(
  //       {
  //         trained: false // 训练是否完成
  //       },
  //       '*'
  //     );
  //   };
  // }, []);
  useEffect(() => {
    if (
      humanTrain_result?.people_five_play_avg_score ||
      AIModelResult?.people_five_avg_score
    ) {
      //  步骤条状态为已完成
      props.handleStepFinish();
    }
  }, [
    humanTrain_result?.people_five_play_avg_score,
    AIModelResult?.people_five_avg_score,
    props
  ]);
  useEffect(() => {
    /*
      AI视频播放规则：
      平均分   视频最后得分
       [0,1)    0
       [1,2)    1
       [2,3)    2
       [3,4)    3
       [4,5)    4
       [5,25)   5
      [25,75)   25
      [75,125)  75
      [125,175) 125
        >=175   175

    */
    let videoSrc = '';
    if (AIModelResult?.ai_five_avg_score < 1) {
      videoSrc =
        'https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/109fe754387702303615969046/v.f40671.mp4';
    } else if (
      AIModelResult?.ai_five_avg_score === 1 ||
      (AIModelResult?.ai_five_avg_score > 1 &&
        AIModelResult?.ai_five_avg_score < 2)
    ) {
      videoSrc =
        'https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/108b150d387702303615954135/v.f40671.mp4';
    } else if (
      AIModelResult?.ai_five_avg_score === 2 ||
      (AIModelResult?.ai_five_avg_score > 2 &&
        AIModelResult?.ai_five_avg_score < 3)
    ) {
      videoSrc =
        'https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/108a9a51387702303615953581/v.f40671.mp4';
    } else if (
      AIModelResult?.ai_five_avg_score === 3 ||
      (AIModelResult?.ai_five_avg_score > 3 &&
        AIModelResult?.ai_five_avg_score < 4)
    ) {
      videoSrc =
        'https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/0c03d7a9387702303615772025/v.f40671.mp4';
    } else if (
      AIModelResult?.ai_five_avg_score === 4 ||
      (AIModelResult?.ai_five_avg_score > 4 &&
        AIModelResult?.ai_five_avg_score < 5)
    ) {
      videoSrc =
        'https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/0c036970387702303615771753/v.f40671.mp4';
    } else if (
      AIModelResult?.ai_five_avg_score === 5 ||
      (AIModelResult?.ai_five_avg_score > 5 &&
        AIModelResult?.ai_five_avg_score < 25)
    ) {
      videoSrc =
        'https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/0e148efa387702303615839668/v.f40671.mp4';
    } else if (
      AIModelResult?.ai_five_avg_score === 25 ||
      (AIModelResult?.ai_five_avg_score > 25 &&
        AIModelResult?.ai_five_avg_score < 75)
    ) {
      videoSrc =
        'https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/10a009c0387702303615969877/v.f40671.mp4';
    } else if (
      AIModelResult?.ai_five_avg_score === 75 ||
      (AIModelResult?.ai_five_avg_score > 75 &&
        AIModelResult?.ai_five_avg_score < 125)
    ) {
      videoSrc =
        'https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/0e148272387702303615839381/v.f40671.mp4';
    } else if (
      AIModelResult?.ai_five_avg_score === 125 ||
      (AIModelResult?.ai_five_avg_score > 125 &&
        AIModelResult?.ai_five_avg_score < 175)
    ) {
      videoSrc =
        'https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/1055fd91387702303615926716/v.f40671.mp4';
    } else if (
      AIModelResult?.ai_five_avg_score === 175 ||
      AIModelResult?.ai_five_avg_score > 175
    ) {
      videoSrc =
        'https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/1055f5d6387702303615926577/v.f40671.mp4';
    }
    setVideoSrc(videoSrc);
  }, [AIModelResult?.ai_five_avg_score]);
  return (
    <div className="step" id="stepFive">
      <div className="title">测试模型</div>
      <p className="describe">
        模型已经训练完成，并自动完成了 5
        次尝试，看看它玩游戏的效果如何？你也可以自己体验一下，看看和 AI
        比起来谁更厉害，谁的学习效率更高。
      </p>
      <div className="game">
        <div className="AIGame">
          {/* <div className="alignLeft"> */}
          <div className="picture">
            <video
              ref={videoRef}
              src={videoSrc}
              width="296"
              height="520"
              controls={false}
              // autoPlay={AIAutoplay}
              onPlay={() => {
                // console.log('play >>>>>> ');
              }}
              onPause={() => {
                setAIAutoplay(false);
                // console.log('====== pause ===');
              }}

              // poster="/image/deepLearning/AIGame.png"
              // controlslist="nodownload nofullscreen"
            ></video>
            {/* <img src="/image/deepLearning/AIGame.png" alt="部分试验记录" /> */}

            {!AIAutoplay ? (
              <>
                <div className="mark"></div>
                <div className="playerBox">
                  <img
                    src="/image/deepLearning/player.png"
                    className="player"
                    alt=""
                    onClick={handlePlayer}
                  />

                  <div className="playTxt">部分试验记录</div>
                </div>
              </>
            ) : null}
          </div>
          <span className="text">
            AI 本次训练时长：{AIModelResult.train_times} <br />5 次尝试 AI
            平均得分：{AIModelResult.ai_five_avg_score} 分
          </span>
          <span className="link" onClick={handleAIResult}>
            查看当前模型的参数和试验结果
          </span>
          {/* </div> */}

          <div className="tips">
            观察 AI 的试验结果，如果效果不好可以重新训练
          </div>
          <div className="btn">
            <Button type="primary" ghost onClick={retrain}>
              重新训练
            </Button>
          </div>
        </div>
        <div className="humanGame">
          <div className="picture">
            <iframe
              // ref="iframeRef"
              title="人类训练"
              src="/flappyBird.html"
              width={296}
              height={520}
              scrolling="no"
            ></iframe>
          </div>
          <span className="text">
            人类训练历史时长：
            {humanTrain_result?.people_total_times ||
              AIModelResult?.people_train_times}
            <br />
            人类最好的{' '}
            {humanTrain_result?.people_count ||
              AIModelResult?.people_count}{' '}
            次尝试平均得分：
            {humanTrain_result?.people_five_play_avg_score ||
              AIModelResult.people_five_avg_score ||
              0}{' '}
            分
          </span>
          <span
            className="link"
            onClick={() => handleHumanResult({ current: 1 })}
          >
            人类试验结果
          </span>
          <div className="tips">
            单击鼠标左键，控制小鸟向上飞一段距离，
            <br /> 避开水管得 1 分，尽量让小鸟可以飞得更远
          </div>
        </div>
      </div>
      {/* AI模型 */}
      <Modal
        title="模型参数和试验结果"
        visible={modalAI}
        onOk={handleOk}
        onCancel={handleClose}
        maskClosable={false}
        destroyOnClose={true}
        width={747}
        // cancelText={'取消'}
        okText={'确定'}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div className="stepModalContent">
          <div className="imgBox" style={{ marginTop: '12px' }}>
            <img src="/image/deepLearning/bird.png" alt="" />
          </div>
          <div className="list">
            <div className="l">
              <span className="litTitle">模型关键参数：</span>
              <div className="textBox">
                <span style={{ marginTop: '10px' }}>
                  {' '}
                  分配算力：{AIModelResult.power}
                </span>
                <span>
                  卷积层数：{AIModelResult.h_conv} <br />
                  池化层数：{AIModelResult.h_pool}
                  <br /> 全连接层数：{AIModelResult.h_fc}
                </span>
                <span>
                  {/* 单个状态所含图像数量：{AIModelResult.image_nums}
                  <br /> */}
                  样本采集阶段的循环次数（万次）：{AIModelResult.observe}
                  <br />
                  训练模型阶段的迭代次数（万次）：{AIModelResult.explore}
                </span>
              </div>
              <span className="litTitle" style={{ marginTop: '20px' }}>
                训练耗时：<span>{AIModelResult.train_times}</span>
              </span>
            </div>
            <div className="r">
              <span className="litTitle">
                试验结果：
                <Popover
                  placement="right"
                  title={'为什么用同样的参数去训练，几次效果不一致？'}
                  content={content}
                  trigger="hover"
                >
                  {' '}
                  <QuestionCircleFilled style={{ color: '#999' }} />
                </Popover>
              </span>

              <Table
                rowKey={record => record.id}
                pagination={false}
                columns={columnsAI}
                dataSource={AIModelResult.aitrain_list}
                size="small"
              />
            </div>
          </div>
        </div>
      </Modal>
      {/* 人类试验 */}
      <Modal
        title="人类试验结果"
        visible={modalHuman}
        onOk={handleOk}
        onCancel={handleClose}
        maskClosable={false}
        destroyOnClose={true}
        width={420}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText={'确定'}
      >
        <div className="stepModalContent">
          <div className="imgBox">
            <img src="/image/deepLearning/bird.png" alt="" />
          </div>
          <span className="litTitle" style={{ marginTop: '24px' }}>
            人类训练历史时长：
            <span>
              {humanTrain_result?.people_total_times ||
                AIModelResult.people_train_times}
            </span>
          </span>
          <span className="litTitle" style={{ marginTop: '20px' }}>
            试验结果：{' '}
            <Popover placement="right" content={humanContent} trigger="hover">
              {' '}
              <QuestionCircleFilled style={{ color: '#999' }} />
            </Popover>
          </span>
          <div className="tableBox">
            <Table
              columns={columns}
              dataSource={data}
              rowKey={record => record.id}
              pagination={paginationProps}
              size="small"
              onChange={handleTableChange}
              loading={humanLoading}
            />

            {data.length ? (
              <span className="paginationTotal">
                共 {paginationProps.total} 条数据
              </span>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default StepFive;
