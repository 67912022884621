import React, {useEffect, useState} from 'react';
import {Button, notification, Spin} from "antd";
import {DownloadOutlined, CloseCircleFilled} from '@ant-design/icons';
import {cropDiseaseRecognition, cacheRecognitionImages} from '../../../utils';
import {setCachePreviewImg, setEmpty, setHasForecast} from "../../../store/cropSlice";
import {useSelector, useDispatch} from "react-redux";

const downloadLink =
    'https://e-course.oss-cn-shenzhen.aliyuncs.com/%E6%8F%92%E4%BB%B6%E8%B5%84%E6%96%99%E7%AE%A1%E7%90%86/%E5%86%9C%E4%BD%9C%E7%89%A9%E7%97%85%E8%99%AB%E5%AE%B3%E6%B5%8B%E8%AF%95%E6%A0%B7%E6%9C%AC.zip';

const StepFive = props => {
    const cachePreviewImg = useSelector(state => state.crop.cachePreviewImg);
    const empty = useSelector(state => state.crop.empty);
    const dispatch = useDispatch();
    // const [success, setSuccess] = useState(false);
    const [isForecast, setIsForeCast] = useState(false);
    const [diseaseType, setDiseaseType] = useState('');

    useEffect(() => {
        // wrongImage()
        // otherError()
        const type = window.localStorage.getItem('diseaseType');
        if (type 
            && 
            Object.prototype.toString.call(type) !== '[object Object]'
        ) {
            setDiseaseType(type);
        } 
        if (Object.prototype.toString.call(type) === '[object Object]') {
            otherError()
        }
        if (cachePreviewImg) return;
        const img = window.localStorage.getItem('cropImage');
        if (img && type) {
            dispatch(setCachePreviewImg(img));
            // setSuccess(true);
            // setDiseaseType(type);
            return;
        }
        cacheRecognitionImages().then(res => {
            if (res.image_info) {
                const data = JSON.parse(res.image_info);
                // setSuccess(true);
                dispatch(setCachePreviewImg(data.image));
                setDiseaseType(data.crop_disease);
            } else {
                dispatch(setCachePreviewImg(''));
                // setSuccess(false);
                setDiseaseType('');
            }
        }).catch(err =>{
            console.log('---error---', err);
            otherError()
        })
    },[]); // eslint-disable-line

    const data = [
        {
            img: '/image/cropDiseases/test1.png',
            name: '数据增强',
        },
        {
            img: '/image/cropDiseases/test2.png',
            name: '模型选择',
        },
        {
            img: '/image/cropDiseases/test3.png',
            name: '参数调优',
        },
        {
            img: '/image/cropDiseases/test4.png',
            name: '训练模型',
        },
    ]

    // 图片格式错误
    const wrongImage = () => {
        notification.open({
            message: '错误',
            description: '经检测，你上传的图片格式不支持或过大，请选择另一张图片',
            placement: 'top',
            duration: 3,
            className: 'errorMsg',
            getContainer: () => document.querySelector('.errorContainer'),
            icon: <CloseCircleFilled style={{color:'#ff4d4f',fontSize:'22px'}}/>,
        });
    }

    // 其他错误
    const otherError = () => {
        notification.open({
            message: '错误',
            description: '你的网络存在问题或缺少该功能的使用权限。请稍后再试，或联系实验室管理员',
            placement: 'top',
            duration: 3,
            className: 'errorMsg',
            getContainer: () => document.querySelector('.errorContainer'),
            icon: <CloseCircleFilled style={{color:'#ff4d4f',fontSize:'22px'}}/>,
        });
    }

    const reader = new FileReader();

    // 处理图片预览路径
    const handleEvent = (event) => {
        // setSuccess(true);
        if (event.type === 'load') {
            dispatch(setCachePreviewImg(reader.result));
        }
    }

    /*const addListeners = (reader) => {
        reader.addEventListener('loadstart', handleEvent);
        reader.addEventListener('load', handleEvent);
        reader.addEventListener('loadend', handleEvent);
        reader.addEventListener('progress', handleEvent);
        reader.addEventListener('error', handleEvent);
        reader.addEventListener('abort', handleEvent);
    }*/

    // 浏览本地文件形成预览
    const choosePic = () => {
        const fileInput = document.createElement('input');
        fileInput.setAttribute('type','file');
        fileInput.setAttribute('style','display:none');
        // 限制图片类型
        fileInput.setAttribute('accept', '.jpg,.png,.bmp,.jpeg');
        document.body.appendChild(fileInput);
        fileInput.click();

        const handleSelected = () => {
            const file = fileInput.files[0];
            const types = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'];
            const typeCorrect = types.find(item => item === file.type);

            // 图片 > 2M 或者 格式错误
            if (file.size > 1024 ** 2 * 2 || !typeCorrect) {
                return wrongImage()
            }

            if (file) {
                // addListeners(reader);
                dispatch(setEmpty(true));
                reader.addEventListener('load', handleEvent);
                reader.readAsDataURL(file);
            }
        }
        fileInput.addEventListener('change', handleSelected);
        document.body.removeChild(fileInput);
    }

    // 预测病虫害
    const forecast = async () => {
        if (isForecast) return
        // const res = await cropDiseaseRecognition(previewImg.current.src);
        try {
            setIsForeCast(true);
            const res = await cropDiseaseRecognition(cachePreviewImg);
            // const {data:res} = await axios.post('http://localhost:5003/upload')
            // const cropImage = previewImg.current.src;
            const cropImage = cachePreviewImg;
            // res.data = ''
            if (res.data) {
                setTimeout(() => {
                    dispatch(setEmpty(false));
                    dispatch(setHasForecast(true));
                    setIsForeCast(false);
                    setDiseaseType(res.data);
                    // setTimeout(() => {
                    //     window.localStorage.setItem('cropImage', previewImg.current.src);
                    // })
                    window.localStorage.setItem('diseaseType', res.data);
                    window.localStorage.setItem('cropImage', cropImage);
                },1000)
            }
            if (!res || !res.data) {
                setIsForeCast(false);
                otherError();
            }
        } catch (err) {
            setIsForeCast(false);
            otherError();
            console.log('---error---',err);
        }
    }

    return(
        <div id="step5">
           <div className="stepTitle">训练农作物病虫害识别模型</div>
            <p className="text">
                在通过 AI 解决生活中的实际问题时，一般会经历以下流程。但这个过程往往不是一帆风顺的，模型的选择可能会影响数据增强方式的选择；调节参数的过程中也可能发现之前选择的模型不是很合适。一个成熟的 AI 产品需要反复打磨才能应用于生产实践。
            </p>

            <div className="dataPicBox">
                {data.map((item, index) =>
                    <>
                        <div className="dataPic">
                            <img src={item.img} alt=""/>
                            <span>{item.name}</span>
                        </div>

                        {
                            index < 3 &&
                            <img
                                src="/image/arrow-black.png"
                                className="icon"
                                alt=""
                            />
                        }
                    </>
                )}
            </div>

            <div className="stepTitle">体验农作物病虫害识别</div>

            {/* 下载测试数据集 */}
            <p className="text">
                我们训练数据集中的样本均为单片树叶的正面特写，所以训练出来的模型也只能较好地识别此类图片。
                <a style={{color:"#0084ff"}} href={downloadLink}>
                    <DownloadOutlined /> 下载测试数据集
                </a>
                在体验过测试数据集后，可以自己寻找农作物病虫害的图片上传。你会发现若上传的图片非正面照，或是存在多片树叶，又或是非特写（叶片占整幅画面的比例较小）都可能识别出错误的结果。这便是 AI 在实际应用中的局限性。
            </p>
            {
                cachePreviewImg
                    ?
                    isForecast
                        ?
                    <Spin>
                        <div className={cachePreviewImg ? isForecast ? "successPreview" :  "successPreview" : "preview"}>
                            <img src={cachePreviewImg}
                                 id="previewImg"
                                 alt=""
                            />
                        </div>
                    </Spin> 
                        :
                        <div className="successPreview" >
                            <img src={cachePreviewImg}
                                 id="previewImg"
                                 alt=""
                            />
                        </div> 
                    : 
                    <div className="preview">
                        <Button type="primary" onClick={choosePic}>
                            浏览本地文件
                        </Button>
                    </div>
            }
            <p className="uploadsText">
                请上传测试集中的病虫害图片，请勿上传包含敏感信息的图片。支持 PNG、JPG、JPEG、BMP，图片大小不超过 2M
            </p>

            { diseaseType && 
                !empty &&
                Object.prototype.toString.call(diseaseType) !== '[object Object]' &&
                <p className="forecast">预测类型为：{diseaseType}</p>
            }

            {
                cachePreviewImg
                    ?   <div className="buttons">
                            <Button onClick={props.prev}>上一步</Button>

                            <Button
                                className="newChoose"
                                onClick={choosePic}
                            >重新选择</Button>

                            <Button
                                type="primary"
                                onClick={forecast}
                            >预测病虫害类型</Button>

                        </div>

                    :   <Button
                            className="prev"
                            onClick={props.prev}
                        >
                            上一步
                        </Button>
            }
            <p className="delete">你所上传的所有内容将在 24 小时后被永久删除</p>
        </div>
    )
}

export default StepFive;
