import React, { useEffect, useRef, useState } from 'react';
import { Button, message } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import SpeedOfProgress from '../speedOfProgress'
import { uploadPictures, getPicture, deleteCache } from '../../utils';
import './style.scss';
function state(take, transmission, complete) {
    this.take = take;
    this.transmission = transmission;
    this.complete = complete;
}
let time;
const Shot = () => {
    // 这个对象是上传的状态
    const [speedOfProgress, setSpeedOfProgress] = useState(new state(false, false, false));
    const [camera, setCamera] = useState(false)
    const [mediaStreamTrack, setMediaStreamTrack] = useState('');
    const [imageSrc, setImageSrc] = useState('');
    const videoRef = useRef();
    const canvasRef = useRef();
    useEffect(() => {
        // 进入后先判断有没有暂时录入的参照，如果有就直接用，没有就去获取，有就将参照放到页面
        if (sessionStorage.getItem('referencePicture')) {
            setImageSrc(sessionStorage.getItem('referencePicture'));
            setCamera(true);
            setSpeedOfProgress(new state(true, true, true));
        } else {
            setTimeout(() => {
                getPicture().then((res) => {
                    if (res.img) {
                        let img = JSON.parse(res.img);
                        setImageSrc(img.image);
                        setCamera(true);
                        sessionStorage.setItem('referencePicture', img.image);
                        setSpeedOfProgress(new state(true, true, true));
                    }
                })
            }, 500)
        }
    }, [])
    // 各种打开摄像头的方式
    const getUserMedia = (constraints, success, error) => {
        if (navigator.mediaDevices.getUserMedia) {
            //最新的标准API
            navigator.mediaDevices.getUserMedia(constraints).then(success).catch(error);
        } else if (navigator.webkitGetUserMedia) {
            //webkit核心浏览器
            navigator.webkitGetUserMedia(constraints).then(success).catch(error);
        } else if (navigator.mozGetUserMedia) {
            //firfox浏览器
            navigator.mozGetUserMedia(constraints, success, error);
        } else if (navigator.getUserMedia) {
            //旧版API
            navigator.getUserMedia(constraints, success, error);
        }
    }
    const success = (stream) => {
        // 打开完成后将拍摄的内容放到视频上播放
        const StreamTrack = typeof stream.stop === 'function' ? stream : stream.getVideoTracks()[0]; //这里需要定义一个全局变量记录这个值，方便在其他地方调用关闭摄像头
        setMediaStreamTrack(StreamTrack);
        videoRef.current.srcObject = stream;
        videoRef.current.play();
    }
    const error = (error) => {
    }
    const shot = () => {
        // 用户点击拍摄后将现在视频那一帧放到canvas上，然后获取图片信息
        var context = canvasRef.current.getContext('2d');
        canvasRef.current.width = videoRef.current.offsetWidth;
        canvasRef.current.height = videoRef.current.offsetHeight;
        // 这里是为了将 canvas 的图片镜像打印，因为视频镜像靠的是 css 翻转
        context.translate(videoRef.current.offsetWidth, 0);
        context.scale(-1, 1);
        context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height); //绘制当前画面，形成图片
        videoRef.current.pause(); //暂停摄像头视频流
        mediaStreamTrack && mediaStreamTrack.stop();    // 关闭摄像头
        var imgURL = canvasRef.current.toDataURL('image/jpeg', 60 / 100); //获取到的图片，这里的图片是base64位的
        setImageSrc(imgURL);
        setSpeedOfProgress(new state(true, false, false))
    }
    const again = () => {
        // 用户点击重新录入，如果已经有就删除
        if (sessionStorage.getItem('referencePicture')) {
            deleteCache();
            sessionStorage.removeItem('referencePicture');
            message.success('当前录入的人脸数据已清除，重新录入后方可继续实验');
        }
        clearTimeout(time);
        setSpeedOfProgress(new state(false, false, false))
        setImageSrc('');
        if (imageSrc) {
            getUserMedia({ video: { facingMode: 'user', } }, success, error);
        }
    }

    useEffect(() => {
        return function () {
            mediaStreamTrack && mediaStreamTrack.stop();
        }
    }, [mediaStreamTrack]);
    const open = () => {
        // 第一次打开摄像头
        if (!mediaStreamTrack) {
            if (navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia) {
                setCamera(true);
                getUserMedia({ video: { facingMode: 'user', } }, success, error);//facingMode: 'user' 为开启前置摄像头
            } else {
                message.info('您的设备不支持访问用户媒体');
            }
        }
    }
    const up = () => {
        // 用户点击上传后先转两秒再上传，为了可以让用户后悔然后点击重新选择（我的评价是：没有意义）
        setSpeedOfProgress(new state(true, 'ing', false));
        time = setTimeout(() => {
            uploadPictures(imageSrc).then(() => {
                sessionStorage.setItem('referencePicture', imageSrc);
                setSpeedOfProgress(new state(true, true, true));
            }).catch(() => {
                message.error('上传失败，请重试');
                setSpeedOfProgress(new state(true, false, false));
            });
        }, 2000)
    }
    return (
        <div className='shot'>
            <SpeedOfProgress
                speedOfProgress={speedOfProgress}
            />
            {
                camera ?
                    (imageSrc ?
                        <img className='preview' alt='' src={imageSrc}></img>
                        : <video id='video' crossOrigin='anonymous' width='400' autoPlay ref={videoRef}></video>)
                    : <div className='choice'></div>
            }
            {
                speedOfProgress.complete ?
                    <div className='complete'>
                        <CheckCircleFilled style={{ color: '#52c41a' }} />
                        <div>参照人脸录入完成，请前往英荔创作平台继续实验</div>
                    </div>
                    : <div className='Tips'>请允许浏览器调用摄像头（camera）。请勿遮挡面部，并保证图像清晰、光线明亮</div>
            }
            {
                (camera && !speedOfProgress.take) ?
                    <Button onClick={shot} className='TakeAnImage' type='primary'>拍摄</Button>
                    : (!speedOfProgress.take ? <Button onClick={open} className='open' type='primary'>开启摄像头</Button> : '')
            }
            {
                speedOfProgress.take && !speedOfProgress.complete ?
                    <div className='SelectionComplete'>
                        <Button onClick={again} className='again program button'>重新拍摄</Button>
                        {speedOfProgress.transmission === 'ing' ?
                            <Button onClick={up} disabled className='disabled button' type='primary'>上传</Button>
                            : <Button onClick={up} className='determine button' type='primary'>上传</Button>}
                    </div>
                    : (speedOfProgress.complete ? <Button onClick={again} className='again program button'>重新录入</Button> : '')
            }
            <canvas style={{ 'position': 'fixed', 'top': '-9999px', 'left': '-9999px' }} id='canvas' ref={canvasRef}></canvas>
        </div >

    )
}
export default Shot;