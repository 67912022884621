import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {changeNoise, changeColor, changeBrightness} from "../../../store/cropSlice";
import {Button} from "antd";

const StepThree = props => {

    const state = useSelector(state => state.crop);
    const dispatch = useDispatch();

    const transformArr = [
        {
            id: 1,
            name: '添加噪声',
            text: '噪声是图片采集过程中不可避免的。低性能的成像传感器、在低亮度的环境中拍照等，都会在图片中产生大量噪声。',
            text2: '可以添加的噪声有很多种，最常见的是高斯噪声，更复杂的还有添加彩色噪点的 Coarse Dropout 噪声。',
            isOpen: state.noise,
            setIsOpen () {
                dispatch(changeNoise());
            },
            imgs: [
                '/image/cropDiseases/noise1.png',
                '/image/cropDiseases/noise2.png',
            ],
        },
        {
            id: 2,
            name: '颜色扰动',
            text: '颜色扰动就是在图片某一个颜色空间通过增加或减少某些颜色分量，或者更改颜色通道的顺序。',
            isOpen: state.color,
            setIsOpen () {
                dispatch(changeColor());
            },
            imgs: [
                '/image/cropDiseases/color1.png',
                '/image/cropDiseases/color2.png',
                '/image/cropDiseases/color3.png',
            ]
        },
        {
            id: 3,
            name: '改变亮度、对比度',
            text: '改变亮度、对比度可以模拟不同相机在不同光照条件下拍摄的样本。',
            isOpen: state.brightness,
            setIsOpen () {
                dispatch(changeBrightness());
            },
            imgs: [
                '/image/cropDiseases/brightness1.png',
                '/image/cropDiseases/brightness2.png',
                '/image/cropDiseases/brightness3.png',
            ]
        },
    ]


    return(
        <div id="step3">
            <div className="stepTitle">颜色变换</div>
            <p className="text text1">
                几何变换类操作，没有改变图像本身的内容，它可能是选择了图像的一部分，或者对图片的像素进行了重新分布。
                <br/>
                如果要改变图像本身的内容，就属于颜色变换类的数据增强了，常见的包括噪声、模糊、颜色变换、擦除、填充等。
                <br/>
                颜色变换，可以用来模拟不同拍摄环境下的样本。
            </p>

            {transformArr.map(item =>
                <div key={item.id} className="transformBox">
                    <div className="secondTitle">{item.name}</div>
                    <p className="text text2">
                        {item.text}
                        <br/>
                        {item.text2 || ''}
                    </p>
                    <div className="transform">
                        <img src="/image/cropDiseases/default.png" alt=""/>

                        {
                            item.isOpen
                                ?
                                <>
                                    <img
                                        src="/image/arrow-black.png"
                                        className="icon"
                                        alt=""
                                    />

                                    {item.imgs.map((imgsrc, idx)=>
                                        <img
                                            key={idx}
                                            src={imgsrc}
                                            className="marginPic"
                                            alt=""
                                        />
                                    )}

                                    {item.imgs.length > 2 &&
                                        <div className="dotsBox">
                                            <div className="dot"/>
                                            <div className="dot"/>
                                            <div className="dot"/>
                                        </div>
                                    }
                                </>
                                :
                                <button
                                    className="bianhuan"
                                    onClick={item.setIsOpen}>
                                    变换
                                </button>
                        }

                    </div>
                </div>
            )}

            <div className="buttons">
                <Button onClick={props.prev}>上一步</Button>
                <Button onClick={props.next} type="primary">下一步</Button>
            </div>

        </div>
    )
}

export default StepThree;
