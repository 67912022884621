import React, {useEffect, useState, useRef, useMemo} from 'react';
import './style.scss'
import SpeedOfProgress from '../speedOfProgress'
import { Document, Page, pdfjs } from 'react-pdf';
import pdf1 from '../../../assets/speakerRecognition/article_1.pdf';
import pdf2 from '../../../assets/speakerRecognition/article_2.pdf';
import pdf3 from '../../../assets/speakerRecognition/article_3.pdf';
import pdf4 from '../../../assets/speakerRecognition/article_4.pdf';
import pdf5 from '../../../assets/speakerRecognition/article_5.pdf';
import pdf6 from '../../../assets/speakerRecognition/article_6.pdf';
import AudioPlayer from "../../../components/audioPlayer";
import {Button, Spin, Popover, Modal, notification,message} from 'antd';
import Recorder from 'recorder-core';
// 接口
import {
    uploadVoice,
    speakerTraining,
    getSpeakerTraining,
    deleteVoice
} from "../../../utils";
import {useDispatch,useSelector} from "react-redux";
// redux action creator
import {
    setRecord,
    setUpload,
    setTrain,
    setComplete,
    setRecRecord,
    setRecUploadPending,
    setRecUpload,
    setRecComplete,
    setUrl,
    setUploadFlag,
    setRecordFlag
} from '../../../store/speakerSlice'
import {
    ExclamationCircleFilled,
    CheckCircleFilled,
    CloseCircleFilled,
    RightOutlined,
    InfoCircleFilled,
} from '@ant-design/icons';
import '../wave'
const {confirm} = Modal;

const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry')
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

// console.log(pdfjs.GlobalWorkerOptions.workerSrc)
function wavFormatConversion () {
    Recorder.prototype.enc_wav = {
        stable: !0,
        testmsg: "支持位数8位、16位（填在比特率里面），采样率取值无限制"
    }
    Recorder.prototype.wav = function (t, e, n) {
        var r = this.set,
            a = t.length,
            o = r.sampleRate,
            f = 8 === r.bitRate ? 8 : 16,
            i = a * (f / 8),
            s = new ArrayBuffer(44 + i),
            c = new DataView(s), u = 0, v = function (t) {
                for (var e = 0; e < t.length; e++, u++) c.setUint8(u, t.charCodeAt(e))
            }, w = function (t) {
                // eslint-disable-next-line no-unused-expressions
                c.setUint16(u, t, !0)
                u += 2
            }, l = function (t) {
                // eslint-disable-next-line no-unused-expressions
                c.setUint32(u, t, !0)
                u += 4
            };
        if (
            // eslint-disable-next-line no-sequences
            v("RIFF"),
                l(36 + i),
                v("WAVE"),
                v("fmt "),
                l(16),
                w(1),
                w(1),
                l(o),
                l(o * (f / 8)),
                w(f / 8), w(f), v("data"),
                l(i), 8 === f) for (var p = 0; p < a; p++, u++) {
                var d = 128 + (t[p] >> 8);
                c.setInt8(u, d, !0)
            } else for (p = 0; p < a; p++, u += 2) c.setInt16(u, t[p], !0);
        e(new Blob([c.buffer], {type: "audio/wav"}))
    }
}
wavFormatConversion()

let i = 0,
    // start, // pdf 开始加载
    // end, // pdf 加载完成
    rec, // record
    wave, // 声纹
    timer,
    totalTime,
    // changePage = false,
    canRec = true,
    needOpen;
let set={
    elem:".wave" //自动显示到dom，并以此dom大小为显示大小
    //或者配置显示大小，手动把surferObj.elem显示到别的地方
    ,width:398 //显示宽度
    ,height:50 //显示高度
    //以上配置二选一
    ,scale:10 //缩放系数，应为正整数，使用2(3? no!)倍宽高进行绘制，避免移动端绘制模糊
    ,fps:60 //绘制帧率，不可过高，50-60fps运动性质动画明显会流畅舒适，实际显示帧率达不到这个值也并无太大影响
    ,duration:66400 //当前视图窗口内最大绘制的波形的持续时间，此处决定了移动速率
    ,direction:1 //波形前进方向，取值：1由左往右，-1由右往左
    ,position:0 //绘制位置，取值-1到1，-1为最底下，0为中间，1为最顶上，小数为百分比
    ,centerHeight:2 //中线基础粗细，如果为0不绘制中线，position=±1时应当设为0
    //波形颜色配置：[位置，css颜色，...] 位置: 取值0.0-1.0之间
    ,linear:[1,"#333"]
    ,centerColor:"" //中线css颜色，留空取波形第一个渐变颜色
}

const RecordPage = ({current, setCurrent, source, infoBox, messageBox}) => {

    const state = useSelector(state => state.speaker);
    const record = state.record;
    const complete = state.complete;
    const recComplete = state.recComplete;
    const url = state.url;
    const userUploadPending = state.userUploadPending;
    const train = state.train;
    const userUpload = state.userUpload;
    const userComplete = state.userComplete;
    const uploadFlag = state.uploadFlag;
    // const recordFlag = state.recordFlag;
    const dispatch = useDispatch();

    // const [expired, setExpired] = useState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        // const hasRecWave = window.localStorage.getItem('hasRecWave');
        if (!source || source !== 'record') return
        getSpeakerTraining().then(res => {
            if (!res.is_exist) {
                window.localStorage.removeItem('loadMore');
                // window.localStorage.removeItem('re_recording');
                return
            }
            // window.localStorage.setItem('re_recording', 'True');
            const more = window.localStorage.getItem('loadMore');
            more && setMore(true);
            // const hide = window.localStorage.getItem('hideUrl');
            // console.log('hideUrl',hide)
            // hide && dispatch(setUrl('0'));
            dispatch(setUrl('0'))
            dispatch(setUpload(true));
            dispatch(setRecUpload(true));
            dispatch(setTrain(true));
            dispatch(setComplete(true));
            dispatch(setRecComplete(true));
            dispatch(setRecord(true));
        })
    },[source]) // eslint-disable-line

     // 录音相关
    const waveContainer = useRef()
    useEffect(() => {
        waveContainer.current && recOpen();
    },[])

    const loadMore = () => {
        window.localStorage.setItem('loadMore','yes');
        setMore(true)
    }


    const recOpen = (success) => {
        //打开麦克风授权获得相关资源
        return new Promise((resolve,reject) => {
            rec = Recorder({
                type: "wav",
                sampleRate: 16000,
                biteRate: 16,
                onProcess:function(buffers,powerLevel,bufferDuration,bufferSampleRate){
                    wave.input(buffers[buffers.length-1],powerLevel,bufferSampleRate);//输入音频数据，更新显示波形
                }
            })
            rec.open(function(){
                wave = Recorder.WaveSurferView(set);
                success && success();
                canRec = true
                resolve(canRec)
            },function(msg,isUserNotAllow){//用户拒绝未授权或不支持
                console.log((isUserNotAllow?"UserNotAllow，":"")+"无法录音:"+msg);
                canRec = false
                resolve(canRec)
            });
        })
    }

    const [pdf, setPdf] = useState(null);
    const pdfList = useMemo(() => {
        return [pdf1, pdf2, pdf3, pdf4, pdf5, pdf6]
    },[])
    useEffect(() => {
        setPdf(pdfList[i]);
    },[pdfList])

    // 切换 pdf
    const [pdfIsLoad, setPdfIsLoad] = useState(false);
    const pdfText = useRef();
    const changeArticle = async () => {
        if (!pdfIsLoad) return
        i++;
        if (i === pdfList.length) i = 0;
        await setPdf(pdfList[i]);
        pdfText.current.scrollTo({
            top: 0,
        });
        // 防止频繁点击切换 pdf
        setPdfIsLoad(false);
    }

    // pdf 页数计算
    const [numPages, setNumPages] = useState(null);
    const computedPdfPages = num => {
        const arr = [];
        for (let i = 1;i<=num; i++) {
            arr.push(i);
        }
        return arr;
    }

    // pdf 文档加载中
    const onDocumentLoading = () => {
        // start = new Date().getTime();
        // console.log('正在加载 pdf 稍安勿躁')
    }

    // pdf 文档加载完成
    const onDocumentLoadSuccess = ({numPages}) => {
        // console.log('加载完成 pdf')
        // end = new Date().getTime();
        // console.log('pdf 请求时长', end - start,'ms');
        setPdfIsLoad(true);
        setNumPages(numPages);
    }

    const leavePage = () => {
        pauseRecord()
        confirm({
            title: '是否继续录制',
            centered: true,
            icon: <ExclamationCircleFilled />,
            okText: '确定',
            cancelText: '取消',
            getContainer:() => infoBox.current,
            content:
                <div className="dialogText">
                    录音过程中，请认真阅读文章，不要将鼠标移出当前页面，否则录音将自动暂停。
                    <br/>
                    准备好后，点击【确定】继续录制声音。
                </div>,
            onOk() {
                resumeRecord()
            },
        });
    }


    const mouseEvent = (open) => {
        if (!open) {
            document.onmouseleave = null
            return
        }
        document.onmouseleave = leavePage
    }

    /*useEffect(() => {
        if (current === 'record' && !changePage) return
        current === 'upload' || isRecPause || !isRecStart
            ? document.onmouseleave = null
            : document.onmouseleave = leavePage
    },[current]) // eslint-disable-line*/

    const [isRecStart, setIsRecStart] = useState(false);
    // 声纹录入已经完成
    const [time, setTime] = useState(0);
    // 开始录音
    const startRecord = async () => {
        await new Promise((resolve) => {
            navigator.getUserMedia  = navigator.getUserMedia ||
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia ||
                navigator.msGetUserMedia;
            navigator.getUserMedia({audio:true}, async (stream) => {
                canRec = true
                needOpen && await recOpen()
                needOpen = false
                resolve()
            }, (error) => {
                canRec = false
                needOpen = true
                resolve()
            });
        })
        if (!canRec) {
            rec.close();
            message.error('缺少麦克风权限，请开启后再录制')
            return
        }
        const isOpen = Recorder.IsOpen();
        if (!isOpen) waveContainer.current && await recOpen()
        if (!uploadFlag) return
        if (waveContainer.current) wave = Recorder.WaveSurferView(set);
        setTrainFlag(true)
        dispatch(setRecRecord(true));
        // changePage = true;
        mouseEvent(true)
        dispatch(setRecordFlag(false));
        totalTime = 100000;
        // totalTime = 2000;
        setIsRecStart(true);
        setIsRecPause(false);
        dispatch(setRecord('ing'))
        rec.start();
        setTimeout(() => {
            onTimer()
        },200) // 补偿录音可能缺失的 ms
    }

    // const [audioList, setAudioList] = useState([]);
    //获取音频列表
   /* const getSpeakerList = () => {
        navigator.mediaDevices
            .enumerateDevices()
            .then(function (devices) {
                let list = [];
                devices.forEach(function (device) {
                    if (device.kind === 'audioinput') {
                        device.value=device.deviceId
                        list.push(device);
                    }
                });
                console.log('list',list)
                // if (list.length)
                // setAudioList(list);
            })
            .catch(function (err) {
                console.log(err.name + ': ' + err.message);
            });
    };*/

    const waveMask = useRef();
    useEffect(() => {
        if(!url) {
            setTime(0)
            waveMask.current.style.width = 398 + 'px'
        }
    },[url])
    const onTimer = () => {
        // const inits = 200;
        const inits = 100;
        // const start = new Date().getTime()
        // let count = 0;
        // let offset = 0;
        // let nextTime = inits - offset; // 原本间隔时间 - 误差时间

        const doRec = () => {
            // getSpeakerList()
            if (totalTime <= 0) {
                clearTimeout(timer);
                timer = null;
                stopRecord();
                dispatch(setRecord(true))
            } else {
                totalTime -= inits;
                // const _time2 = 2000 - totalTime;
                const _time2 = 100000 - totalTime;
                const time = 100 - parseInt(totalTime/1000)
                setTime(parseInt(time))
                waveMask.current.style.width = 398 - 398 * _time2/100000 + 'px';
            }
        }
        /*timer = setTimeout(function f(){
            count ++
            doRec()
            offset = new Date().getTime() - (start + count * inits)
            nextTime = inits - offset
            // console.log('误差时间',offset,'ms')
            // console.log('下一次执行时间',nextTime,'ms')
            if (nextTime < 0) nextTime = 0
            if (!timer) return
            timer = setTimeout(f,nextTime)
        },nextTime)*/
        timer = setInterval(doRec,inits)
    }

    const [isRecPause, setIsRecPause] = useState(false);
    // 暂停录制
    const pauseRecord = (e,dontNeedPauseStatus) => {
        clearTimeout(timer);
        mouseEvent(false)
        if (!dontNeedPauseStatus) {
            setIsRecPause(true);
        }
        // console.log('已暂停录制');
        rec.pause();
    }

    /*const hasCompetence = async () => {
        return new Promise(resolve => {
            navigator.getUserMedia({audio: true}, function onSuccess(stream) {
                canResume = true;
                resolve(canResume)
                console.log('已点击允许,开启成功');
            }, function onError(error) {
                canResume = false;
                resolve(canResume)
                console.log("错误：", error);
            });
        })
    }*/

    // 继续录音
    const resumeRecord = async () => {
        /*await hasCompetence();
        if (!canResume) {
            return message.error('缺少麦克风权限，请开启后再录制')
        }*/
        mouseEvent(true)
        totalTime && onTimer();
        setIsRecPause(false);
        // console.log('已继续录制');
        rec.resume();
    }

    // const [url, setUrl] = useState('')
    const [file, setFile] = useState(null)
    // 结束录音
    const stopRecord = (dontNeedFile) => {
        rec.stop(async (blob,duration) => {
            mouseEvent(false)
            setIsRecStart(false);
            dispatch(setRecRecord(false));
            dispatch(setRecordFlag(true));
            // blob 转化为 file
            const file = new File([blob],"record.wav",{type:"audio/wav"});
            // console.log('file',file)
            setFile(file);
            const url = (window.URL || window.webkitURL).createObjectURL(blob);
            dontNeedFile || dispatch(setUrl(url));
            // dontNeedFile || window.localStorage.setItem('audioUrl',url);
            // console.log(blob, (window.URL || window.webkitURL).createObjectURL(blob), "时长:" + duration + "ms");
            // rec.close();//释放录音资源，当然可以不释放，后面可以连续调用start；但不释放时系统或浏览器会一直提示在录音，最佳操作是录完就close掉
            // rec = null;
        }, msg => {
            console.log("录音失败:"+msg);
            // rec.close();//可以通过stop方法的第3个参数来自动调用close
            // rec = null;
        })
    }

    // 提示信息浮窗
    const messageInfo = ({title, text, icon}) => {
        notification.open({
            message: title,
            description: text,
            placement: 'top',
            duration: 3,
            className: 'notification',
            getContainer: () => messageBox.current,
            icon,
        });
    }

    // const [voiceWave, setVoiceWave] = useState(false);
    // 上传
    // const [uploadFlag, setUploadFlag] = useState(true);
    const [trainFlag, setTrainFlag] = useState(true);
    const uploadAudio = async () => {
        if (!uploadFlag || !trainFlag) return
        dispatch(setUploadFlag(false));
        dispatch(setRecUploadPending(true));
        // setSpeedOfProgress(new State(true, 'ing', false))
        dispatch(setUpload('ing'))
        const formData = new FormData();
        formData.append('file',file);
        formData.append('step','1');
        formData.append('content','123456');
        formData.append('extension_id','speakerRecognition');
        // re_recording 判断条件
        // const re_recording = window.localStorage.getItem('re_recording');
        // if (re_recording) {
        //     formData.append('re_recording', 'True');
        // } else {
        //     formData.append('re_recording', 'False')
        // }

       /* const errorInfo = {
            title: '错误',
            text: '模型训练失败，请根据页面上方的要求，重新录制声音',
            icon: <CloseCircleFilled style={{color:'#ff4d4f',fontSize:'22px'}}/>
        }*/

        /*const errorInfoCreator = (text) => {
            return {
                title: '错误',
                text,
                icon: <CloseCircleFilled style={{color:'#ff4d4f',fontSize:'22px'}}/>
            }
        }*/

        const warningInfo = {
            title: '警告',
            text: '声音上传失败，请检查网络连接是否正常',
            icon: <ExclamationCircleFilled style={{color:'#FAAD14',fontSize:'22px'}}/>
        }

        // 根据请求结果提示
        // messageInfo(errorInfo)


        // 上传失败
        const uploadError = () => {
            dispatch(setRecUploadPending(false));
            dispatch(setUpload(false));
            dispatch(setUploadFlag(true));
            messageInfo(warningInfo)
        }


        // 训练失败
        const trainError = () => {
            // messageInfo(errorInfo);
            dispatch(setTrain(false));
            confirm({
                title: '模型训练失败',
                centered: true,
                icon: <CloseCircleFilled style={{color:'#ff4d4f'}}/>,
                okText: '重新训练',
                cancelText: '取消',
                getContainer:() => messageBox.current,
                content:
                    <div className="dialogText">
                        模型训练失败，可尝试重新训练。或返回首页，重新录制声音。
                    </div>,
                onOk () {
                    try2train()
                },
                onCancel () {
                    dispatch(setUploadFlag(true))
                    setTrainFlag(false)
                }
            });
        }

        // 查询声纹失败 直至获取成功
        const getTrainError = () => {
            getTrain()
        }

        let tryCount = 0;
        const getTrain = async () => {
            tryCount ++;
            if (tryCount > 5) return
            try {
                // 获取声纹
                const res_getTrain = await getSpeakerTraining(formData);
                // console.log('res_getTrain',res_getTrain)

                if(res_getTrain.is_exist) {
                    dispatch(setComplete(true));
                    dispatch(setRecComplete(true));
                    dispatch(setUploadFlag(true));
                    // window.localStorage.setItem('hasRecWave','yes');
                    // window.localStorage.setItem('re_recording','True');
                    // window.localStorage.setItem('hideUrl','yes');
                    window?.opener?.postMessage(
                        { is_exist: true },
                        '*'
                    );
                } else {
                    getTrain()
                }
            } catch (e) {
                if (tryCount > 5) return
                getTrainError()
            }
        }


        // 开始训练
        const try2train = async () => {
            dispatch(setTrain('ing'));
            try {
                const res_train = await speakerTraining(formData);
                if (JSON.stringify(res_train.result) !== '{}' && !res_train.result.errCode) {
                    dispatch(setTrain(true));
                    dispatch(setComplete('ing'));

                    getTrain()

                } else {
                    trainError()
                }

                // console.log('res_train',res_train)
                // 训练错误的捕获
            } catch (error) {
                trainError()
                console.log('train error',error)
            }
        }


        // 真正执行上传
        try {
            // 上传
            const res_upload = await uploadVoice(formData);
            // console.log('res_upload',res_upload)
            if (JSON.stringify(res_upload.result) !== '{}' && !res_upload.result.errCode) {
                dispatch(setRecUploadPending(false));
                dispatch(setUpload(true));
                dispatch(setTrain('ing'));
                dispatch(setRecUpload(true));
                // 训练
                formData.append('source','record');
                await try2train()
            } else {
                uploadError()
            }
        } catch (error) {
            uploadError()
            console.log('upload error',error)
        }
    }

    // 打开确认框 再确定 关闭录音
    const reRecordContainer1 = useRef();
    const reRecordContainer2 = useRef();
    const reStart = async (complete) => {
        if (complete) {
            try {
                const res = await deleteVoice();
                // console.log('res',res)
                if(!res?.success) return
                window?.opener?.postMessage(
                    { is_exist: false },
                    '*'
                );
                // window.localStorage.removeItem('hasRecWave');
            } catch (e) {
                console.log('删除声纹失败')
                return
            }
        }
        // window.localStorage.removeItem('hideUrl');
        // window.localStorage.removeItem('audioUrl');
        window.localStorage.removeItem('loadMore');
        setFile(null);
        setMore(false);
        waveMask.current.style.width = 398 + 'px';
        isRecPause && setIsRecPause(false);
        dispatch(setUrl(''));
        dispatch(setRecord(false));
        dispatch(setUpload(false));
        dispatch(setTrain(false));
        dispatch(setComplete(false));
        dispatch(setRecUpload(false));
        // dispatch(setUserUpload(false));
        dispatch(setRecComplete(false));
        setTime(0);
    }
    const reStartRecord = (complete) => {
        !complete && pauseRecord()
        if (!uploadFlag) return
        confirm({
            title: complete ? '是否确定要重新录入' : '是否确定要重新录制',
            centered: true,
            icon: <ExclamationCircleFilled />,
            okText: '确定',
            cancelText: '取消',
            getContainer: !complete
                ? () => reRecordContainer1.current
                : () => reRecordContainer2.current
            ,
            content:
                !complete
                    ? <div className="dialogText">
                        如果觉得录制过程中有过多杂音、语音断断续续或其他问题，可以重新录制。
                        <br/>
                        开始重新录制，你之前录制的内容会被删除。
                      </div>
                    : <div className="dialogText">
                        点击【确定】，当前已经训练完成的声纹模型会被立即删除，然后你可以开始重新采集语音数据。</div>
            ,
            onOk() {
                if (isRecStart) {
                    pauseRecord(1,true);
                    stopRecord(true);
                } else {
                    setIsRecStart(false);
                }
                setTrainFlag(true)
                dispatch(setUploadFlag(true))
                reStart(complete)
            },
            onCancel() {
                if (url) return
                !complete && resumeRecord()
            }
        });
    }

    const download = () => {
        const a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', '英荔 AI 实验平台录音');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    // 加载更多
    const [more, setMore] = useState(false);
    const pdfBox = useRef();

    /** AudioPlayer Style **/
    const style = {
        width:558,
        height:64,
        border:'none',
        position:'absolute'
    }

    useEffect(() => {
        setIsRecStart(false);
        waveMask.current.style.width = 398 + 'px';
        /** 录音未结束切换分页 **/
        return () => {
            if (timer) {
                // changePage = false;
                mouseEvent(false);
                dispatch(setRecordFlag(true));

                clearTimeout(timer);
                timer = null;
                pauseRecord();

                setFile(null);
                setMore(false);
                // waveMask.current.style.width = 398 + 'px';
                isRecPause && setIsRecPause(false);
                dispatch(setUrl(''));
                dispatch(setRecord(false));
                dispatch(setUpload(false));
                dispatch(setTrain(false));
                dispatch(setComplete(false));
                dispatch(setRecUpload(false));
                // dispatch(setUserUpload(false));
                dispatch(setRecComplete(false));
                setTime(0);
            }
            rec.close();
            rec = null;
        }
    }, [])// eslint-disable-line

    const computedName = () => {
        return recComplete === true ? "reStart_recordEnd fix_reStart" : "reStart_recordEnd"
    }

    return(
        <div id="record" style={current ==='record' ? {} : {display:'none'}} >
            {
                !record &&
                !userUploadPending &&
                !userUpload &&
                <div className="text">
                    <span className="boldText">说话人识别，又称「声纹识别」，通常应用于辅助身份验证。</span>开始识别前，需要先采集一段清晰的语音，机器通过特定的算法提取这段语音中的发音特征并保存下来。然后当新的语音输入时，机器用同样的方法提取新语音中的特征，与数据库中已有的特征进行对比分析，从而确定身份。
                </div>
            }
            <SpeedOfProgress />
            {
                !record &&
                !userUploadPending &&
                !userUpload &&
                <div className="text">
                    请在
                    <span className="boldText">较为安静的环境中，用正常语速，中文普通话发音念 100 秒。</span>
                    你可以随意选择阅读材料，只要保证可以比较流利地阅读即可。若在阅读过程中发现自己念错了字，不需要修正，继续阅读后面的内容即可。若觉得难以一次性读完，可以中途暂停休息，尽量避免阅读过程中有较长时间（超过 10 秒）的停顿。
                    <br/>
                    当前实验环境预备了多篇文章供你阅读，均节选自《统编版语文教材》，若你觉得下方的文章不合适，可以
                    <span className="changeArticle" onClick={changeArticle}>换一篇文章</span>。
                </div>
            }
            <div id="pdfBox" ref={pdfBox} style={userUpload || userUploadPending ? {display:'none'} : {}}>
                <div className='pdfTextBox'
                     style={complete === true ? {display:'none'} : {}}
                >
                    <div className='pdfText' ref={pdfText}>
                        <Spin className={pdfIsLoad ? "spinHidden" : ""}>
                            <div className="contentPdfText">
                                <Document
                                    option={{
                                        cMapUrl:"“https://xxx.cmaps/", // 预定义的 Adob​​e CMap 所在的 URL。包括尾部斜杠。
                                        cMapPacked: true, // 指定 Adob​​、e CMap 是否为二进制打包。
                                    }}
                                    renderMode="canvas"
                                    file={pdf}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    loading={onDocumentLoading}
                                >
                                    {computedPdfPages(numPages).map((item, index)=>
                                        <Page
                                            key={index}
                                            pageNumber={item}
                                        />
                                    )}
                                </Document>
                            </div>
                        </Spin>
                    </div>

                    <div className='waveBox'>
                        {
                            url && <AudioPlayer
                                url={url}
                                style={style}
                                times={100}
                                needPause={complete === true}
                            />
                        }
                        {/* 用于实时显示声波进度 */}
                        <div className="waveMask" ref={waveMask}>
                            <div className="progressLine"></div>
                        </div>
                        <div className="wave" ref={waveContainer}></div>
                        <span>{time} / 100</span>
                    </div>
                </div>
                {
                    recComplete !== true
                        ? <>
                            <Popover
                                placement="top"
                                trigger="hover"
                                content={(
                                    <div className="popover">
                                        <p className="audioPermissionText">
                                            1. 请允许浏览器调用麦克风，否则无法录音
                                        </p>
                                        <img className="audioPermission" src="/image/speaker/audioPermission1.png" alt=""/>
                                        <p className="audioPermissionText">
                                            2. 如果录音总是没有声音（录音时波形图没有显示），请检查浏览器是否开启了麦克风。
                                        </p>
                                        <img className="audioPermission" src="/image/speaker/audioPermission2.png" alt=""/>
                                    </div>
                                )}
                                getPopupContainer={() => pdfBox.current}
                                forceRender={true} // 解决开局闪动
                            >
                                <i className="icon">
                                    <ExclamationCircleFilled className="warn"/>
                                </i>
                            </Popover>
                            <span className="tips">请允许浏览器调用麦克风（microphone），否则无法录制。请匀速、连续地朗读文字。</span>
                          </>
                        : <>
                            {
                                url !== '0'
                                    ? <AudioPlayer url={url} times={100}/>
                                    : <div className="expired">为了最大限度的保障隐私，你的原始声音文件已被删除。你的声纹模型也会在 72 小时内被清除。</div>
                            }
                            <div className="waveSuccessBox">
                                <i className="icon"><CheckCircleFilled className="success" /></i>
                                <span className="tips tips2">声纹录入完成，请前往英荔创作平台继续实验</span>
                            </div>
                          </>
                }

            </div>
            <div className="reRecordContainer">
                <div ref={reRecordContainer1} className="container1"></div>
                <div ref={reRecordContainer2} className="container2"></div>
            </div>
            {
                isRecStart
                ? <div className="buttons_recording">
                    {isRecPause
                        ? <Button onClick={resumeRecord}>继续录制</Button>
                        : <Button onClick={(e) => pauseRecord(e)}>暂停录制</Button>
                    }
                    <Button
                        className="reStart"
                        onClick={
                        recComplete
                            ? reStartRecord
                            : (() => reStartRecord((complete)))
                    }>重新录制</Button>
                  </div>
                : url && !userUpload && !userUploadPending
                    ? <div className="buttons_recordEnd">
                        {
                            trainFlag
                                ?
                                    <span
                                        className={ uploadFlag ? computedName() : "disabled" }
                                        onClick={
                                            recComplete
                                                ? reStartRecord
                                                : (() => reStartRecord((complete)))}
                                    >
                                        { recComplete === true ? '重新录入' : '重新录制' }
                                    </span>
                                :
                                <span
                                    className={computedName()}
                                    onClick={reStart}
                                >返回首页</span>
                        }
                        {
                            recComplete === true
                            ||
                            <span
                                className={ trainFlag && uploadFlag ? "upload" : "disabled" }
                                onClick={uploadAudio}
                            >上传</span>
                        }
                      </div>
                    : !userUpload
                        ?
                        !userUploadPending
                            ?
                            <Button
                                className="centerButton"
                                type="primary"
                                onClick={startRecord}
                            >开始录制</Button>
                            :
                            <div className="pendingStatus">
                                <i className="icon">
                                    <InfoCircleFilled className="pending" />
                                </i>
                                音频文件正在上传中，前往「
                                <span className="changeTab" onClick={() => setCurrent('upload')}>上传音频</span>
                                」分页查看详情
                            </div>
                        :
                        <div className="afterUpload">
                            {
                                !userComplete
                                    ?
                                    train
                                        ?
                                        <>
                                            <i className="icon">
                                                <InfoCircleFilled className="pending" />
                                            </i>
                                            音频文件已经上传，模型正在训练中，前往「
                                            <span className="changeTab" onClick={() => setCurrent('upload')}>上传音频</span>
                                            」分页查看详情
                                        </>
                                        : <>
                                            <i className="icon">
                                                <CloseCircleFilled style={{color:'#ff4d4f'}}/>
                                            </i>
                                            训练失败，前往「
                                            <span className="changeTab" onClick={() => setCurrent('upload')}>上传音频</span>
                                            」分页查看详情
                                          </>
                                    :
                                    <>
                                        <i className="icon">
                                            <CheckCircleFilled className="success" />
                                        </i>
                                        声纹录入完成，请前往英荔创作平台继续实验，或前往「
                                        <span className="changeTab" onClick={() => setCurrent('upload')}>上传音频</span>
                                        」分页查看详情
                                    </>
                            }
                        </div>
            }
            {
                recComplete === true && !userComplete &&
                <div className="hasWaveBox">
                    {
                        url !== '0' &&
                        <div className="hasWaveText">
                            为了最大限度的保障隐私，我们会在声纹模型训练完成后立即删除你的原始声音文件，现在你可以
                            <span className="downloadText" onClick={download}>下载录音文件</span>
                            。你的声纹模型也会在 72 小时内被清除。
                        </div>
                    }
                    <div className="hasWaveText">
                        说话人识别，通常应用于辅助身份验证，主要原因是其便利性很高但可靠程度稍弱。
                        <br/>
                        当前实验环境仅用于体验说话人识别技术中的 1:1 辨认，即判断「现在说话的是不是你」。
                        说话人识别还有一种主要的应用场景是 1:N 辨认，即区分出「现在说话的是谁」，原理是类似的。
                        <br/>
                        {
                            more ||
                            <span className="loadMore" onClick={loadMore}>
                                了解更多 <RightOutlined/>
                            </span>
                        }
                    </div>
                    {
                        more &&
                        <>
                            <div className="hasWaveText">
                                <div className="boldText">
                                    影响说话人识别可靠度的主要因素：
                                </div>
                                1、的确可能存在两个声纹特征高度相似的人。如果说话人识别的声纹库足够大，就很容易遇到这种情况；
                                <br/>
                                2、当前主流的模型只能对标准的中文普通话进行训练。若录入的声音并非标准的中文普通话，可能会对模型的准确率产生影响；
                                <br/>
                                3、声音很容易受到干扰。辨认说话人时的语音语调若和录入的声音样本有较大差异，或是环境音过于嘈杂，也可能导致辨认错误。
                                <br/>
                                聪明的你，在闭上眼睛后也许可以辨认出身边的几个人，但是不是也很难辨认出全班同学呢？
                            </div>
                            <div className="hasWaveText">
                                <div className="boldText">
                                    说话人识别的过程由于不需要使用者额外进行操作，在某些场景下有难以代替的优势。
                                </div>
                                比如在我们
                                <span className="boldText">使用智能语音助手</span>
                                （如：小爱同学）的过程中，希望自己的声音只会唤醒自己的语音助手，不会出现一个家庭中多个语音助手指令混乱的情况。在这种场景里，输入密码、指纹识别、人脸识别都非常麻烦，而说话人识别就可以很好地完成任务。
                                <br/>
                                厦门天聪智能软件有限公司研发了一套
                                <span className="boldText">社保声纹识别身份认证系统</span>
                                。不熟悉电脑或智能手机操作的老年人，可以通过电话轻松完成业务。有效解决养老人员远程身份认证的难题，便民的同时，防止冒领、盗领养老金现象的发生。
                            </div>
                        </>
                    }
                </div>
            }

        </div>
    )
}

export default RecordPage;
