import { Spin } from 'antd';
import './style.scss';

const SpeedOfProgress = (speedOfProgress) => {
    const step = speedOfProgress.speedOfProgress;
    // 录入的完成状态
    return (
        <div className='speedOfProgress'>
            <div className='step'>
                <img className='img' src={step.take ? '/image/icon1-done.png' : '/image/icon1-normal.png'} alt=''></img>
                <div>人脸数据采集</div>
            </div>
            <img className='arrow' alt='' src={step.transmission ? '/image/arrow-black.png' : '/image/arrow-grey.png'}></img>
            <div className='step'>
                {step.transmission === 'ing' ? <div className='spin'><Spin /></div> : <img className='img' src={step.transmission ? '/image/icon2-done.png' : '/image/icon2-normal.png'} alt=''></img>}
                <div>传输数据</div>
            </div>
            <img className='arrow' alt='' src={step.complete ? '/image/arrow-black.png' : '/image/arrow-grey.png'}></img>
            <div className='step'>
                <img className='img' src={step.complete ? '/image/icon3-done.png' : '/image/icon3-normal.png'} alt=''></img>
                <div>完成数据录入</div>
            </div>
            <img className='arrow' alt='' src='/image/arrow-grey.png'></img>
            <div className='step'>
                <img className='img' src='/image/icon4-normal.png' alt=''></img>
                <div>审核与对比</div>
            </div>
        </div >
    )
}
export default SpeedOfProgress;