import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CloseCircleFilled } from '@ant-design/icons';
import {
  Button,
  message,
  Modal,
  Spin,
  Upload,
  Form,
  Checkbox,
  Row,
  Col,
  notification
} from 'antd';

import { currentRulesID } from '../../store/createSlice';
import './style.scss';
import { getBase64 } from '../../utils/utils';
import { pattern_currentRules, picture_detection } from '../../utils/index';

const PatternRecognitionExpertSystem = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [imageSrc, setImageSrc] = useState('');
  // const [preImg, setPreImg] = useState('');
  const [AIresult, setAIresult] = useState(null);
  const [AIImg, setAIImg] = useState('');
  const [modalCurrentRules, setModalCurrentRules] = useState(false);
  const [modalEditRules, setModalEditRules] = useState(false);
  const [base64Img, setBase64Img] = useState(null);
  // const [rules, setRules] = useState(['a']);
  const rules = useSelector(state => state.counter.currentRulesID);
  const [currentRules, setCurrentRules] = useState([]);
  const [loading, setLoading] = useState(false);

  const beforeUpload = file => {
    // setPreImg(imageSrc);
    getBase64(file).then(res => setBase64Img(res));
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png' ||
      file.type === 'image/bmp';

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M || !isJpgOrPng) {
      notification.info({
        message: '错误',
        placement: 'top',
        // top: 'calc(50% - 52px)',
        description: '经检测，你上传的图片格式不支持或过大，请选择另一张图片',
        icon: <CloseCircleFilled style={{ color: '#ff4d4f' }} />
      });

      return false;
    }
    setAIresult(null);
    setAIImg('');
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      setImageSrc(e.currentTarget.result);
    };

    return Promise.reject();
  };
  const props = {
    name: 'file',

    showUploadList: false,
    accept: 'image/jpg,image/png,image/jpeg,image/bmp',
    beforeUpload,
    onChange(info) {
      // 上传中、完成、失败都会调用onChange 函数

      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };
  const handleDetection = async () => {
    const params = {
      rule_type_id: rules.join(),
      image: base64Img.replace(/^data:image\/\w+;base64,/, '')
    };
    setAIresult(null);
    setAIImg('');
    setLoading(true);
    // setPreImg(imageSrc);
    try {
      const result = await picture_detection(params);
      setAIresult(result.data?.data_result);
      setAIImg(result.data?.output_img);
      if (!result.data?.data_result.length) {
        notification.info({
          message: '错误',
          placement: 'top',
          // top: 'calc(50% - 52px)',
          description:
            '当前图片中没有识别到清晰的图形，请更换图片再试。建议使用《测试数据集》中的图片。',
          icon: <CloseCircleFilled style={{ color: '#ff4d4f' }} />
        });
      }
    } catch (error) {
      if (error.data.code === 'ERROR') {
        notification.info({
          message: '错误',
          // top: 'calc(50% - 52px)',
          placement: 'top',
          description: error.data.message,
          icon: <CloseCircleFilled style={{ color: '#ff4d4f' }} />
        });
        return;
      }

      notification.info({
        message: '错误',
        placement: 'top',
        // top: 'calc(50% - 52px)',
        description:
          '你的网络存在问题或缺少该功能的使用权限。请稍后再试，或联系实验室管理员',
        icon: <CloseCircleFilled style={{ color: '#ff4d4f' }} />
      });
    } finally {
      setLoading(false);
    }
  };
  const handleDownload = () => {
    const a = document.createElement('a');
    a.href =
      'https://e-course.oss-cn-shenzhen.aliyuncs.com/%E6%8F%92%E4%BB%B6%E8%B5%84%E6%96%99%E7%AE%A1%E7%90%86/%E5%9B%BE%E5%BD%A2%E8%AF%86%E5%88%AB%E4%B8%93%E5%AE%B6%E7%B3%BB%E7%BB%9F/%E5%9B%BE%E5%BD%A2%E8%AF%86%E5%88%AB%E6%B5%8B%E8%AF%95%E6%95%B0%E6%8D%AE%E9%9B%86.zip';
    a.setAttribute('download', '');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const handleCurrentRules = () => {
    let params = rules.join();
    pattern_currentRules(params).then(res => setCurrentRules(res));
    setModalCurrentRules(true);
  };
  const handleEditRules = () => {
    setModalEditRules(true);
  };
  const handleOk = () => {
    setModalCurrentRules(false);
  };
  const handleOkEdit = () => {
    const rulesArr = form.getFieldsValue(true)?.rules.sort();
    dispatch(currentRulesID(rulesArr));
    sessionStorage.setItem('rules', rulesArr);
    setModalEditRules(false);
  };
  const handleClose = () => {
    setModalCurrentRules(false);
    setModalEditRules(false);
  };
  const handleCloseEdit = () => {
    setModalEditRules(false);
    form.setFieldsValue({ rules });
  };
  return (
    <div className="patternRecognition">
      <div className="name">图形识别专家系统</div>
      <div className="content">
        <p className="describe">
        专家系统是一个智能计算机程序系统，其内部含有大量的某个领域专家水平的知识与经验，它能够应用人工智能技术和计算机技术，根据系统中的知识与经验，进行推理和判断，模拟人类专家的决策过程，以便解决那些需要人类专家处理的复杂问题。简而言之，专家系统是一种模拟人类专家解决某领域内问题的计算机程序系统。
        </p>
        <div className="describe">实验：</div>
        <div className="linkBtn" onClick={handleDownload}>
          1. 下载测试数据集
        </div>
          <div className="linkBtn" onClick={handleCurrentRules}>
            2. 查看当前规则
          </div>
        
          
        {/* <div>
          <span className="linkBtn" onClick={handleCurrentRules}>
            2. 查看当前规则
          </span>
          <Divider type="vertical" style={{ borderLeft: '1px solid #bbb' }} />
          <span className="linkBtn" onClick={handleEditRules}>
            编辑规则
          </span>
        </div> */}
        <div className="describe">3. 上传图形图片，体验专家系统</div>
        <div className="describe">
        4.{' '} 
          <span className="linkBtn" onClick={handleEditRules}>
            编辑规则
          </span>
          ，重复实验
          </div>
        {!imageSrc ? (
          <div className="choice">
            <Upload {...props}>
              <Button type="primary">浏览本地文件</Button>
            </Upload>
          </div>
        ) : (
          <Spin spinning={loading}>
            <div className="preview">
              <img src={imageSrc} alt="" className="previewImg" />
            </div>
          </Spin>
        )}

        <div className="uploadTips">
          请上传测试集中的图形图片，请勿上传包含敏感信息的图片。支持
          PNG、JPG、JPEG、BMP，图片大小不超过 2M
        </div>
        {imageSrc ? (
          <div className="btnBox">
            <Upload {...props}>
              <Button type="primary" ghost>
                重新选择
              </Button>
            </Upload>
            {/*  图片已替换也显示识别按钮 */}
            {/* {(!AIresult || preImg !== imageSrc) && ( */}
            {/* {!AIresult && (
              <Button
                type="primary"
                style={{ marginLeft: '16px' }}
                onClick={handleDetection}
              >
                识别图形
              </Button>
            )} */}
            <Button
              type="primary"
              style={{ marginLeft: '16px' }}
              onClick={handleDetection}
            >
              识别图形
            </Button>
          </div>
        ) : null}
        {AIresult?.length ? (
          <>
            <div className="describe" style={{ marginTop: '40px' }}>
              轮廓检测：此处使用了一个专门进行轮廓检测的 AI
              模型。一个成熟的人工智能应用往往需要使用多个处理具体问题的 AI
              模型。模型会自动过滤掉超出当前实验环境规则的形状组合。
            </div>

            <div className="AIresult">
              <img src={AIImg} alt="AI模型" />
            </div>

            {!!AIresult.length &&
              AIresult?.map((item, idx) => {
                return (
                  <div className="describe resultDescribe" key={idx}>
                    {AIresult.length > 1 ? (
                      <>
                        形状 {idx + 1}
                        <br />{' '}
                      </>
                    ) : null}
                    {'对应的事实：' + item.antecedent}
                    <br />
                    {item.rules_msg?.map((item, idx) => (
                      <div key={idx}>符合的规则：{item}</div>
                    ))}
                    结论：{item.description}
                  </div>
                );
              })}
          </>
        ) : null}
        {/* <div className="fileTips">
          你所上传的所有内容将在 24 小时后被永久删除
        </div> */}
      </div>

      <Modal
        title="当前规则"
        visible={modalCurrentRules}
        onOk={handleOk}
        onCancel={handleClose}
        maskClosable={false}
        destroyOnClose={true}
        width={420}
        okText={'确定'}
        centered={true}
        cancelButtonProps={{ style: { display: 'none' } }}
        wrapClassName="currentRules_modal"
      >
        <div className="modalContent">
          <div className="rulesCont">
            {currentRules?.map((i, idx) => {
              let str = '{\n';
              for (const [key, value] of Object.entries(i)) {
                const val = Array.isArray(value)
                  ? `${value.join('，')}`
                  : value;
                // const val = Array.isArray(value) ? `【${value}】` : value;
                str += `${key} : ${val}\n`;
              }
              str += '}\n';
              return str;
            })}
          </div>
        </div>
      </Modal>

      <Modal
        title="编辑规则"
        visible={modalEditRules}
        onOk={handleOkEdit}
        onCancel={handleCloseEdit}
        maskClosable={false}
        destroyOnClose={true}
        width={488}
        okText={'确定'}
        centered={true}
        cancelButtonProps={{ style: { display: 'none' } }}
        wrapClassName="editRules_modal"
      >
        <div className="rulesCont">
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{ rules: rules }}
          >
            <Form.Item name="rules" label="选择启用的规则库：">
              <Checkbox.Group>
                <Row>
                  <Col span={24}>
                    <Checkbox value="a" style={{ lineHeight: '32px' }} disabled>
                      基础规则（区分是几边形）
                    </Checkbox>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Checkbox value="b" style={{ lineHeight: '32px' }}>
                      三角形加强版规则（区分直角三角形、等腰三角形等）
                    </Checkbox>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Checkbox value="c" style={{ lineHeight: '32px' }}>
                      三角形完整版规则（加强版的基础上，支持区分等腰直角三角形等）
                    </Checkbox>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Checkbox value="d" style={{ lineHeight: '32px' }}>
                      四角形加强版规则（区分矩形、菱形等）
                    </Checkbox>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Checkbox value="e" style={{ lineHeight: '32px' }}>
                      四角形完整版规则（加强版的基础上，支持区分等腰梯形等）
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};
export default PatternRecognitionExpertSystem;
