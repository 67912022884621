import React, {useState, useEffect, useRef} from 'react';
import SpeedOfProgress from "../speedOfProgress";
import './style.scss'
import AudioPlayer from '../../../components/audioPlayer'
import {Button, notification, Modal} from 'antd';
import {
    CheckCircleFilled,
    CloseCircleFilled,
    ExclamationCircleFilled,
    RightOutlined,
    InfoCircleFilled
} from '@ant-design/icons'
import {
    uploadVoice,
    speakerTraining,
    getSpeakerTraining,
    deleteVoice
} from "../../../utils";
import {useDispatch,useSelector} from "react-redux";
import {
    setComplete,
    setRecComplete,
    setRecord,
    setRecUpload,
    setTrain,
    setUpload,
    setUrl,
    setUserComplete,
    setUserUploadPending,
    setUserUpload,
    setUserRecord,
    setUploadFlag
} from "../../../store/speakerSlice";
const { confirm } = Modal;

const UploadPage = ({current, setCurrent, source, messageBox}) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.speaker);
    const recRecord = state.recRecord;
    const recUploadPending = state.recUploadPending;
    const recUpload = state.recUpload;
    const recComplete = state.recComplete;
    const upload = state.upload;
    const train = state.train;
    const complete = state.complete;
    const userComplete = state.userComplete;
    const uploadFlag = state.uploadFlag;
    const recordFlag = state.recordFlag;

    // const [expired, setExpired] = useState(false);
    useEffect(() => {
        if (!source || source !== 'upload') return
        getSpeakerTraining().then(res => {
            // const url = window.localStorage.getItem('audioUrl');
            if (!res.is_exist) {
                window.localStorage.removeItem('loadMore');
                // window.localStorage.removeItem('re_recording');
                return
            }
            const name = window.localStorage.getItem('audioName');
            const more = window.localStorage.getItem('loadMore');
            setName(name);
            more && setMore(true);
            // const hide = window.localStorage.getItem('hideUrl');
            // hide && setAudioUrl('0');
            setAudioUrl('0');
            dispatch(setUserRecord(true));
            dispatch(setUpload(true));
            dispatch(setUserUpload(true));
            dispatch(setTrain(true));
            dispatch(setComplete(true));
            dispatch(setUserComplete(true));
            // setExpired(true);
        })
    },[source]) // eslint-disable-line

    const loadMore = () => {
        window.localStorage.setItem('loadMore','yes');
        setMore(true)
    }


    // 提示信息浮窗
    const messageInfo = ({title, text, icon}) => {
        notification.open({
            message: title,
            description: text,
            placement: 'top',
            duration: 3,
            className: 'notification',
            getContainer: () => messageBox.current,
            icon,
        });
    }

    const audioTypeError = {
        title: '错误',
        text: '请选择小于 5M，时长 80～120 秒的 wav 或 mp3 格式的文件',
        icon: <CloseCircleFilled style={{color:'#ff4d4f',fontSize:'22px'}} />
    }

    const reader = new FileReader();
    const handleEvent = (event) => {
        // setSuccess(true);
        if (event.type === 'load') {
            /*let blob = base64ToBlob(reader.result);
            let url = URL.createObjectURL(blob)
            console.log('blob',blob)*/
            // console.log('url',url)
            // setUrl(url);
        }
    }

    const [name,setName] = useState('');
    const [audioUrl, setAudioUrl] = useState('');
    const [file,setFile] = useState(null);
    /** 浏览本地文件 **/
    const browse = () => {
        if (!uploadFlag || !recordFlag) return
        const fileInput = document.createElement('input');
        fileInput.setAttribute('type','file');
        fileInput.setAttribute('style','display:none');
        // 限制音频类型
        fileInput.setAttribute('accept', '.wav,.mp3');
        document.body.appendChild(fileInput);
        fileInput.click();
        const handleSelected = async () => {
            const file = fileInput.files[0];
            // const type = file.name.slice(file.name.lastIndexOf('.')+1).toLowerCase();
            const type = file.type.slice(file.type.lastIndexOf('/')+1);
            const name = file.name;
            const size = file.size;
            const url = URL.createObjectURL(file);
            /*console.log('type',type)
            console.log('file',file)
            console.log('url',url)
            console.log('size',size)*/

            // mp3 对应 mime 类型为 audio/mpeg 故作此区分
            const types = ['wav','mpeg'];
            // console.log('has type',types.indexOf(type) === -1)
            if (types.indexOf(type) === -1 || size > 5 * 1024 *1024) return messageInfo(audioTypeError)
            const audioElement = new Audio(url);
            let duration;
            audioElement.addEventListener("loadedmetadata", async (_event) => {
                duration = audioElement.duration * 1000;
                // console.log('音频时长',duration,'ms');
                // 80 ~ 120s
                if (duration < 80000 || duration > 120000) {
                    return messageInfo(audioTypeError);
                }
                // 音频格式没问题
                setAudioUrl(url)
                setName(name)
                setFile(file)
                // window.localStorage.setItem('audioUrl',url);
                window.localStorage.setItem('audioName',name);
                dispatch(setUserRecord(true))
            });
            reader.addEventListener(
                'load',
                (e) => handleEvent(e)
            );
            reader.readAsDataURL(file);
        }
        fileInput.addEventListener('change', handleSelected);
        document.body.removeChild(fileInput);
    }

    const reselect = () => {
        if (!uploadFlag) return
        browse()
    }

    const reStart = async () => {
        if (complete) {
            try {
                const res = await deleteVoice();
                // console.log('res',res)
                if(!res?.success) return
                window?.opener?.postMessage(
                    { is_exist: false },
                    '*'
                );
                // window.localStorage.removeItem('hasUserWave');
            } catch (e) {
                console.log('删除声纹失败')
                return
            }
        }
        window.localStorage.removeItem('hideUrl');
        window.localStorage.removeItem('audioName');
        // window.localStorage.removeItem('audioUrl');
        window.localStorage.removeItem('loadMore');
        setMore(false);
        setTrainFlag(true)
        setAudioUrl('');
        dispatch(setRecord(false));
        dispatch(setUpload(false));
        dispatch(setTrain(false));
        dispatch(setComplete(false));
        dispatch(setRecUpload(false));
        dispatch(setRecComplete(false));
        dispatch(setUserRecord(false));
        dispatch(setUserUpload(false));
        dispatch(setUserComplete(false));
        dispatch(setUrl(''));
    }

    // const [uploadFlag, setUploadFlag] = useState(true);
    const [trainFlag, setTrainFlag] = useState(true);
    const uploadAudio = async () => {
        if (!uploadFlag || !recordFlag || !trainFlag) return
        dispatch(setUploadFlag(false));
        dispatch(setUserUploadPending(true));
        dispatch(setUpload('ing'));
        const formData = new FormData();
        formData.append('file',file);
        formData.append('step','1');
        formData.append('content','123456');
        formData.append('extension_id','speakerRecognition');
        // const re_recording = window.localStorage.getItem('re_recording');
        // if (re_recording) {
        //     formData.append('re_recording', 'True');
        // } else {
        //     formData.append('re_recording', 'False')
        // }

        /*const errorInfo = {
            title: '错误',
            text: '模型训练失败，请根据页面上方的要求，重新选择音频文件',
            icon: <CloseCircleFilled style={{color:'#ff4d4f',fontSize:'22px'}}/>
        }*/

        /*const errorInfoCreator = (text) => {
            return {
                title: '错误',
                text,
                icon: <CloseCircleFilled style={{color:'#ff4d4f',fontSize:'22px'}}/>
            }
        }*/

        const warningInfo = {
            title: '警告',
            text: '声音上传失败，请检查网络连接是否正常',
            icon: <ExclamationCircleFilled style={{color:'#FAAD14',fontSize:'22px'}}/>
        }

        // 训练失败
        const trainError = () => {
            // messageInfo(errorInfo);
            // dispatch(setUploadFlag(true))

            // dispatch(setRecord(false))
            // dispatch(setUserRecord(false));
            // dispatch(setUserUpload(false)); // 是否显示训练失败
            // dispatch(setUpload(false))

            // setAudioUrl('');
            dispatch(setTrain(false))

            confirm({
                title: '模型训练失败',
                centered: true,
                icon: <CloseCircleFilled style={{color:'#ff4d4f'}}/>,
                okText: '重新训练',
                cancelText: '取消',
                getContainer:() => messageBox.current,
                content:
                    <div className="dialogText">
                        模型训练失败，可根据页面上方的要求，重新录制声音，或重新训练
                    </div>,
                onOk () {
                    try2train()
                },
                onCancel () {
                    dispatch(setUploadFlag(true))
                    setTrainFlag(false)
                }
            });
        }

        // 查询声纹失败 直至获取成功
        const getTrainError = () => {
            getTrain()
        }

        // 获取声纹
        let tryCount = 0;
        const getTrain = async () => {
            tryCount ++;
            if (tryCount > 5) return
            try {
                // 获取声纹
                const res_getTrain = await getSpeakerTraining(formData);
                // console.log('res_getTrain',res_getTrain)

                if (res_getTrain.is_exist) {
                    dispatch(setComplete(true));
                    dispatch(setUserComplete(true));
                    dispatch(setUploadFlag(true));
                    // window.localStorage.setItem('hasUserWave','yes');
                    // window.localStorage.setItem('re_recording','True');
                    window.localStorage.setItem('hideUrl','yes');
                    // 训练成功 声纹存在 传给 vm
                    window?.opener?.postMessage(
                        { is_exist: true },
                        '*'
                    );
                } else {
                    getTrain()
                }
            } catch (e) {
                if (tryCount > 5) return
                getTrainError()
            }
        }

        // 训练
        const try2train = async () => {
            dispatch(setTrain('ing'))
            try {
                const res_train = await speakerTraining(formData)
                // console.log('res_train',res_train)

                if (JSON.stringify(res_train.result) !== '{}' && !res_train.result.errCode) {
                    dispatch(setTrain(true));
                    dispatch(setComplete('ing'));

                    getTrain()

                } else {
                    trainError()
                }
            } catch (error) {
                trainError()
            }
        }

        // 真正执行上传
        try {
            // 上传
            const res_upload = await uploadVoice(formData);
            // console.log('res_upload',res_upload)
            if (JSON.stringify(res_upload.result) !== '{}' && !res_upload.result.errCode) {
                /*if (res_upload.code === "ERROR") {
                    messageInfo(errorInfoCreator(res_upload?.message))
                }*/
                dispatch(setUpload(true));
                dispatch(setUserUploadPending(false));
                dispatch(setTrain('ing'));
                dispatch(setUserUpload(true));
                // 训练
                formData.append('source','upload');
                await try2train()
            } else {
                dispatch(setUserUploadPending(false));
                dispatch(setUpload(false));
                dispatch(setUploadFlag(true));
                messageInfo(warningInfo)
            }
        } catch (e) {
            dispatch(setUpload(false));
            dispatch(setUploadFlag(true));
            dispatch(setUserUploadPending(false));
            messageInfo(warningInfo)
            console.log('---err---',e)
        }
    }

    const back2start_Ref = useRef();
    const back2start = () => {
        confirm({
            title: '是否确定要重新录入',
            centered: true,
            icon: <ExclamationCircleFilled />,
            okText: '确定',
            cancelText: '取消',
            getContainer:() => back2start_Ref.current,
            content:
                <div className="dialogText">
                    点击【确定】，当前已经训练完成的声纹模型会被立即删除，然后你可以开始重新采集语音数据。
                </div>,
            onOk() {
                reStart()
            },
        });
    }

    const getTools = () => {
        if (!uploadFlag) return
        setAudioUrl('');
        dispatch(setUserRecord(false));
    }

    const [more, setMore] = useState(false);

    return(
        <div id="upload" style={current !=='record' ? {} : {display:'none'}}>
            {
                !recRecord &&
                !recUploadPending &&
                !recUpload &&
                !audioUrl &&
                <div className="text">
                    <span className="boldText">说话人识别，又称「声纹识别」，通常应用于辅助身份验证。</span>
                    开始识别前，需要先采集一段清晰的语音，机器通过特定的算法提取这段语音中的发音特征并保存下来。然后当新的语音输入时，机器用同样的方法提取新语音中的特征，与数据库中已有的特征进行对比分析，从而确定身份。
                </div>
            }
            <SpeedOfProgress />
            {
                (recRecord || recUploadPending) &&
                <div className="pendingStatus">
                    <i className="icon">
                        <InfoCircleFilled className="pending"/>
                    </i>
                    { recRecord ? '正在录制声音中' : '录制的声音正在上传中' }
                    ，前往「
                    <span className="changeTab" onClick={() => setCurrent('record')}>录入声音</span>
                    」分页查看详情
                </div>
            }
            {
                !recRecord &&
                !recUploadPending &&
                !recUpload
                    ? !audioUrl
                        ?
                        <>
                            <div className="text">
                                请上传一段 80～120 秒的录音文件。建议在
                                <span className="boldText">较为安静的环境中，用正常语速，中文普通话发音</span>
                                。你可以随意选择阅读材料，只要保证可以比较流利地阅读即可。若在阅读过程中发现自己念错了字，不需要修正，继续阅读后面的内容即可。若觉得难以一次性读完，可以中途暂停休息，尽量避免阅读过程中有较长时间（超过 10 秒）的停顿。
                            </div>
                            <div className="text">
                                <div className="boldText">
                                    录音文件建议是 wav（不压缩，pcm 编码）格式，采样率 16000，16bit 采样精度的单声道语音。
                                </div>

                                大部分手机的录音机 App 都支持选择录音格式，但往往不支持选择采样率、采样精度、声道数。
                                <br/>
                                请尽量将录音机设置为接近实验平台要求的参数，而不是一味追求高参数。
                                <span className="boldText">
                                    对于不完全符合实验平台要求的录音，我们会在上传完成后先进行转码。
                                </span>
                                转码的过程中，部分声音信息会丢失，导致训练出来的模型可靠性略有下降。如果对于模型的可靠性有较高要求，建议自行对音频进行裁切、转码、试听。
                            </div>
                            <div className="tools">
                                <span className="recommend">推荐的第三方工具</span>
                                （使用在线工具时请注意保护个人隐私）
                                <br/>
                                在线可视化剪辑：
                                <a href="https://www.67tool.com/audio/edit/cut"
                                   target="_blank" rel="noreferrer"
                                >即时工具</a>
                                、
                                <a href="https://mp3cut.net/cn/"
                                   target="_blank" rel="noreferrer"
                                >Audio Cutter</a>
                                <br/>
                                在线格式转换与参数调整：
                                <a href="https://online-audio-converter.com/cn/"
                                   target="_blank" rel="noreferrer"
                                >音频转换器</a>
                                、
                                <a href="https://convertio.co/zh/formats/wav/"
                                   target="_blank" rel="noreferrer"
                                >Convertio</a>
                                <br/>
                                剪辑与格式转换软件：
                                <a href="http://www.pcfreetime.com/formatfactory/CN/index.html"
                                   target="_blank" rel="noreferrer"
                                >格式工厂</a>
                            </div>
                            <Button
                                type="primary"
                                className={ !uploadFlag || !recordFlag ? "browse-disabled" : "browse"}
                                onClick={browse}
                                disabled={!uploadFlag || !recordFlag}
                            >浏览本地文件</Button>
                            <div className="audioStyle">请选择小于 5M，时长 80～120 秒的 wav 或 mp3 格式的文件。</div>
                        </>
                        :
                        <>
                            { audioUrl !== '0' && <AudioPlayer url={audioUrl}/> }
                            {
                                !userComplete
                                    ?
                                    <>
                                        <p className="userAudio">
                                            {name}
                                            {
                                                !upload &&
                                                <span className="getTools" onClick={getTools}>
                                                获取音频编辑工具
                                                </span>
                                            }
                                        </p>
                                        <div className="buttons">
                                            {trainFlag
                                                ?
                                                <span
                                                    className={uploadFlag ? "reselect" : "disabled"}
                                                    onClick={reselect}
                                                >重新选择</span>
                                                :
                                                <span
                                                    className="reselect"
                                                    onClick={reStart}
                                                >返回首页</span>
                                            }
                                            <span
                                                className={ trainFlag && uploadFlag ? "upload" : "disabled" }
                                                onClick={uploadAudio}
                                            >上传</span>
                                        </div>
                                        <div className="audioStyle">请选择小于 5M，时长 80～120 秒的 wav 或 mp3 格式的文件。</div>
                                    </>
                                    :
                                    <div className="userAudioSuccessBox">
                                        <div className="userAudioSuccess">
                                            {name}
                                            <div className="successTextTips">
                                                <i className="icon">
                                                    <CheckCircleFilled className="success" />
                                                </i>
                                                声纹录入完成，请前往英荔创作平台继续实验
                                            </div>
                                        </div>
                                        <Button className="reselect" onClick={back2start}>重新录入</Button>
                                    </div>
                            }
                        </>
                    :
                    !recRecord && !recUploadPending &&
                    <div className="afterUpload">
                        {
                            !recComplete
                            ?   train
                                    ?
                                    <>
                                        <i className="icon">
                                            <InfoCircleFilled className="pending" />
                                        </i>
                                        声音已经上传，模型正在训练中，前往「
                                        <span className="changeTab" onClick={() => setCurrent('record')}>录入声音</span>
                                        」分页查看详情
                                    </>
                                    : <>
                                        <i className="icon">
                                            <CloseCircleFilled style={{color:'#ff4d4f'}}/>
                                        </i>
                                        训练失败，前往「
                                        <span className="changeTab" onClick={() => setCurrent('record')}>录入声音</span>
                                        」分页查看详情
                                      </>
                            :   <>
                                    <i className="icon">
                                        <CheckCircleFilled className="success" />
                                    </i>
                                    声纹录入完成，请前往英荔创作平台继续实验，或前往「
                                    <span className="changeTab" onClick={() => setCurrent('record')}>录入声音</span>
                                    」分页查看详情
                                </>
                        }
                    </div>
            }

            {userComplete === true && !recComplete &&
                <div className="hasWaveText">
                    说话人识别，通常应用于辅助身份验证，主要原因是其便利性很高但可靠程度稍弱。
                    <br/>
                    当前实验环境仅用于体验说话人识别技术中的 1:1 辨认，即判断「现在说话的是不是你」。
                    说话人识别还有一种主要的应用场景是 1:N 辨认，即区分出「现在说话的是谁」，原理是类似的。
                    <br/>
                    {
                        more ||
                        <span className="loadMore" onClick={loadMore}>
                            了解更多 <RightOutlined/>
                        </span>
                    }
                </div>
            }
            {more &&
                <>
                    <div className="hasWaveText">
                        <div className="boldText">
                            影响说话人识别可靠度的主要因素：
                        </div>
                        1、的确可能存在两个声纹特征高度相似的人。如果说话人识别的声纹库足够大，就很容易遇到这种情况；
                        <br/>
                        2、当前主流的模型只能对标准的中文普通话进行训练。若录入的声音并非标准的中文普通话，可能会对模型的准确率产生影响；
                        <br/>
                        3、声音很容易受到干扰。辨认说话人时的语音语调若和录入的声音样本有较大差异，或是环境音过于嘈杂，也可能导致辨认错误。
                        <br/>
                        聪明的你，在闭上眼睛后也许可以辨认出身边的几个人，但是不是也很难辨认出全班同学呢？
                    </div>
                    <div className="hasWaveText">
                        <div className="boldText">
                            说话人识别的过程由于不需要使用者额外进行操作，在某些场景下有难以代替的优势。
                        </div>
                        比如在我们
                        <span className="boldText">使用智能语音助手</span>
                        （如：小爱同学）的过程中，希望自己的声音只会唤醒自己的语音助手，不会出现一个家庭中多个语音助手指令混乱的情况。在这种场景里，输入密码、指纹识别、人脸识别都非常麻烦，而说话人识别就可以很好地完成任务。
                        <br/>
                        厦门天聪智能软件有限公司研发了一套
                        <span className="boldText">社保声纹识别身份认证系统</span>
                        。不熟悉电脑或智能手机操作的老年人，可以通过电话轻松完成业务。有效解决养老人员远程身份认证的难题，便民的同时，防止冒领、盗领养老金现象的发生。
                    </div>
                </>
            }
            <div className="back2startContainer">
                <div className="container" ref={back2start_Ref}></div>
            </div>
        </div>
    )
}

export default UploadPage;
