import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/header';
import RouterRender from './router';
import './App.scss';

function App() {
  return (
    <Router>
      <div className="bodyBox">
        <Header />
        <RouterRender />
      </div>
    </Router>
  );
}

export default App;
