import React from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const ImageView = ({ photoImages }) => {
  return (
    <PhotoProvider
      pullClosable={false}
      viewClassName="_viewBox"
      maskOpacity={0.7}
      loop={true}
      toolbarRender={({ onScale, scale }) => {
        return (
          <>
            <svg
              className="PhotoView-PhotoSlider__toolbarIcon"
              width="30"
              height="30"
              viewBox="0 0 768 768"
              fill="white"
              onClick={() => onScale(scale + 0.5)}
            >
              <path d="M384 640.5q105 0 180.75-75.75t75.75-180.75-75.75-180.75-180.75-75.75-180.75 75.75-75.75 180.75 75.75 180.75 180.75 75.75zM384 64.5q132 0 225.75 93.75t93.75 225.75-93.75 225.75-225.75 93.75-225.75-93.75-93.75-225.75 93.75-225.75 225.75-93.75zM415.5 223.5v129h129v63h-129v129h-63v-129h-129v-63h129v-129h63z" />
            </svg>
            <svg
              className="PhotoView-PhotoSlider__toolbarIcon"
              width="30"
              height="30"
              viewBox="0 0 768 768"
              fill="white"
              onClick={() => onScale(scale - 0.5)}
            >
              <path d="M384 640.5q105 0 180.75-75.75t75.75-180.75-75.75-180.75-180.75-75.75-180.75 75.75-75.75 180.75 75.75 180.75 180.75 75.75zM384 64.5q132 0 225.75 93.75t93.75 225.75-93.75 225.75-225.75 93.75-225.75-93.75-93.75-225.75 93.75-225.75 225.75-93.75zM223.5 352.5h321v63h-321v-63z" />
            </svg>
          </>
        );
      }}
    >
      {photoImages.map((item, index) => (
        <PhotoView key={index} src={item.url}>
          <div className="photoBox">
            <div>{item.title}</div>
            <img src={item.url} alt="" style={{ cursor: 'pointer' }} />
          </div>
        </PhotoView>
      ))}
    </PhotoProvider>
  );
};

export default ImageView;
