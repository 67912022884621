import { createSlice } from '@reduxjs/toolkit'

export const cropSlice = createSlice({
    name: 'textCreateImage',
    initialState: {
        targets: []
    },
    reducers: {
        setTargets: (state, action) => {
            state.targets = action.payload
        }
    }
})


export const { setTargets } = cropSlice.actions;

export default cropSlice.reducer;