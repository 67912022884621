import Home from '../page/Home';
import NotFound from '../page/403';
import FaceContrast from '../page/FaceContrast';
import ClassificationOfIrises from '../page/Iris';
import DeepReinforcementLearning from '../page/DeepReinforcementLearning';
import CropDiseasesAndPests from '../page/CropDiseasesAndPests';
import PatternRecognitionExpertSystem from '../page/patternRecognitionExpertSystem';
import SpeakerRecognition from '../page/speakerRecognition';
import TextCreateImage from '../page/textCreateImage'

export const routes = [
  {
    path: '/',
    element: Home
  },
  {
    path: '/faceContrast',
    element: FaceContrast
  },
  {
    path: '/iris',
    element: ClassificationOfIrises
  },
  {
    path: '/deepReinforcementLearning',
    element: DeepReinforcementLearning
  },
  {
    path: '/crops',
    element: CropDiseasesAndPests
  },
  {
    path: '/patternRecognition',
    element: PatternRecognitionExpertSystem
  },
  {
    path: '/speakerRecognition',
    element: SpeakerRecognition
  },
  {
    path: '/textCreateImage',
    element: TextCreateImage
  },
  {
    path: '/403',
    element: NotFound
  }
];

export const Route404 = {
  path: '/*',
  redirect: '/'
};
