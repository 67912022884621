import React from 'react';
import { InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { cycles, iterationsNum } from '../../../store/createSlice';
import './style.scss';

const StepFour = props => {
  const dispatch = useDispatch();
  // const imagesNumber = useSelector(state => state.counter.imagesNum);
  const cyclesNumber = useSelector(state => state.counter.cycles);
  const iterationsNumber = useSelector(state => state.counter.iterationsNum);
  const comPower = useSelector(state => state.counter.computingPower);

  return (
    <div className="step" id="stepFour">
      <div className="title">设计强化学习模型</div>
      <p className="describe">
        强化学习不要求预先给定任何数据，而是通过接收环境对动作的奖励（反馈）获得学习信息。
        <br />
        但首先需要通过一段时间地运行，去观察大量不同的环境，利用深度学习给模型建立起感知环境的能力，我们简单地把这个过程称为采样阶段。在一个真实的游戏中，状态的种类数是非常多的，这就需要更多的样本和更多次迭代。
      </p>
      <div className="model">
        <img
          className="modelImg"
          src="/image/deepLearning/step3_1.png"
          alt="定义一个状态"
        />
        <div className="process_jtLine">
          {/* <span></span> */}
          <img
            src="/image/deepLearning/arrow_right.png"
            alt=""
            className="arrow_right"
          />
        </div>
        <img
          className="modelImg"
          src="/image/deepLearning/step4_2.png"
          alt="感知状态"
        />
        <div>
          <img
            src="/image/deepLearning/arrow_cycle.png"
            alt=""
            className="arrow_cycle"
          />
        </div>
        {/* <div className="process_jtCycle">
        
          <img
            src="/image/deepLearning/arrow_left.png"
            alt=""
            className="arrow_left"
          />
          <img
            src="/image/deepLearning/arrow_right.png"
            alt=""
            className="arrow_right"
          />
        </div> */}
        <img
          className="modelImg"
          src="/image/deepLearning/step4_3.png"
          alt="执行动作，改变状态"
        />
        <div>
          <img
            src="/image/deepLearning/arrow_cycle.png"
            alt=""
            className="arrow_cycle"
          />
        </div>
        {/* <div className="process_jtCycle">
        
          <img
            src="/image/deepLearning/arrow_left.png"
            alt=""
            className="arrow_left"
          />
          <img
            src="/image/deepLearning/arrow_right.png"
            alt=""
            className="arrow_right"
          />
        </div> */}
        <img
          className="modelImg"
          src="/image/deepLearning/step4_4.png"
          alt="采集样本"
        />
      </div>
      <div className="textBox">
        <span>定义一个状态</span>
        <span>感知状态</span>
        <span>执行动作，改变状态</span>
        <span>采集样本</span>
      </div>
      <div className="middle">
        {/* <div className="process_jt">
          <em className="jt_right"></em>
         
          <img
            src="/image/deepLearning/arrow_bottom.png"
            alt=""
            className="arrow_right"
          />
        </div> */}
        <img src="/image/deepLearning/arrow_z.png" alt="" className="arrow_z" />
      </div>
      <div className="bottom">
        <img src="/image/deepLearning/step4_5.png" alt="训练模型" />
        <span>训练模型</span>
      </div>
      {props.training ? (
        <>
          {/* <div className="inputNum">
            <span>设置单个状态所含图像数量：{imagesNumber}</span>
          </div> */}
          <div className="inputNum">
            <span>设置样本采集阶段的循环次数（万次）：{cyclesNumber}</span>
          </div>
          <div className="inputNum">
            <span>设置训练模型阶段的迭代次数（万次）：{iterationsNumber}</span>
          </div>
          <div className="inputNum">
            <span>分配算力(%)：{comPower}%</span>
          </div>
        </>
      ) : (
        <>
          {/* <div className="inputNum">
            <span>设置单个状态所含图像数量：</span>
            <InputNumber
              min={1}
              max={10}
              precision={0}
              defaultValue={imagesNumber}
              onChange={value => dispatch(imagesNum(value))}
            />
            <span className="inputTips">当前实验环境规定最多 10 张</span>
          </div> */}
          <div className="inputNum">
            <span>设置样本采集阶段的循环次数（万次）：</span>
            <InputNumber
              min={1}
              max={20}
              precision={0}
              defaultValue={cyclesNumber}
              onChange={value => dispatch(cycles(value))}
            />
            <span className="inputTips">
              当前实验环境规定最多采集 20 万份样本
            </span>
          </div>
          <div className="inputNum">
            <span>设置训练模型阶段的迭代次数（万次）：</span>
            <InputNumber
              min={1}
              precision={0}
              max={200}
              defaultValue={iterationsNumber}
              onChange={value => dispatch(iterationsNum(value))}
            />
            <span className="inputTips">当前实验环境规定最多训练 200 万次</span>
          </div>
        </>
      )}
    </div>
  );
};
export default StepFour;
