import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {changeFlip, changeSpin, changeCut, changeTrans} from '../../../store/cropSlice';
import {Button} from "antd";
import './style.scss';

const StepTwo = (props) => {

    const state = useSelector(state => state.crop);
    const dispatch = useDispatch();

    const transformArr = [
        {
            id: 1,
            name: '翻转',
            text: '水平翻转类似于做对称，垂直翻转就是翻转 180 度，可以将一个样本扩充成 3 种情况。',
            isOpen: state.flip,
            setIsOpen () {
                dispatch(changeFlip());
            },
            imgs: [
                '/image/cropDiseases/flip1.png',
                '/image/cropDiseases/flip2.png',
            ],
        },
        {
            id: 2,
            name: '旋转',
            text: '旋转可以将原有的单个样本扩充为无限数量的情况，一般会设置随机旋转。',
            isOpen: state.spin,
            setIsOpen () {
                dispatch(changeSpin());
            },
            imgs: [
                '/image/cropDiseases/spin1.png',
                '/image/cropDiseases/spin2.png',
                '/image/cropDiseases/spin3.png',
            ]
        },
        {
            id: 3,
            name: '裁剪',
            text: '剪裁可以将原有的单个样本扩充为无限数量的情况，一般会设置随机剪裁。',
            isOpen: state.cut,
            setIsOpen () {
                dispatch(changeCut());
            },
            imgs: [
                '/image/cropDiseases/cut1.png',
                '/image/cropDiseases/cut2.png',
                '/image/cropDiseases/cut3.png',
            ]
        },
        {
            id: 4,
            name: '变形缩放',
            text: '变形缩放可以模拟一些拍摄存在畸变的情况。',
            isOpen: state.trans,
            setIsOpen () {
                dispatch(changeTrans());
            },
            imgs: [
                '/image/cropDiseases/trans1.png',
                '/image/cropDiseases/trans2.png',
            ]
        },
    ]

    const curClass = (id) => {
        if(id === 3) return 'marginPic cut'
        if(id === 4) return 'marginPic trans'
        return 'marginPic'
    }

    return(
        <div id="step2">
            <div className="stepTitle">几何变换</div>
            <p className="text text1">
                数据增强也叫数据扩增，意思是在不实质性地增加数据的情况下，让有限的数据产生等价于更多数据的价值。
                <br/>
                几何变换是图像识别领域一类常见的数据增强方式，包括翻转，旋转，移位，裁剪，变形，缩放等。
                <br/>
                几何变换，可以用来模拟不同拍摄角度下的样本。
            </p>
            {transformArr.map(item =>
                <div key={item.id} className="transformBox">
                    <div className="secondTitle">{item.name}</div>
                    <p className="text text2">
                        {item.text}
                    </p>
                    <div className="transform">
                        <img
                            src="/image/cropDiseases/default.png"
                            alt=""
                        />
                        {
                            item.isOpen
                                ?
                                <>
                                    <img
                                        src="/image/arrow-black.png"
                                        className="icon"
                                        alt=""
                                    />

                                    {item.imgs.map((imgsrc, idx)=>
                                        <img
                                            key={idx}
                                            src={imgsrc}
                                            className={curClass(item.id)}
                                            alt=""
                                        />
                                    )}

                                    {item.imgs.length > 2 &&
                                        <div className="dotsBox">
                                            <div className="dot"/>
                                            <div className="dot"/>
                                            <div className="dot"/>
                                        </div>
                                    }
                                </>
                                :
                                <button 
                                    className="bianhuan" 
                                    onClick={item.setIsOpen}>
                                    变换
                                </button>
                        }
                    </div>
                </div>
            )}
            <div className="buttons">
                <Button onClick={props.prev}>上一步</Button>
                <Button onClick={props.next} type="primary">下一步</Button>
            </div>
        </div>
    )
}

export default StepTwo;
