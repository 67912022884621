import React, {useCallback, useEffect, useMemo, useReducer, useRef, useState} from "react"
import {Button, ConfigProvider, Image as ImageTag, Radio} from "antd";
import zhCN from "antd/es/locale/zh_CN";
import {RightOutlined} from "@ant-design/icons";
import DropdownBox from "../Dropdown";
import module from "../../../assets/textCreateImage/module.json";
import {getDataJson} from "../../../utils";
import data from "../../../assets/textCreateImage/data.json";
import "./style.scss"

const GeneratorInner = (props) => {
    const { type = "advanced", baseurl, moduleIndex, dashLine = true } = props
    
    useEffect(() => {
        props.more && moduleItem.quickGenerate && !imageList.length && generateImage()
        // eslint-disable-next-line
    }, [props.more])

    const [currentTime, setCurrentTime] = useState(0)

    // 点击 开始生成/重新生成 生成图片setMore
    const [generate, setGenerate] = useState(false)

    // 点击 开始生成/重新生成 正在生成图片
    const [generating, setGenerating] = useState(false)

    useEffect(() => {
        const dataArray = module.modules
        getDataJson(dataArray[moduleIndex >= 0 ? moduleIndex : Math.floor( Math.random() * dataArray.length)]).then(module => {
            console.log('moduleInner', module)
            // 得到模块信息，需要根据模块进一步选择图片 keywords
            moduleDataDispatch({ type: "update", module })

            // module.json 的 module 和 data.json 的 module 不对应，要特别注意！
            const moduleItem = data[module["group_name"]]
            console.log('item', moduleItem)

            moduleItemDispatch({ type: "update", item: moduleItem })

            handleKeyword(moduleItem)

            // languageMapDispatch({ type: "reverse" })

            /*setTimeout(() => {
                // 正在生成中
                setGenerating(false)
                // 点击「换一换」隐藏「了解更多」按钮
                setShowMoreButton(false)
                // 并且收起「了解更多」的文本内容
                setShowMore(false)
                // 重置为未生成
                setGenerate(false)
                // 清空图片列表
                imageListDispatch({ type: "updateImageList", imageList: [] })
            }, 300)

            generateImageTimer.current && clearTimeout(generateImageTimer.current)*/

            // const moduleName = data[]
            // console.log('moduleName', moduleName)
        })
        // eslint-disable-next-line
    }, [])


    const [correctKeywordList, setCorrectKeywordList] = useState([])
    // 根据 moduleItem 处理 keywordList
    const handleKeyword = moduleItem => {
        const _keywordList = []
        // eslint-disable-next-line
        moduleItem?.languageMap.map(obj => {
            const inner = []
            for (const key in obj) {
                inner.push(key)
            }
            _keywordList.push(inner)
        })

        // console.log('_keywordList', _keywordList)

        setCorrectKeywordList(_keywordList)

        let keywordIndex = -1
        const list = moduleItem.keywords.map(item => {
            if (item === "keyword") {
                keywordIndex++
                return _keywordList[keywordIndex]
            }
            return item
        })

        setKeywordList(list)
    }


    const moduleItemReducer = (state, action) => {
        if (action.type === "update") return action.item
    }

    const [
        moduleItem,
        moduleItemDispatch
    ] = useReducer(moduleItemReducer, null, () => null)
    

    const [keywordList, setKeywordList] = useState([])

    const moduleDataReducer = (state, action) => {
        if (action.type === "update") return action.module
    }

    const [
        moduleData,
        moduleDataDispatch
    ] = useReducer(moduleDataReducer, null, () => null)

    // 等待图片清晰后才显示「了解更多」
    const [showMoreButton, setShowMoreButton] = useState(false)
    
    useEffect(() => {
        if (!moduleData) return
        // 根据范围获取随机时间
        setRandomCurrentTimeByModuleData()
        // eslint-disable-next-line
    }, [moduleData])
    
    const setRandomCurrentTimeByModuleData = () => {
        const time = moduleData.variable_info.length <= 3
            ? Math.random() + 2.5
            : Math.random() + 3.5
        setCurrentTime(time)
    }
    
    const showImageTime = useRef(0)
    useEffect(() => {
        showImageTime.current = currentTime
        // console.log('effect currentTime',currentTime)
        
    }, [currentTime])

    // 根据使用地方 type 的不同，从而进行设定不同的逻辑、样式
    // console.log('type',type)

    // 以 updateFlag 的值来决定是否更新这个组件
    // console.log('updateFlag', updateFlag)

    

    // // 点击 开始生成/重新生成 生成图片
    // const [generate, setGenerate] = useState(false)

    // 根据初始默认（随机）模块或者「换一换」按钮得到的模块，获取随机五张图片
    const imageListReducer = (state, action) => {
        if (action.type === "updateImageList") return action.imageList
        throw Error("Unknown action: " + action.type);
    }

    const [
        imageList,
        imageListDispatch
    ] = useReducer(imageListReducer, null, () => [])

    // 使图片变清晰
    const [clear, setClear] = useState(false)

    const generateImageTimer = useRef(0)
    const generateImage = async () => {
        if (generating) return
        generateImageTimer.current && clearTimeout(generateImageTimer.current)
        setImagesLoaded(false)
        setGenerate(true)
        setGenerating(true)
        setClear(false)
        setImageFilter(false)
        // 将 props 中的模块传入，生成不同的图片
        const images = await getImage()
        imageListDispatch({ type: "updateImageList", imageList: images })
        // console.log('getImage()', getImage())
        
    }
    const [imagesLoaded, setImagesLoaded] = useState(false)
    
    useEffect(() => {
        if (imagesLoaded) {
            setTimeout(() => {
                setClear(true)
            },50)

            generateImageTimer.current = setTimeout(() => {
                // 如果没有「单项解读」就没有「了解更多」按钮
                moduleItem.singleSentence.length && setShowMoreButton(true)
                setGenerating(false)
            }, showImageTime.current * 1000)
        }
        // eslint-disable-next-line
    }, [imagesLoaded])

    // 点击「了解更多」按钮
    const [showMore, setShowMore] = useState(false)

    // userKeywordList 置空
    useEffect(() => {
        // console.log('correct', correctKeywordList)
        // keywordList 更改的时候将 userKeywordList.current 置空
        // 让他重新生成
        userKeywordList.current = []
    }, [keywordList])


    // 获取正确的 keyword
    const getCurrentKeyword = index => {
        if (keywordList?.length) {

            // 将所有的添加一遍 有性能浪费 看怎么只将相同的进行 push
            if (userKeywordList.current.length < correctKeywordList.length) {
                userKeywordList.current.push(keywordList[index][0])
                return ""
            }

            return  keywordList[index]?.length ? keywordList[index][0] : "wrong"
        }
        return ""
    }
    
    const [specialKeywordList, setSpecialKeywordList] = useState([])
    const userKeywordList = useRef([])
    const [imageFilter, setImageFilter] = useState(false)
    const getUserKeywordList = (keyword, keywordIndex, userSelect) => {
        // 相同的 props 传入 DropdownBox 不会引起更新 故做以下处理
        let flag = true

        // 保存之前的 userKeywordList 列表
        // let previousUserKeywordList = userKeywordListStatus

        // 如果已经相同 则需要根据 index 进行替换
        if (correctKeywordList.length <= userKeywordList.current.length) {
            // eslint-disable-next-line
            correctKeywordList.map((item, index) => {
                // eslint-disable-next-line
                item.map(inner => {
                    if (~inner.indexOf(keyword)) {
                        userKeywordList.current[keywordIndex] = keyword
                        flag = false
                        // console.log('index', index)
                    }
                })
            })
        }

        flag && userKeywordList.current.push(keyword)

        // 删除多余的项
        // for (let num = 0; num < userKeywordList.current.length - correctKeywordList.length; num++) {
        //     userKeywordList.current.pop()
        // }

        console.log('userKeywordList', userKeywordList.current)
        
        if (moduleIndex === 11) {
            setSpecialKeywordList([...userKeywordList.current])
        }
        
        if (moduleItem.quickGenerate && userSelect) {
            console.log('userSelect', userSelect)
            setImagesLoaded(false)
            setTimeout(() => {
                generateImage()
            })
            return
        }
        
        // console.log('afterGenerateKeywordList', afterGenerateKeywordList.current)

        const deepArraysEqual = (arr1, arr2) => {
            if (arr1.length !== arr2.length) {
                return false;
            }

            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) {
                    return false;
                }
            }

            return true;
        }

        // 将之前的 userKeywordList 和现在的进行对比
        // 通过 Dropdown 选择不同的关键字时 对图片添加透明度滤镜
       setImageFilter(!deepArraysEqual(userKeywordList.current, afterGenerateKeywordList.current))

    }

    const afterGenerateKeywordList = useRef([])
    useEffect(() => {
        // imageList 变化时保存 userKeywordList
        // console.log('imageList change', userKeywordList.current)
        afterGenerateKeywordList.current = JSON.parse(JSON.stringify(userKeywordList.current))
        // console.log('afterGenerateKeywordList', afterGenerateKeywordList.current)
    }, [imageList])

    // 根据 module & keywords 真正得到图片 并且通过 dispatch 传递给 state
    const getImage = () => {
        // 1. 获取用户自定义排列的 keywordsList
        // 2. 根据 module 去拉取 module json 文件
        // 3. 根据具体的 keywordList 从 module json 拿到具体 keywordList 的 prefix 进行拼接获取图片

        let keywordList1
        console.log('moduleItem', moduleItem)
        // 如果一个 module 中有重复的 keyword 则不能进行 sort 排序
        if (moduleItem.keywordOrder) {
            const arr = userKeywordList.current.map(item => {
                const arr = []
                // eslint-disable-next-line
                moduleItem.languageMap.map((obj, index) => {
                    for (let key in obj) {
                        if (key === item) {
                            arr.push(obj[key])
                        }
                    }
                })
                return arr[0]
            })
            keywordList1 = moduleItem.keywordOrder.map(index => arr[index])
        } else {
            keywordList1 = userKeywordList.current.map(item => {
                const arr = []
                // eslint-disable-next-line
                moduleItem.languageMap.map((obj, index) => {
                    for (let key in obj) {
                        if (key === item) {
                            arr.push(obj[key])
                        }
                    }
                })
                return arr[0]
            }).sort()
        }


        // 使用 sort 排序会有问题 
        // 需要根据 item["variable_assignments"] 的顺序来排列 keywordList1 的顺序

        console.log('keywordList1', keywordList1)

        let currentImageList
        // eslint-disable-next-line
        moduleData["completion_info"].map(item => {
            const keywordList2 = moduleItem.keywordOrder
                ? item["variable_assignments"]
                : item["variable_assignments"].sort()

            if (JSON.stringify(keywordList1) === JSON.stringify(keywordList2)) {
                // 得到 prefix
                // 拼接规则 baseurl + prefix + "_num.png" num 为 0-30 的随机数
                console.log('item', item)
                let prefix 
                if (moduleItem.imagePrompt) {
                    // 根据用户选择的 index 读取 prompt
                    prefix = item["completions"].find(item => 
                        item.img === moduleItem.imagePrompt[promptValue].prompt
                    )["image_url_prefix"]
                } else {
                    prefix = item["completions"][0]["image_url_prefix"]
                }
                // console.log('prefix', prefix)

                const generateRandomNonRepeatingNumbers = (count, min, max) => {
                    if (count > max - min + 1 || max < min) {
                        return null;
                    }

                    const result = [];
                    while (result.length < count) {
                        const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
                        if (!result.includes(randomNum)) {
                            result.push(randomNum);
                        }
                    }

                    return result;
                }

                const count = moduleItem?.quickGenerate ? 2 : 5
                const randomNumbers = generateRandomNonRepeatingNumbers(count, 1, 30);
                // console.log('randomNumbers', randomNumbers)

                const imageList = []
                for (let num = 0; num < count; num++) {
                    imageList.push(baseurl + "/" + prefix + `_${randomNumbers[num]}.png`)
                }

                // console.log('imageList', imageList)
                currentImageList = imageList
            }
        })

        Promise.all(currentImageList.map(url => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = url;
                img.onload = resolve;
                img.onerror = reject;
            });
        })).then(() => {
            setImagesLoaded(true);
        });
        
        return currentImageList
    }
    
    const [promptValue, setPromptValue] = useState(0)
    const onChangePrompt = (e) => {
        setPromptValue(e.target.value)
    }

    // 在外部定义一个对象来存储关键字索引
    // eslint-disable-next-line
    const keywordIndexes = useMemo(() => ({}), [moduleItem])

    const keywordIndex = (index) =>
        keywordIndexes[index] !== undefined
            ? keywordIndexes[index]
            : (keywordIndexes[index] = Object.keys(keywordIndexes).length)

    // console.log('keywordIndexes', keywordIndexes)

    const Sentence = useCallback(() => {
        return (
            <div>
                <span className="chooseWords boldText">选择提示词：</span>
                {/*
                    动态提示词，通过 keywords 渲染，
                    将 string[] 中的 `keyword` 替换成 DropdownBox component 
                */}
                {moduleItem?.keywords.map((item, index) => (
                    <div className="keywords" key={index}>
                        {item !== "keyword"
                            ?
                            <> {item}&nbsp; </>
                            :
                            <>
                                <DropdownBox keyword={getCurrentKeyword(index)}
                                             menu={keywordList[index]}
                                             getUserKeywordList={getUserKeywordList}
                                             keywordIndex={keywordIndex(index)}
                                />
                                {index !== moduleItem?.keywords.length - 1 && <>&nbsp;</>}
                            </>
                        }
                    </div>
                ))}
            </div>
        )
        // eslint-disable-next-line
    }, [moduleItem, keywordList])
    
    
    return(
        <div className="generatorInner">
            { type !== "basic" &&
                <>
                    <div className="knowMoreText">
                        {moduleItem?.singleSentence.map((item, index) =>
                            <pre key={index}
                                 style={{ marginBottom: item ? "20px" : "0" }}
                            >
                                { item }
                            </pre>
                        )}
                    </div>
                    <div className="sentenceBox">
                        <Sentence />

                        { moduleItem?.imagePrompt &&
                            <div className="imagePromptContainer">
                                <span className="boldText">提示的图片：</span>
                                
                                    <Radio.Group onChange={onChangePrompt} 
                                                 value={promptValue}
                                                 defaultValue={0}
                                                 style={{width: "100%"}}
                                    >
                                        <div className="promptImageBox">
                                        { moduleItem.imagePrompt.map((item, index) =>
                                            <div key={index}>
                                                <Radio value={index}></Radio>
                                                <img src={`https://dall-e.eliteu.xyz/dall-e/v2/image_prompts/${item.prompt}.png`}
                                                     alt=""
                                                     key={index}
                                                     style={{filter: "blur(0)", border: '1px solid #DDD'}}
                                                />
                                            </div>
                                        )}
                                        </div>
                                            
                                    </Radio.Group>
                            </div>
                        }
                        
                        {/*  牛油果为什么要特殊处理  */}

                        { moduleIndex === 11 &&
                            <div className="special">
                                辅助提示：一个模拟{specialKeywordList[0]}的{specialKeywordList[2]}
                            </div>
                        }
                    </div>
                </>
            }

            <span className="boldText">生成的图片：</span>


            { moduleItem?.quickGenerate
                ?
                <span className="quickGenerate">此类图像支持快速生成，请直接切换描述即可获得不同图像。</span>
                :
                <Button type="primary" onClick={() => generateImage()}>
                    {generate
                        ? generating
                            ? "生成中..."
                            : "重新生成"
                        : "开始生成"
                    }
                </Button>
            }
            
            { generate &&
                <>
                    <div className="imageBox">
                        <ConfigProvider locale={zhCN}>
                            <ImageTag.PreviewGroup>
                                { imageList?.map((item, index) =>
                                    <div key={index}>
                                        <ImageTag src={item}
                                                  preview={
                                                      imagesLoaded &&
                                                      !moduleItem.quickGenerate
                                                          ? showMoreButton
                                                          && !generating
                                                          && !imageFilter
                                                          : showMoreButton && !generating
                                                  }
                                                  className={
                                                      imagesLoaded
                                                          ? !moduleItem.quickGenerate
                                                              ? clear
                                                                  ? imageFilter
                                                                      ? "imageOpacity"
                                                                      : "clear"
                                                                  : ""
                                                              : clear
                                                                  ? "clear"
                                                                  : ""
                                                          : ""
                                                  }
                                                  style={
                                                      imagesLoaded
                                                          ?
                                                          clear
                                                              ? {transition: `filter ${currentTime}s linear`}
                                                              : {}
                                                          : { opacity: 0 }
                                                  }
                                        />
                                    </div>
                                )}

                                { imageList.length < 5 &&
                                    [1,2,3].map((item) => <i key={item}></i>)
                                }
                            </ImageTag.PreviewGroup>
                        </ConfigProvider>
                    </div>


                    { type === "basic" && 
                        showMoreButton ?
                        // 这里可能没有了解更多，根据情况再判断
                        !showMore
                            ?
                            <span onClick={() => setShowMore(true)}
                                  className="knowMore">了解更多 <RightOutlined/>
                                </span>
                            :
                            <div className="knowMoreText">
                                { moduleItem.singleSentence.map((item, index) => <pre key={ index }>{ item }</pre>) }
                            </div>
                        : <></>
                    }
                </>
            }

            <div className="fixMarginBottom"></div>

            { type !== "basic" && dashLine && <div className="dashLine"></div> }
        </div>
    )
}

export default GeneratorInner