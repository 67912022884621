import { Spin } from 'antd';
import './style.scss';
import {useSelector} from "react-redux";

const SpeedOfProgress = () => {
    // const step = speedOfProgress.speedOfProgress;
    const step = useSelector(state => state.speaker);

    // 录入的完成状态
    return (
        <div className='progress'>
            <div className='step'>
                {step.record === 'ing'
                    ? <Spin><div className='spin'></div></Spin>
                    : <img className='img' src={
                        step.record || step.userRecord
                            ? '/image/speaker/step1-true.png'
                            : '/image/speaker/step1-false.png'}
                           alt=''
                    />
                }
                <div className="stepText">语音数据采集</div>
            </div>


            <img className='arrow' alt='' src={
                step.record === true || step.userRecord
                    ? '/image/speaker/arrow-black.png'
                    : '/image/speaker/arrow-grey.png'}
            />

            <div className='step'>
                {step.upload === 'ing'
                    ? <Spin><div className='spin'></div></Spin>
                    : <img className='img' src={
                        step.upload
                        ? '/image/speaker/step2-true.png'
                        : '/image/speaker/step2-false.png'} alt=''
                      />
                }
                <div className="stepText">上传</div>
            </div>

            <img className='arrow' alt='' src={
                step.upload === true
                ? '/image/speaker/arrow-black.png'
                : '/image/speaker/arrow-grey.png'}
            />

            <div className='step'>
                {step.train === 'ing'
                    ? <Spin><div className='spin'></div></Spin>
                    : <img className='img' src={
                        step.train
                            ? '/image/speaker/step3-true.png'
                            : '/image/speaker/step3-false.png'} alt=''
                      />
                }
                <div className="stepText">声音处理与特征分析</div>
            </div>

            <img className='arrow' alt='' src={
                step.train === true
                    ? '/image/speaker/arrow-black.png'
                    : '/image/speaker/arrow-grey.png'}
            />

            <div className='step'>
                {step.complete === 'ing'
                    ? <Spin><div className='spin'></div></Spin>
                    : <img className='img' src={
                        step.complete
                            ? '/image/speaker/step4-true.png'
                            : '/image/speaker/step4-false.png'} alt=''
                    />
                }
                <div className="stepText">完成声纹录入</div>
            </div>
        </div >
    )
}
export default SpeedOfProgress;
