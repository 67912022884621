import React from 'react';
import { InputNumber } from 'antd';
import { computingPower } from '../../../store/createSlice';
import { useSelector, useDispatch } from 'react-redux';
import './style.scss';

const StepTwo = () => {
  const dispatch = useDispatch();
  const comPower = useSelector(state => state.counter.computingPower);

  const onChange = value => {
    dispatch(computingPower(value));
  };
  return (
    <div className="step" id="stepTwo">
      <div className="title">设置样本预处理规则</div>
      <p className="describe">
        对进入深度学习神经网络的图片进行简单处理，可以排除原始数据中的无效信息，提高运算效率。
      </p>
      <div className="process">
        <div className="top">
          <img src="/image/deepLearning/1.png" alt="原始图像" />
          <span>原始图像</span>
        </div>
        <div className="middle">
          {/* <div className="process_jt">
            <em className="jt_right"></em>
            <img src="/image/deepLearning/arrow_bottom.png" alt="" />
            {/* <span></span> */}
          {/* <svg width="32" height="24" viewBox="-50 -50 300 300">
              <polygon
                className="triangle"
                stroke-linejoin="round"
                points="100,0 0,200 200,200"
              />
            </svg> */}
          {/* </div> */}
          <img
            src="/image/deepLearning/arrow_z.png"
            alt=""
            className="arrow_z"
          />
        </div>
        <div className="bottom">
          <img
            className="modelImg"
            src="/image/deepLearning/2.png"
            alt="剪裁、压缩图片"
          />
          <div className="process_jtLine">
            {/* <span></span> */}
            <img src="/image/deepLearning/arrow_right.png" alt="" />
          </div>
          <img
            className="modelImg"
            src="/image/deepLearning/3.png"
            alt="图像灰度化"
          />
          <div className="process_jtLine">
            {/* <span></span> */}
            <img src="/image/deepLearning/arrow_right.png" alt="" />
          </div>
          <img
            className="modelImg"
            src="/image/deepLearning/4.png"
            alt="图像二值化"
          />
          <div className="process_jtLine">
            {/* <span></span> */}
            <img src="/image/deepLearning/arrow_right.png" alt="" />
          </div>
          <img
            className="modelImg"
            src="/image/deepLearning/5.png"
            alt="图像平滑处理"
          />
        </div>
        <div className="textBox">
          <span>剪裁、压缩图片</span>
          <span>图像灰度化</span>
          <span>图像二值化</span>
          <span>图像平滑处理</span>
        </div>
      </div>
      <div className="inputNum">
        <span>分配算力（%）：</span>
        <InputNumber
          min={1}
          max={100}
          precision={0}
          defaultValue={comPower}
          onChange={onChange}
        />
      </div>
      <p className="describe" style={{ marginTop: '36px' }}>
        强化学习主要是解决「决策」的问题，而决策需要准确地了解环境。深度学习主要就是解决「感知」的问题，在对样本图像进行基本的处理后就需要用卷积神经网络去理解图像中的内容。
      </p>
    </div>
  );
};
export default StepTwo;
