import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import G6 from '@antv/g6';
import './index.scss';

const data = (centerNodes) => {
  // 有空研究一下节点的增删、节点位置的自动调整
  // https://antv-g6.gitee.io/zh/docs/api/graphFunc/item
  const centerNdesY = (i) => {
    switch (centerNodes) {
      case 1:
        return (i + 1) * 50 + 140;
      case 2:
        return (i + 1) * 50 + 130;
      case 3:
        return (i + 1) * 50 + 110;
      case 4:
        return (i + 1) * 50 + 80;
      case 5:
        return (i + 1) * 50 + 55;
      case 6:
        return (i + 1) * 50 + 30;
      case 7:
        return (i + 1) * 50 + 0;
      case 8:
        return (i + 1) * 50 - 20;
      default:
        return (i + 1) * 50 - 20;
    }
  };
  const nodes = [];
  const edges = [];
  // Add 4 nodes on the left
  for (let i = 0; i < 4; i++) {
    const id = 'left' + i;
    nodes.push({
      id,
      x: 100,
      y: (i + 1) * 50 + 80,
      // 该节点可选的连接点集合，该点有两个可选的连接点
      anchorPoints: [
        [0, 0.5],
        [1, 0.5],
      ],
      type: 'circle',
      style: {
        fill: '#FFCB20',
        stroke: '#FFCB20',
      },
      size: 30,
    });
    for (let i = 0; i < centerNodes; i++) {
      let leftTargetId = 'center' + i;
      if (centerNodes < 9 || leftTargetId !== 'center4') {
        edges.push({
          source: id,
          target: leftTargetId, // 该边连入 source 点的第 0 个 anchorPoint，
          sourceAnchor: 0,
          // 该边连入 target 点的第 0 个 anchorPoint，
          targetAnchor: 0,
          type: 'can-running',
        });
      }
    }
  }
  // Center node
  for (let i = 0; i < centerNodes; i++) {
    const id = 'center' + i;
    nodes.push({
      id,
      x: 300,
      y: centerNdesY(i),
      // 该节点可选的连接点集合，该点有两个可选的连接点
      anchorPoints: [
        [0, 0.5],
        [1, 0.5],
      ],
      type: 'circle',
      style: {
        fill: '#9C57FF',
        stroke: '#9C57FF',
      },
      size: 30,
    });
    for (let i = 0; i < 3; i++) {
      let leftTargetId = 'right' + i;
      if (centerNodes < 9 || id !== 'center4') {
        edges.push({
          source: id,
          target: leftTargetId, // 该边连入 source 点的第 0 个 anchorPoint，
          sourceAnchor: 0,
          // 该边连入 target 点的第 0 个 anchorPoint，
          targetAnchor: 0,
          type: 'can-running',
        });
      }
    }
  }
  // Add 3 nodes on the right
  for (let i = 0; i < 3; i++) {
    const id = 'right' + i;
    nodes.push({
      id,
      x: 500,
      y: (i + 1) * 50 + 105,
      // 该节点可选的连接点集合，该点有两个可选的连接点
      anchorPoints: [
        [0, 0.5],
        [1, 0.5],
      ],
      type: 'circle',
      style: {
        fill: '#329CFF',
        stroke: '#329CFF',
      },
      size: 30,
    });
  }
  const data = {
    nodes,
    edges,
  };

  return data;
};

const SignalImage = ({ centerNodes }) => {
  const ref = useRef(null);

  useEffect(() => {
    let _graph = null;

    if (!_graph) {
      G6.registerEdge(
        'can-running',
        {
          afterDraw(cfg, group) {
            // 获得该边的第一个图形，这里是边的 path
            const shape = group.get('children')[0];
            let index = 0;
            // 边 path 图形的动画
            shape.animate(
              () => {
                index++;
                if (index > 9) {
                  index = 0;
                }
                const res = {
                  lineDash: [4, 2, 1, 2],
                  lineDashOffset: -index,
                };
                // 返回需要修改的参数集，这里修改了 lineDash,lineDashOffset
                return res;
              },
              {
                repeat: true, // 动画重复
                duration: 5000, // 一次动画的时长为 5000
              }
            );
          },
        },
        'cubic'
      ); // 该自定义边继承了内置三阶贝塞尔曲线边 cubic

      _graph = new G6.Graph({
        container: ReactDOM.findDOMNode(ref.current),
        width: 660,
        height: centerNodes > 7 ? 460 : 400,
        defaultEdge: {
          type: 'circle-running',
          style: {
            lineWidth: 1,
            stroke: '#ccc',
          },
        },
      });
    }
    _graph.data(data(centerNodes));
    _graph.render();
  }, [centerNodes]);

  return <div className="signal-image" ref={ref}></div>;
};

export default SignalImage;
