export const generateRandomNonRepeatingNumbers = (count, min, max) => {
    if (count > max - min + 1 || max < min) {
        return null;
    }

    const result = [];
    while (result.length < count) {
        const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
        if (!result.includes(randomNum)) {
            result.push(randomNum);
        }
    }

    return result;
}

export const getRandomNumbers = (arr) => {
    if (arr.length < 2) {
        return null; // 数组中元素数量不足两个，无法选择两个不同的数字
    }

    const randomIndexes = [];

    while (randomIndexes.length < 2) {
        const randomIndex = Math.floor(Math.random() * arr.length);
        if (!randomIndexes.includes(randomIndex)) {
            randomIndexes.push(randomIndex);
        }
    }

    return randomIndexes.map(index => arr[index]);
}

export const selectRandomExcluding = (array, itemsToExclude) => {
    // 创建一个排除特定项的临时数组
    const filteredArray = array.filter(item => !itemsToExclude.includes(item));

    // 如果剩下的项为空，则返回null
    if (filteredArray.length === 0) {
        return null;
    }

    // 从剩下的项中随机选择一个
    const randomIndex = Math.floor(Math.random() * filteredArray.length);
    return filteredArray[randomIndex];
}