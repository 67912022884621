import React, { useEffect, useState } from 'react';
import { Spin, Button, Divider, InputNumber, message } from 'antd';
import { DownloadOutlined, CheckCircleFilled } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import './index.scss';
import SignalImage from '../../components/signalImage';
import AnimateCanvas from '../../components/signalImage/animateCanvas';
// 有空把SignalImage和AnimateCanvas合并成一个组件（主要问题在动静态的转换，出现的问题：同时出现两个canvas）
import { trainIris, getTrainResult } from './../../utils';
import ImageView from '../../components/imageView';

// 以下代码多处重复，有空要重新整合
const ClassificationOfIrises = () => {
  const steps = [
    {
      title: '调节训练参数',
      status: 'wait',
      waitImg: '/image/iris_1.png',
      finishImg: '/image/iris_1done.png',
    },
    {
      title: '分配算力',
      status: 'wait',
      waitImg: '/image/iris_2.png',
      finishImg: '/image/iris_2done.png',
    },
    {
      title: '完成训练',
      status: 'wait',
      waitImg: '/image/icon3-normal.png',
      finishImg: '/image/icon3-done.png',
    },
    {
      title: '验证结果',
      status: 'wait',
      waitImg: '/image/icon4-normal.png',
      finishImg: '/image/icon4-done.png',
    },
  ];

  const [newSteps, setSteps] = useState([...steps]);
  const [current, setCurrent] = useState(0);
  const [param, setParam] = useState({
    n_hidden: 6,
    num_iterations: 1000,
    power: 50,
  });
  const [trainRes, setTrainRes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null);
  const [power_time, setPowerTime] = useState(0);
  const location = useLocation();
  const [_color, setColor] = useState([true, true, true]);
  const [introduction,setIntroduction] = useState(false);

  const downloadList = [
    {
      name: '下载完整数据集',
      url: 'https://e-course.oss-cn-shenzhen.aliyuncs.com/%E6%8F%92%E4%BB%B6%E8%B5%84%E6%96%99%E7%AE%A1%E7%90%86/%E9%B8%A2%E5%B0%BE%E8%8A%B1%E5%88%86%E7%B1%BB/%E9%B8%A2%E5%B0%BE%E8%8A%B1%20-%20%E5%AE%8C%E6%95%B4%E6%95%B0%E6%8D%AE%E9%9B%86.csv',
    },
    {
      name: '下载训练数据集',
      url: 'https://e-course.oss-cn-shenzhen.aliyuncs.com/%E6%8F%92%E4%BB%B6%E8%B5%84%E6%96%99%E7%AE%A1%E7%90%86/%E9%B8%A2%E5%B0%BE%E8%8A%B1%E5%88%86%E7%B1%BB/%E9%B8%A2%E5%B0%BE%E8%8A%B1%20-%20%E8%AE%AD%E7%BB%83%E7%94%A8%E6%95%B0%E6%8D%AE%E9%9B%86.csv',
    },
    {
      name: '下载验证数据集',
      url: 'https://e-course.oss-cn-shenzhen.aliyuncs.com/%E6%8F%92%E4%BB%B6%E8%B5%84%E6%96%99%E7%AE%A1%E7%90%86/%E9%B8%A2%E5%B0%BE%E8%8A%B1%E5%88%86%E7%B1%BB/%E9%B8%A2%E5%B0%BE%E8%8A%B1%20-%20%E6%B5%8B%E8%AF%95%E7%94%A8%E6%95%B0%E6%8D%AE%E9%9B%86%20-%20AI.csv',
    },
  ];

  const publicRender = (data) => {
    return (
      <>
        <div className="_flex">
          <div className="title">数据集：</div>
          <div
            className="context"
            style={{ color: current === 1 ? '#52c41a' : '#333333' }}
          >
            已完成录入
          </div>
        </div>
        <div className="_flex">
          <div className="title">隐节点数：</div>
          <div className="context">{data?.n_hidden || param.n_hidden}</div>
        </div>
        <div className="_flex">
          <div className="title">迭代次数：</div>
          <div className="context">
            {data?.num_iterations || param.num_iterations}
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (location.pathname.includes('iris')) {
      getTrainResult().then((res) => {
        if (res.success) {
          setTrainRes(res.result);
          setCurrent(3);
          setSteps((prev) => {
            prev.forEach((item) => (item.status = 'finish'));
            return [...prev];
          });
        }
      });
    }
  }, [location.pathname]);

  const handlerRest = () => {
    setParam({
      n_hidden: 6,
      num_iterations: 1000,
      power: 50,
    });
    setSteps((prev) => {
      prev.forEach((item) => (item.status = 'wait'));
      return [...prev];
    });
    setCurrent(0);
  };

  useEffect(() => {
    if (current === 2) {
      const newTimer = setTimeout(() => {
        setCurrent((prev) => prev + 1);
        setSteps((prev) => {
          prev[2].status = 'finish';
          prev[3].status = 'finish';
          return [...prev];
        });
      }, power_time * 1000);
      setTimer(newTimer);
    }
  }, [current, power_time]);

  const handleTrain = () => {
    setLoading(true);
    trainIris({ ...param, type: 0 })
      .then((res) => {
        if (res?.success) {
          setPowerTime(res.data.power_time);
          setTrainRes(res.data);
          setCurrent((prev) => prev + 1);
          setSteps((prev) => {
            prev[1].status = 'finish';
            prev[2].status = 'process';
            return [...prev];
          });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const reg = /^[1-9]\d*$/;

  return (
    <div className="irisBox">
      <div className="name">鸢尾花分类</div>
      <div className="content">
        <div className="dec">
          英国统计学家和生物学家 Ronald Fisher 在他 1936 年的论文中发布了包含
          150 个样本的鸢尾花数据集，美国植物学家 Edgar Anderson
          将其用于量化分类。此后，该数据集逐渐成为了机器学习领域用于教授分类算法最经典的数据集之一。
        </div>
        <div className="steps">
          {newSteps.map((item, index) => (
            <div key={index} className="step">
              <div className="stepImg">
                {item.status === 'process' ? (
                  <Spin />
                ) : (
                  <img
                    src={item.status === 'wait' ? item.waitImg : item.finishImg}
                    alt={item.title}
                  />
                )}
                <div>{item.title}</div>
              </div>
              {index !== 3 && (
                <div className="stepArrow">
                  <img
                    alt=""
                    src={
                      item.status === 'finish'
                        ? '/image/arrow-black.png'
                        : '/image/arrow-grey.png'
                    }
                  ></img>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="stepsContent">
          {current === 0 && (
            <div className="step_1">
              <div className="step_1_1">
                <div className="title">浏览数据集：</div>
                <div className="context">
                  安德森鸢尾花数据集已经预先录入完成。包含 4
                  种特征，萼片长度（Sepal Length）、萼片宽度（Sepal
                  Width）、花瓣长度（Petal Length）和花瓣宽度（Petal
                  Width），以及 3
                  种鸢尾花，变色鸢尾（Versicolor）、维吉尼亚鸢尾（Virginica）和山鸢尾（Setosa）。
                  <div>
                    {downloadList.map((item, index) => (
                      <span key={index}>
                        <a href={item.url}>
                          <DownloadOutlined />
                          {item.name}
                        </a>
                        <Divider type="vertical" />
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="step_1_2">
                <div className="title">设置隐节点数：</div>
                <div className="context">
                  <InputNumber
                    min={1}
                    max={200}
                    defaultValue={6}
                    onChange={(value) => {
                      param.n_hidden = value;
                      setParam(param);
                    }}
                    style={{ borderColor: _color[0] ? '#d9d9d9' : 'red' }}
                  />
                  <span className="tip">当前实验环境规定最多 200 个节点</span>
                </div>
              </div>
              <div className="step_1_2">
                <div className="title">设置迭代次数：</div>
                <div className="context">
                  <InputNumber
                    min={1}
                    max={20000}
                    defaultValue={1000}
                    onChange={(value) => {
                      param.num_iterations = value;
                      setParam(param);
                    }}
                    style={{ borderColor: _color[1] ? '#d9d9d9' : 'red' }}
                  />
                  <span className="tip">当前实验环境规定最多 20000 次迭代</span>
                </div>
              </div>
              <div className="bottom_btn">
                <Button
                  type="primary"
                  onClick={() => {
                    if (param.n_hidden && param.num_iterations) {
                      setColor((prev) => {
                        prev[0] = reg.test(param.n_hidden);
                        prev[1] = reg.test(param.num_iterations);
                        return [...prev];
                      });
                      if (
                        !reg.test(param.n_hidden) ||
                        !reg.test(param.num_iterations)
                      ) {
                        message.warning('当前参数只支持整数');
                        return;
                      }
                      setCurrent((prev) => prev + 1);
                      setSteps((prev) => {
                        prev[0].status = 'finish';
                        return [...prev];
                      });
                    } else {
                      message.warn('参数不能为空！');
                    }
                  }}
                >
                  确认参数
                </Button>
              </div>
            </div>
          )}
          {current === 1 && (
            <div>
              {publicRender()}
              <div className="signalImage">
                <div className="title">
                  示意：
                  {param.n_hidden > 8 && (
                    <span>隐节点数超过 8 个不完全显示</span>
                  )}
                </div>
                <div className="context">
                  <div className="_image">
                    <SignalImage
                      centerNodes={param.n_hidden > 8 ? 9 : param.n_hidden}
                      isAnimate={false}
                    />
                    {param.n_hidden > 8 && <img src="/image/more.png" alt="" />}
                  </div>
                  <div className="bottomList">
                    <span>输入层</span>
                    <span>隐藏层</span>
                    <span>输出层</span>
                  </div>
                </div>
              </div>
              <div className="_flex" style={{ paddingTop: '16px' }}>
                <div className="title">分配算力（%）：</div>
                <div className="context">
                  <InputNumber
                    min={1}
                    max={100}
                    defaultValue={50}
                    onChange={(value) => {
                      param.power = value;
                      setParam(param);
                    }}
                    style={{ borderColor: _color[2] ? '#d9d9d9' : 'red' }}
                  />
                </div>
              </div>
              <div className="bottom_btn">
                <Button
                  type="primary"
                  ghost
                  onClick={handlerRest}
                  style={{ marginRight: '16px' }}
                >
                  重设参数
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    if (param.power) {
                      setColor((prev) => {
                        prev[2] = reg.test(param.power);
                        return [...prev];
                      });
                      if (!reg.test(param.power)) {
                        message.warning('当前参数只支持整数');
                        return;
                      }
                      handleTrain();
                    } else {
                      message.warn('参数不能为空！');
                      return;
                    }
                  }}
                  loading={loading}
                >
                  开始训练
                </Button>
              </div>
            </div>
          )}
          {current === 2 && (
            <div>
              {publicRender()}
              <div className="signalImage">
                <div className="title">
                  示意：
                  {param.n_hidden > 8 && (
                    <span>隐节点数超过 8 个不完全显示</span>
                  )}
                </div>
                <div className="context">
                  <div className="_image">
                    <AnimateCanvas
                      centerNodes={param.n_hidden > 8 ? 9 : param.n_hidden}
                      isAnimate={true}
                    />
                    {param.n_hidden > 8 && <img src="/image/more.png" alt="" />}
                  </div>
                  <div className="bottomList">
                    <span>输入层</span>
                    <span>隐藏层</span>
                    <span>输出层</span>
                  </div>
                </div>
              </div>
              <div className="bottom_btn">
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    timer && clearTimeout(timer);
                    handlerRest();
                  }}
                  style={{ marginRight: '16px' }}
                >
                  取消训练
                </Button>
              </div>
            </div>
          )}
          {current === 3 && (
            <div>
              {publicRender(trainRes)}
              <div className="_flex">
                <div className="title">训练耗时：</div>
                <div className="context">{trainRes?.power_time} 秒</div>
              </div>
              <div className="_flex">
                <div className="title">预测的分类：</div>
                <div className="context">
                  {/* {trainRes?.acc.toFixed(2)}% */}
                  有 {trainRes?.error_count}{' '}
                  个样本被当前模型分到了错误的类别中
                </div>
              </div>
              <div className="signalImage">
                <div className="title">
                  结果示意图：
                  <span>模型只是在预测，不一定能获得完全正确的分类效果</span>
                </div>
                <div className="context train-res">
                  {trainRes?.images && (
                    <ImageView photoImages={trainRes.images} />
                  )}
                </div>
                <p>
                  <CheckCircleFilled />
                  完成一次实验，请重复多次实验以便确定最佳参数
                </p>
              </div>
              <div className="bottom_btn">
                <Button type="primary" ghost onClick={handlerRest}>
                  重新训练
                </Button>
              </div>
              <div className="introduction">
                <div className="top">
                  <span>上方展示的结果示意图属于 RadViz 图，你知道 RadViz 图是怎么画出来的吗？</span>
                 
                  {
                  !introduction ? 
                  (
                    <span className='linkBtn' onClick={()=>{setIntroduction(true)}}>了解更多 &gt;</span>
                  ):
                  (
                  <div className='introDetail'>
                    <span style={{marginTop:'20px'}}>
                      RadViz 的全称是 Radial Visualization，意思是径向可视化，即沿半径方向将数据用图形来呈现的方法。
                      RadViz 是基于圆形坐标系的设计思想而提出的多维可视化方法。圆形的 n 条半径表示 n 维空间，使用坐标系中的一点代表多维信息对象，其实现原理参照物理学中物体受力平衡定理。
                    </span>
                    <h2>RadViz 图的绘制方法</h2>
                    <span>
                      1、归一化：把某个特征（比如：花萼长度）的数值，按同一规则去缩小到 0～1 的范围内。处理后这个特征中的最大值为 1，其余的值均小于 1。
                    </span>
                    <div className='imgBox'> <img src="/image/iris/intro1.png" alt="" /></div>
                    <span>
                      2、画出平面坐标系：定义一个中心点（称为原点）代表值 0。由中心点向外射出多条直线，然后把直线上某个距离定义（这取决于你希望你的图有多大）为最远距离，其代表值 1，由此你可以得到一个圆。在同一条直线上，越远离中心点的值越大。一个中心点可以射出 n 条线。
                    </span>
                    <div className='imgBox'> <img src="/image/iris/intro2.png" alt="" /></div>
                    <span>
                      3、由圆心射出到圆上的线称为半径。每一条半径可以代表一种特征，有 n 种特征（比如：一朵鸢尾花有 4 个特征），就画出 n 根半径，并让它们平均分布（切分出来的各条弧线长度一致）。
                    </span>
                    <div className='imgBox'> <img src="/image/iris/intro3.png" alt="" /></div>
                    <span>
                      4、根据第一步获得的结果，将数值以坐标点的形式标注在各条对应的半径上。为了美观，圆形也可以省略不画。
                    </span>
                    <div className='imgBox'> <img src="/image/iris/intro4.png" alt="" /></div>
                    <span>
                      5、将各个相邻半径上的坐标点相连，会组成一个封闭的 n 边平面图形。
                    </span>
                    <div className='imgBox'> <img src="/image/iris/intro5.png" alt="" /></div>
                    <span>
                      6、根据物理学中的受力平衡原理，确定这个平面图形的「平衡中心」。这个「中心」就是这个样本在 RadViz 图上的坐标。
                    </span>
                    <div className='imgBox'> <img src="/image/iris/intro6.png" alt="" /></div>
                    <span>
                      7、当样本量较大时，为了保证画面简洁，坐标轴、平面图形、样本标注都可以省略不画。只保留数值 1 的坐标，并在 RadViz 图的外围适当位置画个边框。
                    </span>
                    <div className='imgBox'> <img src="/image/iris/intro7.png" alt="" /></div>
                    <span>8、根据上述规则重复多次就可以将所有样本呈现在 RadViz 图上了。</span>
                </div>
                )
                }
                </div>
              </div>
              <div className="bottom_tip">
                你所训练的模型将在 24 小时后被永久删除
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClassificationOfIrises;
