import React, { useEffect, useState } from 'react';
import Shot from '../../components/shot';
import { useNavigate } from 'react-router-dom';
import Upload from '../../components/upload';
import { jurisdiction,me } from '../../utils'
import { message } from 'antd';
import './style.scss';
const FaceRecognition = () => {
    const navigate = useNavigate();
    const [current, setCurrent] = useState('shot');
    const authority = (data) => {
        // 查看这个用户是否有这个插件的使用权限
        let i = false;
        data?.map((item) => {
            if (item.extensionId === 'faceContrast') {
                i = true;
            }
            return item;
        })
        return i;
    }
  useEffect(() => {
   
        // 如果在当前插件页面，获取登录用户的插件权限，如果没有权限，就去往 403
           // 请求me接口获取用户名
      me().then((res) => {
          setTimeout(() => {
              jurisdiction(res.username).then((res) => {
                  if (!authority(res.data)) {
                      navigate('/403') 
                  }
              })
          }, 500)
        }); 
   
        
      }, [navigate]) 
    const switchingMode = (pattern) => {
        // 如果已经录入过，那么当他切换录入方式时会提醒已经录入完成了
        if (pattern !== current) {
            setCurrent(pattern);
            if (sessionStorage.getItem('referencePicture')) {
                message.destroy();
                message.info('人脸录入已完成，重新录入会清除现有的数据');
            }
        }
    }
    return (
        <div className='faceRecognition'>
            <div className='name'>人脸对比</div>
            <div className='switch'>
                <span onClick={() => { switchingMode('shot') }} style={{ 'color': current === 'shot' ? '#0084ff' : '#333333' }}>拍摄照片</span>
                <span onClick={() => { switchingMode('upload') }} style={{ 'color': current === 'upload' ? '#0084ff' : '#333333' }}>上传图片</span>
            </div>
            <div className='details'>
                人脸对比技术通常应用于身份验证。开始对比前，需要先在数据库中录入一张合格的人脸作为对比的参照，然后才可以将新输入的人脸与数据库中已有的人脸进行对比分析，进而判断新输入的人脸的身份。
            </div>
            {
                current === 'shot' ? <Shot /> : <Upload />
            }
            <footer>英荔 AI 实验平台不会保存你的人脸信息，你所上传的所有内容将在 24 小时后被永久删除</footer>
        </div>
    )
}
export default FaceRecognition;