import React, { useState, useReducer, useRef } from 'react'
import GeneratorInner from "../GeneratorInner";
import './style.scss'
import Arrow from "../../../assets/textCreateImage/arrow.svg";

const ModuleGenerator = (props) => {

    const [more, setMore] = useState(false)
    const openDropdown = () => {
        setMore(!more)
        closeMore(index, setMore)
    }

    const {title, text, baseurl, generatorList, index, closeMore} = props

    return (
        <div className="generatorBox">
            <div className="generatorTop">
                <span className="moduleTitle">{title}</span>

                <div className="more"
                     onClick={() => openDropdown()}
                >
                    <Arrow className={more ? "moreIcon" : ""}
                           style={{fill: more ? "#0084FF" : "#333"}}
                    />

                </div>

            </div>

            <div className="titleBottomLine"></div>

            <pre>{text}</pre>

            <div className="moreBox"
                 style={{gridTemplateRows: more ? "1fr" : "0fr"}}
            >
                <div className="container">
                    {
                        generatorList?.map((moduleIndex, index, arr) => (
                            <GeneratorInner key={index}
                                            moduleIndex={moduleIndex} 
                                            baseurl={baseurl}
                                            dashLine={index !== arr.length - 1}
                                            more={more}
                            />
                        ))
                    }
                    
                </div>
            </div>

        </div>
    )
}

const Advanced = ({ show, baseurl }) => {
    
    const [moduleGeneratorList] = useReducer(null, null, () => [
        {
            title: "控制属性",
            text: "绘制一种物体并修改它的属性，这是最基础的创作能力。",
            generatorList: [0, 1, 2]
        },
        {
            title: "绘制多种物体",
            text: "同时控制多个不同物体的属性和它们之间的空间关系难度比单纯控制一个物体要高很多，因为 AI 需要去理解不同物体之间的关系。\nAI 对于少量对象的属性和位置可以比较准确地绘制，但成功率可能取决于描述的措辞方式。随着引入的对象越来越多，AI 容易混淆对象与其颜色之间的关联，成功率急剧下降。",
            generatorList: [3, 4]
        },
        {
            title: "多视角和 3D 渲染",
            text: "多视角意味着要对一个物体从不同角度看过去呈现的效果、不同程度的细节有足够的了解。3D 渲染意味着要去模拟不同情况下的光影效果，这些能力对于很多不精通作画的人类来说是难以掌握的。",
            generatorList: [5, 6]
        },
        {
            title: "推断没有被明确描述的细节",
            text: "将文本翻译成图像的任务是不精确的。一条对于图像的描述通常可以有无数种画法。例如，一条描述是「日出时坐在田野上的水豚画」。根据水豚姿势的不同，可能需要绘制相应的阴影，但在描述中并没有明确提及此细节要如何去绘制。在没有明确指示的情况下，传统的图像渲染引擎并不会去绘制「不存在的细节」。人类创作者有时也会在这种情况下出现失误。",
            generatorList: [7, 8]
        },
        {
            title: "了解地理信息",
            text: "同一个物体在不同的国家、地区或是在某些特定的时间节点会有不同的形态特征，需要掌握大量的信息才能较为准确地生成图像。在这个方面，AI 由于具备大数据的优势，时常可以展现出人类创作者难以比拟的能力。",
            generatorList: [13]
        },
        {
            title: "了解时代特征",
            text: "同一个物体在不同的时代会有不同的形态特征，需要掌握大量的信息才能较为准确地生成图像。在这个方面，AI 由于具备大数据的优势，时常可以展现出人类创作者难以比拟的能力。",
            generatorList: [14, 15]
        },
        {
            title: "文字生成图像的典型应用",
            text: "文字生成图像的能力可以极大地降低普通人进行服装设计、室内设计、平面设计的难度。",
            generatorList: [9, 10, 11, 12]
        }
    ])
    
    const hasOpenedMore = useRef([])
    const closeMore = (index, setMore) => {
        // 已经有一个打开了
        if (hasOpenedMore.current.length) {
            // 关闭这个 index 的展开
            index !== hasOpenedMore.current[0].index && hasOpenedMore.current[0].setMore(false)
            // 清空数组
            hasOpenedMore.current = []
        }
        hasOpenedMore.current.push({ index, setMore })
    }
    
    return(
        <div id="advanced" style={{ display: show ? "block" : "none" }}>

            <p className="text">
                AIGC 指的是「人工智能生成内容」，是人工智能技术中一个很重要的领域。特别是在 2022 年下半年，伴随着 Dall-E、Craiyon、Stable Diffusion 等项目的发布，AIGC 开始逐渐成为人工智能技术中最火热的话题。
            </p>
            
            <p className="text">
                在 2022 年以前，AI 领域的进步大多与理解现有数据有关。例如，AI 模型可以识别人们用手机拍摄的照片中是否有猫的存在，AI 模型也可以识别图像中的人脸是属于哪个 ID。这些模型已经足够可靠，被应用到了生活中的很多地方。生成式 AI 模型的不同之处在于可以生成世界上并不存在的全新东西。换句话说，它们是在创造，而不仅仅是在分析数据。这种能力在很长一段时间里被认为是人类所独有的能力。
            </p>

            <p className="text">
                AIGC 主要可以分为 AI 文本生成图像、AI 文本生成文段、AI 文本生成视频等类型。其中 AI 文本生成图像由于其技术最早达到商业应用级别，成为了 AIGC 中率先进入大众视野的产品。
            </p>
            
            <p className="text">
                AI 文本生成图像，顾名思义指的是 AI 可以依据少量词汇在几秒钟内生成与之相匹配的图像。
            </p>
            
            <p className="text">
                当然，就像小孩子作画一样，AI 对于不同的内容也展现出不同程度的创作能力。
            </p>

            {
                moduleGeneratorList?.map((item, index) => (
                    <ModuleGenerator key={index}
                                     title={item.title}
                                     text={item.text}
                                     baseurl={baseurl}
                                     generatorList={item.generatorList}
                                     index={index}
                                     closeMore={closeMore}
                    />
                ))
            }
        </div>
    )
}

export default Advanced