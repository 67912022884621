import React from 'react';
import './style.scss'
const Note = () => {
    return (
        <div className='NoPermission'>
            <img className='img' alt='' src='/image/empty-page.png'></img>
            <div className='tips'>
                <img alt='' src='/image/403.png'></img>
                <div>抱歉，你尚未被授权使用此功能模块，请与实验室管理员联系</div>
            </div>
        </div>
    )
}
export default Note;