import React, { useState, useRef, useEffect } from 'react';
import './style.scss';
const StepOne = props => {
  const flipRef_status = useRef(null);
  const flipRef_award = useRef(null);
  const flipRef_acting = useRef(null);
  const flipRef_action = useRef(null);
  const flipRef_surroundings = useRef(null);
  // const tipsRef = useRef(null);
  // const [tipsText, setTipsText] = useState('');
  const [showBtn, setShowBtn] = useState(false);
  // const showTips = e => {

  //   if (e.target.className.includes('surroundingsImg')) {
  //     setTipsText('环境');
  //     tipsRef.current.style = 'display:inline-block';
  //   }
  // };
  const handleShowBtn = () => {
    setShowBtn(true);
    props.parentFun(showBtn);
  };
  useEffect(() => {
    props.parentFun(showBtn);
  }, [props, showBtn]);
  return (
    <div className="step">
      <div className="title">强化学习的基本原理</div>
      <p className="describe">
        强化学习用于描述和解决智能体（Agent）在与环境的交互过程中通过学习策略以达成回报最大化或实现特定目标的问题。强化学习的基本原理可以看作是「试探评价」的过程。Agent
        选择一个动作用于环境，环境受该动作影响状态发生变化，同时产生一个强化信号（奖或惩），Agent
        根据强化信号和环境当前状态再选择下一个动作，选择的原则是使受到正强化（奖）的概率增大。
      </p>
      <div className="cardsBox">
        <div className="cards">
          <div className="jt">
            {/* <span></span> */}
            <img
              src="/image/deepLearning/arrow_bottom_black.png"
              alt=""
              className="arrow_bottom_black"
            />
          </div>
          <div className="middleBox">
            {/* <div className="bigCard acting_card">智能体</div> */}
            <div
              className="imgBox   acting_card  surroundings_img"
              ref={flipRef_acting}
              onMouseMove={() => {
                flipRef_acting.current?.classList?.add('icon_img', 'icon_big');
                handleShowBtn();
              }}
            >
              <div className="bigCard  zhengImg  rotateAmview ">智能体</div>
              <div className="fanImg  rotateAmview">
                <img
                  className="bigImg actingImg"
                  src="/image/deepLearning/acting_card.png"
                  alt="智能体"
                  title="智能体"
                ></img>
              </div>
            </div>
            <div className="jtLine">
              {/* <span></span> */}
              <img
                src="/image/deepLearning/arrow_left_black.png"
                alt=""
                className="arrow_left_black"
              />
            </div>
            {/* <div className="bigCard surroundings_card">环境</div> */}
            <div
              className="imgBox   surroundings_card  surroundings_img"
              ref={flipRef_surroundings}
              onMouseMove={e => {
                flipRef_surroundings.current?.classList?.add(
                  'icon_img',
                  'icon_big'
                );
                handleShowBtn();
                // showTips(e);
              }}
            >
              <div className="bigCard  zhengImg  rotateAmview ">环境</div>
              <div className="fanImg  rotateAmview">
                <img
                  className="bigImg surroundingsImg"
                  src="/image/deepLearning/surroundings_card.png"
                  alt="环境"
                  title="环境"
                ></img>
              </div>
            </div>
          </div>
          <div className="jtBottom">
            {/* <span></span> */}
            <img
              src="/image/deepLearning/arrow_top_black.png"
              alt=""
              className="arrow_top_black"
            />
          </div>
          {/* <div className="smallCard status_card">状态</div> */}
          {/* <div className="smallCard award_card">奖励</div> */}
          {/* <div className="smallCard action_card">动作</div> */}
          {/* 图片翻转 */}
          <div
            className="imgBox  status_card  status_img"
            ref={flipRef_status}
            onMouseMove={() => {
              flipRef_status.current?.classList?.add('icon_img', 'icon_small');
              handleShowBtn();
            }}
          >
            <div className="smallCard  zhengImg  rotateAmview ">状态</div>
            <div className="fanImg  rotateAmview">
              <img
                src="/image/deepLearning/status_card.png"
                alt="状态"
                title="状态"
                className="statusImg"
              ></img>
            </div>
          </div>

          <div
            className="imgBox  award_card  status_img"
            ref={flipRef_award}
            onMouseMove={() => {
              flipRef_award.current?.classList?.add('icon_img', 'icon_small');
              handleShowBtn();
            }}
          >
            <div className="smallCard  zhengImg  rotateAmview ">奖励</div>
            <div className="fanImg  rotateAmview">
              <img
                src="/image/deepLearning/award_card.png"
                alt="奖励"
                title="奖励"
              ></img>
            </div>
          </div>

          <div
            className="imgBox  action_card  status_img"
            ref={flipRef_action}
            onMouseMove={() => {
              flipRef_action.current?.classList?.add('icon_img', 'icon_small');
              handleShowBtn();
            }}
          >
            <div className="smallCard  zhengImg  rotateAmview ">动作</div>
            <div className="fanImg  rotateAmview">
              <img
                src="/image/deepLearning/action_card.png"
                alt="动作"
                title="动作"
              ></img>
            </div>
          </div>
        </div>
      </div>
      {showBtn ? (
        <p className="describe">
          对于现实生活中的问题，如何定义「奖励」是构建强化学习模型的难点。目前，强化学习最常见的应用场景是游戏。
          <br />
          下一步，我们就需要设计样本收集的方法。
        </p>
      ) : null}

      {/* <span className="imgTips" ref={tipsRef}>
        {tipsText}
      </span> */}
    </div>
  );
};
export default StepOne;
