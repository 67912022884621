import React, {useState, useRef, useEffect} from 'react';
import './style.scss';

const AudioPlayer = ({url,style,times,needPause}) => {

    const btn = useRef();
    const media = useRef();
    const bar = useRef();
    const t1 = useRef();
    const progressAll = useRef();
    const progressNow = useRef();

    const [play, setPlay] = useState(false);
    const [timer, setTimer] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [audioTime, setAudioTime] = useState(0);

    useEffect(() => {
        //加载完成后会执行这个函数
        media.current.onloadedmetadata = () => {
            const time = times ? times : parseInt(media.current?.duration)
            // 音频总时间
            setAudioTime(time)
        }
    },[]) // eslint-disable-line

    useEffect(() => {
        needPause && media.current?.pause();
    },[needPause])

    const [hoverPlay,setHoverPlay] = useState(false);
    const [hoverPause,setHoverPause] = useState(false);

    const playOrPause = () => {
        // 假如为暂停状态,点击则播放
        if(media.current?.paused) {
            setPlay(true);
            media.current.play();
            const currentTime = parseInt(media.current?.currentTime)
            if (currentTime === times) {
                media.current.currentTime = 0
            }
            const timer = setInterval(() => {
                // console.log(media.current.currentTime,'当前进度')
                // 当前播放时间
                const currentTime = parseInt(media.current?.currentTime)
                // t1.current.innerHTML = parseInt(media.current.currentTime) + ' ';
                setCurrentTime(currentTime)
                const progressAllWidth = progressAll.current?.offsetWidth;
                const count = times ? times : parseInt(media.current?.duration)
                const pre = (100 * media.current?.currentTime)
                    / (100 * count)
                    * progressAllWidth;
                if (progressNow.current) progressNow.current.style.width = pre+'px';
                if (bar.current) bar.current.style.left = pre + 'px';

                // 100
                if (times && currentTime === times) {
                    clearInterval(timer)
                    media.current?.pause();
                    setPlay(false);
                    setHoverPlay(false)
                    return
                }
                //如果播放完毕
                if(media.current?.currentTime === media.current?.duration){
                    clearInterval(timer)
                    setPlay(false);
                    setHoverPlay(false)
                }
            },500)
            setTimer(timer)
        } else {//假如为播放状态，点击则暂停
            setPlay(false);
            media.current?.pause();
            clearInterval(timer)
        }
    }

    const slide2where = (event) => {
        // 获取progress对象
        const progress = progressNow.current;
        let progressX;
        //获取圆点偏移量
        const progressLeft = event.clientX - bar.current?.offsetLeft;

        // 为右侧圆点绑定拖动事件
        document.onmousemove = function(event){
            // 获取鼠标坐标
            progressX = event.clientX - progressLeft;
            if(progressX <= 0){
                // 暂停拖动（如果拖动条超出范围，则停止拖动）
                progressX = 0;
            }
            else if(progressX >= 398){
                progressX = 398;
            }
            if (bar.current) bar.current.style.left = progressX+"px";
            // 显示进度条
            if (progress) progress.style.width = progressX+"px";
        };

        // 为右侧圆点绑定鼠标抬起事件
        document.onmouseup = function(event){
            // 取消鼠标移动事件
            document.onmousemove = null;
            // 取消鼠标抬起事件
            document.onmouseup = null;
            // console.log('currentTime',audioTime * progressX/398)
            media.current.currentTime = audioTime * progressX/398;
            setCurrentTime(parseInt(media.current?.currentTime))
        };
        //取消浏览器对拖拽内容进行搜索的默认行为
        return false;
    }

    return(
        <div id="audioPlayerComponent" style={style ? style: {}}>
            <div className='changeStatusButton' ref={btn} onClick={playOrPause}>
                { play ||
                    <img
                        src={ hoverPlay ? "/image/speaker/play-hover.png" : "/image/speaker/play.png"}
                        onMouseEnter={() => setHoverPlay(true)}
                        onMouseLeave={() => setHoverPlay(false)}
                        alt=""
                    />
                }
                { play &&
                    <img
                        src={ hoverPause ? "/image/speaker/pause-hover.png" : "/image/speaker/pause.png"}
                        onMouseEnter={() => setHoverPause(true)}
                        onMouseLeave={() => setHoverPause(false)}
                        alt=""/>}
            </div>
            <div className='audioTime'>
                <span className='t1' ref={t1}>{currentTime} </span>
                /
                <span className='t2'> {audioTime}</span>
            </div>
            <div className='barbox'>
                <div className='progressall' ref={progressAll}>
                    {/*进度条*/}
                    <div id="progress" className='progresscurrent' ref={progressNow}>
                        {/*进度条右边圆角*/}
                        <div id="progress-bar" ref={bar} onMouseDown={slide2where}></div>
                    </div>
                </div>
            </div>
            <audio id='media' src={url} preload="auto" ref={media}></audio>
        </div>
    )
}

export default AudioPlayer;
