import React, { useEffect, useState, useRef, useReducer } from 'react'
import { Dropdown, Menu } from 'antd'
import Down from '../../../assets/textCreateImage/downArrow.svg'
import './style.scss'

const DropdownBox = props => {
    
    const { getUserKeywordList } = props

    const menuRef = useRef(null)
    
    useEffect(() => {
        setKeyword(props.keyword)
    }, [props.keyword])
    
    // useEffect(() => {
    //     console.log('props.keywordIndex', props.keywordIndex)
    // },[props.keywordIndex])
    
    useEffect(() => {
        
        const currentMenu = props.menu?.map((item, index) => ({
            key: index.toString(),
            label: item
        }))
        
        menuDispatch({ type: "update", currentMenu })
        
    }, [props.menu])
    
    
    const dropdownContainer = useRef(null)
    const [keyword, setKeyword] = useState("")

    useEffect(() => {
        keyword && getUserKeywordList(keyword, props.keywordIndex, userSelect.current)
        // eslint-disable-next-line
    }, [keyword])

    const userSelect = useRef(false)
    const selectKeyword = ({ key }) => {
        userSelect.current = true
        setKeyword(menu[key * 1].label)
        setIsOverflowed(false)
    }
    
    const menuReducer = (state, action) => {
        if (action.type === "update") {
            // 这里传过来的数组需要进行过滤，再处理成 object[]
            return action.currentMenu
        }
    }
    
    const [
        menu,
        menuDispatch
    ] = useReducer(menuReducer, [], () => [])


    // 菜单过长
    const [isOverflowed, setIsOverflowed] = useState(false)
    
    const checkOverflow = () => {
        if (menuRef.current && dropdownContainer.current) {
            setTimeout(() => {
                // 菜单过长有滚动条时，需要添加一个小图标提示用户下面还有内容
                const menuClientHeight = menuRef.current.menu.list.clientHeight
                const menuScrollHeight = menuRef.current.menu.list.scrollHeight
                // console.log('is overflow', menuScrollHeight > menuClientHeight)
                setIsOverflowed(menuScrollHeight > menuClientHeight)
            }, 100)
        }
    };

    const openMenu = () => {
        setTimeout(() => {
            checkOverflow();
        })
    }
    
    const closeDropdown = (open) => {
        // console.log('open', open)
        open ? openMenu() : setIsOverflowed(false)
    }

    return(
        <div ref={dropdownContainer} 
             className="dropdownContainer"
        >
            <Dropdown placement="bottom"
                      trigger={["click"]}
                      getPopupContainer={() => dropdownContainer.current}
                      onOpenChange={ closeDropdown }
                      overlay={
                          <>
                              <Menu
                                  className="keywordSelect"
                                  onClick={ selectKeyword }
                                  ref={ menuRef }
                              >
                                  {menu.map(item => (
                                      <Menu.Item key={item.key}>{item.label}</Menu.Item>
                                  ))}

                              </Menu>
                              { isOverflowed && <Down className="icon"/> }
                          </>
                      }
            >
                <span className="keyword">{ keyword }</span>
            </Dropdown>
        </div>
    )
}

export default DropdownBox