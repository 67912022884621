import React, { useEffect, useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
// import { useSelector, useDispatch } from 'react-redux'
// import { connect, name, toKen } from '../../store/createSlice'
import { me } from '../../utils';
import './style.scss';
const CLOUD_TEST_API = 'https://learn.dev.longan.eliteu.xyz';
const CLOUD_FORMAL_API = 'https://course.aimaker.space'
const CLOUD_API = window.location.host.indexOf('aimaker.space') > -1 ?
    CLOUD_FORMAL_API :
    CLOUD_TEST_API;

const scratchURL = window.location.host.indexOf('aimaker.space') > -1 ?
    'https://make.aimaker.space' :
    'https://make.dev.longan.eliteu.xyz';

const Header = () => {
    // const navigate = useNavigate();
    const [connects, setConnect] = useState(false);
    const [userNickname, setUserNickname] = useState('');
    // const [headPortrait, setHeadPortrait] = useState('');
  let headPortrait = useRef();

  useEffect(() => {
    const labToken = Cookies.get('eliteuToken') || Cookies.get('token'); //教学云设置token: eliteuToken 开发服， token 生产服
        if (window.opener && labToken) {
            // 如果是从创作平台打开，那么判断连接的方式就是是否有父页面
            sessionStorage.setItem('toKen', `Bearer ${labToken} `);
            me().then((res) => {
                // 获取这个用户的信息
                if (res.name) {
                    setUserNickname(res.name);
                } else {
                    setUserNickname(res.username);
                }
                // setHeadPortrait(CLOUD_API + res.avatar_url);
                headPortrait.current.style = `background-image: url(${CLOUD_API + res.avatar_url});`
                sessionStorage.setItem('name', res.username);
                sessionStorage.setItem('id',res.id);
            });
            setConnect(true);
            let time = setInterval(() => {
                // 每 2 秒判断一下是否连接(虽然连不连接都能用)
                if (!window.opener) {
                    clearInterval(time);
                    setConnect(false);
                }
            }, 2000)
        } else if (labToken) {
            // 如果是输入网址打开，那么判断连接的方式就是是否有打开的子页面
            sessionStorage.setItem('toKen', `Bearer ${labToken}`);
            me().then((res) => {
                if (res.name) {
                    setUserNickname(res.name);
                } else {
                    setUserNickname(res.username);
                }
                headPortrait.current.style = `background-image: url(${CLOUD_API + res.avatar_url});`
                // setHeadPortrait(CLOUD_API + res.avatar_url);
                sessionStorage.setItem('name', res.username);
                sessionStorage.setItem('id',res.id);
            });
            if (sessionStorage.getItem('win') === 'true') {
                setConnect(true);
                let win = window.open('', "editor");
                let time = setInterval(() => {
                    if (win.closed) {
                        sessionStorage.setItem('win', 'false');
                        clearInterval(time);
                        setConnect(false);
                    }
                }, 2000)
            }
        } else {
            // 如果没有登录，就前往登录页面
            window.open(CLOUD_API + '/login/?url=' + window.location.href, '_self');
        }
    }, [])
    const open = () => {
        // 未连接的状态下点击打开，打开创作平台，然后暂时缓存记录连接状态
        setConnect(true);
        let data = window.open(scratchURL + '/projects/editor/', "editor")
        sessionStorage.setItem('win', 'true');
        let time = setInterval(() => {
            if (data.closed) {
                sessionStorage.setItem('win', 'false');
                clearInterval(time);
                setConnect(false);
            }
        }, 2000)
    }
    const home = () => {
        // 回到首页
        // navigate('/');
        window.location.href = '/';
    }
    return (
        <div className='header'>
            <div className='home' onClick={home}>英荔 AI 实验平台</div>
            <div className='nickname'><span className='headPortrait' ref={headPortrait}></span><span>{userNickname}</span></div>
            {connects ? <div className='connect'><span className='Connected'></span>已连接到英荔创作平台</div> : <div className='connect'><span className='NotConnected'></span>英荔 AI 实验平台需要连接创作平台使用 <span className='OpenPlatform' onClick={open}>立即打开 &gt;</span></div>}
        </div>
    )
}
export default Header;
