import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Steps, Button, message, Modal, Spin } from 'antd';

import StepOne from './stepsContent/stepOne';
import StepTwo from './stepsContent/stepTwo';
import StepThree from './stepsContent/stepThree';
import StepFour from './stepsContent/stepFour';
import StepFive from './stepsContent/stepFive';
import {
  deepLearn_trainTime,
  deepLearn_trainCancel_or_Ok,
  deepLearn_getTrainModal
} from '../../utils';
import { AIModelResult } from '../../store/createSlice';
import './style.scss';

const { Step } = Steps;

const DeepReinforcementLearning = () => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [setpFinish, setSetpFinish] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [modeId, setModelId] = useState(null);
  const convolutionalL = useSelector(state => state.counter.convolutionalLayer);
  const AIModel_result = useSelector(state => state.counter.AIModelResult);
  const poolingL = useSelector(state => state.counter.poolingLayer);
  const fullyConnect = useSelector(state => state.counter.fullyConnected);
  const imagesNumber = useSelector(state => state.counter.imagesNum);
  const cyclesNumber = useSelector(state => state.counter.cycles);
  const iterationsNumber = useSelector(state => state.counter.iterationsNum);
  const comPower = useSelector(state => state.counter.computingPower);
  const [trainTime, setTrainTime] = useState('');
  const [okBtnDisabled, setOKBtnDisabled] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [nextBtnDis, setNextBtnDis] = useState(false);
  // console.log('otherWindow::::', window.name);
  // window.postMessage({
  //   key: 'ai-lab',
  //   value: '传给创作平台'
  // });
  const onChangeStep = num => {
    if (current < num) return;
    setCurrent(num);
  };
  const next = () => {
    setCurrent(current + 1);
  };
  const handleStepFinish = () => {
    setSetpFinish(true);
  };
  // useEffect(() => {
  //   window.addEventListener(
  //     'message',
  //     event => {
  //       console.log('ai-lab ***', event);
  //       event.source.postMessage({
  //         key: 'ai-lab',
  //         value: '传给创作平台'
  //       });
  //     },
  //     false
  //   );
  // }, []);
  window.addEventListener('beforeunload', e => {
    window?.opener?.postMessage(
      {
        trained: false, // 训练是否完成
        t: '关闭窗口'
      },
      '*'
    );
    // window.event.returnValue = '是否关闭？';
  });
  useEffect(() => {
    deepLearn_getTrainModal()
      .then(res => {
        dispatch(AIModelResult(res.data));
        setCurrent(4);
      })
      .catch(err => console.log(err));
  }, [dispatch]);
  // window.onload = () => {
  //   setCurrent(3);
  //   console.log('onload-->>');
  // };
  useEffect(() => {
    setNextBtnDis(false);

    if (
      (current === 1 && !comPower) ||
      (current === 2 && (!convolutionalL || !poolingL || !fullyConnect)) ||
      (current === 3 && (!cyclesNumber || !iterationsNumber))
    )
      // 如果当前步骤输入框为空，则下一步不可点击
      setNextBtnDis(true);
  }, [
    convolutionalL,
    poolingL,
    fullyConnect,
    current,
    comPower,
    cyclesNumber,
    iterationsNumber
  ]);
  const prev = () => {
    setCurrent(current - 1);
  };
  const handleTrain = () => {
    let params = {
      power: comPower,
      h_conv: convolutionalL,
      h_pool: poolingL,
      h_fc: fullyConnect,
      image_nums: imagesNumber,
      observe: cyclesNumber,
      explore: iterationsNumber
    };
    deepLearn_trainTime(params)
      .then(res => {
        setOKBtnDisabled(false);
        setModelId(res.data.id);
        setTrainTime(res.data.train_times);
      })
      .catch(err => {
        setOKBtnDisabled(true);
        message.error(err.data.message);
      });
    setIsModalVisible(true);
  };
  const handleOkTrain = () => {
    let params = { id: modeId, opt_type: 1 };
    deepLearn_trainCancel_or_Ok(params).then(res => {
      dispatch(AIModelResult(res.data));

      setTimeout(() => {
        setLoadingModal(false);
        next();
      }, res.data.loading_time * 1000);
    });
    setIsModalVisible(false);
    setLoadingModal(true);
  };
  const handleCancelTrain = () => {
    setIsModalVisible(false);
  };
  const stepsCont = [
    {
      title: '强化学习的基本原理',
      content: (
        <StepOne
          parentFun={show => {
            setShowBtn(show);
          }}
        />
      )
    },
    {
      title: '样本处理',
      content: <StepTwo />
    },
    {
      title: '设计深度学习模型',
      content: <StepThree />
    },
    {
      title: '完成强化学习模型',
      content: <StepFour training={isModalVisible || loadingModal} />
    },
    {
      title: '测试模型',
      content: <StepFive prev={prev} handleStepFinish={handleStepFinish} />
    }
  ];
  const imgsrc = status => {
    let iconsrc = '';
    switch (status) {
      case 'wait':
        iconsrc = '/image/deepLearning/wait.png';
        break;
      case 'process':
        iconsrc = '/image/deepLearning/process.png';
        break;
      case 'finish':
        iconsrc = '/image/deepLearning/finish.png';
        break;
      default:
        break;
    }
    return iconsrc;
  };

  return (
    <div className="deepReinBox">
      <div className="name">深度强化学习</div>
      <div className="content">
        {/* 图片翻转 */}

        {/* <div className="icon_img">
          <img className="zhengImg rotateAmview" src="/image/iris_1.png"></img>
          <div className="fanImg rotateAmview">
            <img src="/image/iris_1done.png"></img>
          </div>
        </div> */}

        <Steps
          current={current}
          labelPlacement="vertical"
          onChange={onChangeStep}
        >
          {/* {stepItem} */}
          <Step
            title={stepsCont[0].title}
            status={current === 0 ? 'process' : 'finish'}
            icon={
              <img
                width={18}
                src={imgsrc(current === 0 ? 'process' : 'finish')}
                alt=""
              />
            }
          ></Step>
          <Step
            title={stepsCont[1].title}
            status={
              current === 1 ? 'process' : current === 0 ? 'wait' : 'finish'
            }
            icon={
              <img
                width={18}
                src={imgsrc(
                  current === 1 ? 'process' : current === 0 ? 'wait' : 'finish'
                )}
                alt=""
              />
            }
          ></Step>
          <Step
            title={stepsCont[2].title}
            status={
              current === 2
                ? 'process'
                : current === 0 || current === 1
                ? 'wait'
                : 'finish'
            }
            icon={
              <img
                width={18}
                alt=""
                src={imgsrc(
                  current === 2
                    ? 'process'
                    : current === 0 || current === 1
                    ? 'wait'
                    : 'finish'
                )}
              />
            }
          ></Step>
          <Step
            title={stepsCont[3].title}
            status={
              current === 3
                ? 'process'
                : current === 0 || current === 1 || current === 2
                ? 'wait'
                : 'finish'
            }
            icon={
              <img
                width={18}
                alt=""
                src={imgsrc(
                  current === 3
                    ? 'process'
                    : current === 0 || current === 1 || current === 2
                    ? 'wait'
                    : 'finish'
                )}
              />
            }
          />
          <Step
            title={stepsCont[4].title}
            status={
              current === 4
                ? 'process'
                : current === 0 ||
                  current === 1 ||
                  current === 2 ||
                  current === 3
                ? 'wait'
                : 'finish'
            }
            icon={
              <img
                alt=""
                width={18}
                // src={imgsrc(
                //   current === 4
                //     ? 'process'
                //     : current === 0 ||
                //       current === 1 ||
                //       current === 2 ||
                //       current === 3
                //     ? 'wait'
                //     : 'finish'
                // )}
                src={imgsrc(
                  current === 4 && !setpFinish
                    ? 'process'
                    : current === 0 ||
                      current === 1 ||
                      current === 2 ||
                      current === 3 ||
                      !setpFinish
                    ? 'wait'
                    : 'finish'
                )}
              />
            }
          />
        </Steps>
        {/* 各步骤内容区 */}
        <div className="stepsContent">{stepsCont[current].content}</div>
        {/* 按钮 */}
        {current !== stepsCont.length - 1 ? (
          <div className="stepsAction">
            {current > 0 && current !== stepsCont.length - 1 && (
              <Button
                style={{ margin: '0', marginRight: '16px' }}
                onClick={() => prev()}
              >
                上一步
              </Button>
            )}
            {current !== 0 && current < stepsCont.length - 1 && current !== 3 && (
              <Button
                type="primary"
                onClick={() => next()}
                disabled={nextBtnDis}
              >
                下一步
              </Button>
            )}
            {current === 0 && showBtn && (
              <Button type="primary" onClick={() => next()}>
                下一步
              </Button>
            )}
            {current === 3 && (
              <Button
                type="primary"
                onClick={() => handleTrain()}
                disabled={nextBtnDis}
              >
                开始训练
              </Button>
            )}
          </div>
        ) : null}
      </div>
      {/* 模型正在训练中 弹窗 */}
      <Modal
        title="模型正在训练中"
        visible={isModalVisible}
        onOk={handleOkTrain}
        onCancel={handleCancelTrain}
        maskClosable={false}
        destroyOnClose={true}
        width={420}
        cancelText={'取消'}
        okText={'下一步'}
        okButtonProps={{ disabled: okBtnDisabled }}
      >
        <div className="ModalContent">
          {' '}
          <Spin size="large" />
          <span className="timeText">当前参数预计训练耗时 {trainTime}</span>
          <p>
            为提高实验效率，训练过程中若出现未知错误，英荔 AI
            实验平台将自动调整参数到某个你所设置参数的近似值，以确保可以完成模型。
            <br />
            不想等待太久？英荔 AI
            实验平台内置了一些现成模型可以直接点击【下一步】加载使用。建议观察这些模型以及试验的结果，寻找参数设置和效果间的规律。
          </p>
        </div>
      </Modal>
      <Modal
        title="模型正在加载中"
        visible={loadingModal}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
        width={420}
        closable={false}
      >
        <div className="ModalContent">
          {' '}
          <Spin size="large" />
          <span className="timeText">
            预计加载耗时 {AIModel_result.loading_time} 秒
          </span>
          <p>
            <span className="timeText">模型关键参数：</span>
            <br /> 分配算力 {AIModel_result.power}；
            {/* <br /> 单个状态所含图像数量 {AIModel_result.image_nums} 张； */}
            <br />
            卷积层数 {AIModel_result.h_conv} 层；池化层数{' '}
            {AIModel_result.h_pool} 层；全连接层数 {AIModel_result.h_fc} 层；
            <br />
            样本采集阶段的循环次数 {AIModel_result.observe} 万次；
            <br />
            训练模型阶段的迭代次数 {AIModel_result.explore} 万次。
          </p>
        </div>
      </Modal>
    </div>
  );
};
export default DeepReinforcementLearning;
