import React, { useState, useRef } from 'react'
import './style.scss'
import Basic from './Basic'
import Advanced from './Advanced'

const TextCreateImage = () => {

    const [current, setCurrent] = useState("basic");
    
    const baseurl = useRef("https://dall-e.eliteu.xyz/dall-e/v2/samples")
    
    return(
        <div id="textCreateImage">
            <div className="title">AI 文本生成图像</div>

            <div className="switch">
                <span onClick={() => setCurrent("basic")} style={{ "color": current === "basic" ? "#0084ff" : "#333333" }}>
                    基础版
                </span>
                <span onClick={() => setCurrent("advanced")} style={{ "color": current === "advanced" ? "#0084ff" : "#333333" }}>
                    进阶版
                </span>
            </div>


            <div className="content">
                <Basic show={ current === "basic" } baseurl={ baseurl.current } /> 
                <Advanced show={ current === "advanced" } baseurl={ baseurl.current } /> 
            </div>
            
            
        </div>
    )
}

export default TextCreateImage