import { createSlice } from '@reduxjs/toolkit'

export const cropSlice = createSlice({
    name: 'crops',
    initialState: {
        /* step2 变换 */
        flip: false,
        spin: false,
        cut: false,
        trans: false,

        /* step3 变换 */
        noise: false,
        color: false,
        brightness: false,

        /* step4 图片步骤 */
        step: 0,
        isClick1: false, // 步骤 1 是否点击
        isClick: false, // 步骤 2 是否点击
        isClick3: false, // 步骤 3 是否点击

        /* step5 上传 */
        cachePreviewImg: '', // 图片预览
        empty: false, // 预测类型是否显示
        hasForecast: false, // 是否预测过 用于判断步骤条
    },
    reducers: {
        changeFlip: (state) => {
            state.flip = true;
        },
        changeSpin: (state) => {
            state.spin = true;
        },
        changeCut: (state) => {
            state.cut = true;
        },
        changeTrans: (state) => {
            state.trans = true;
        },
        changeNoise: (state) => {
            state.noise = true;
        },
        changeColor: (state) => {
            state.color = true;
        },
        changeBrightness: (state) => {
            state.brightness = true;
        },
        changeStep: (state, action) => {
            state.step = action.payload;
        },
        changeIsClick1: (state) => {
            state.isClick1 = true;
        },
        changeIsClick: (state) => {
            state.isClick = true;
        },
        changeIsClick3: (state) => {
            state.isClick3 = true;
        },
        setCachePreviewImg: (state, action) => {
            state.cachePreviewImg = action.payload;
        },
        setEmpty: (state, action) => {
            state.empty = action.payload;
        },
        setHasForecast: (state, action) => {
            state.hasForecast = action.payload;
        }
    },
})

export const {
    changeFlip,
    changeSpin,
    changeCut,
    changeTrans,
    changeNoise,
    changeBrightness,
    changeColor,
    changeStep,
    changeIsClick1,
    changeIsClick,
    changeIsClick3,
    setCachePreviewImg,
    setEmpty,
    setHasForecast
} = cropSlice.actions;

export default cropSlice.reducer;
