import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './createSlice'
import cropReducer from './cropSlice'
import speakerReducer from './speakerSlice'
import textCreateImage from './textCreateImageSlice';
export default configureStore({
    reducer: {
        counter: counterReducer,
        crop: cropReducer,
        speaker: speakerReducer,
        textCreateImage
    },
})
